import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

import {useMainStore} from 'stores/main';
import {ref, toRef, watch} from 'vue';
import ApiForm from 'src/components/qutils/form/ApiForm.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BusinessRegistrationForm',
  props: {
  modelValue: {
    required: true,
    default: {
      address: {}
    }
  },
  errors: {},
  dark: {
    default: false
  }
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const loading = ref(false)
const mainStore = useMainStore()

loading.value = true
mainStore.fetchRegisterBusinessFormFields().finally(() => {
  loading.value = false
})
const emit = __emit

const props = __props

//
const formDataRef = toRef(props, 'modelValue')
const formData = ref(formDataRef.value)


watch(
  () => formData.value,
  (val) => {
    emit('update:modelValue', val)
  },
  {deep: true}
)


return (_ctx: any,_cache: any) => {
  const _component_q_skeleton = _resolveComponent("q-skeleton")!

  return (!loading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(ApiForm, {
          "model-value": formData.value,
          errors: props.errors,
          "option-fields": _unref(mainStore).businessRegisterFormFields,
          dark: __props.dark,
          outlined: "",
          "fields-config": {
                  name: { attrs: {class: 'col-12 q-mb-md'} },
                  website: { attrs: {class: 'col-12 col-sm-6'} },
                  phone: { attrs: {class: 'col-12 col-sm-6'} },
                  email: { attrs: {class: 'col-12 col-sm-6 q-mb-md q-mr-sm'} },
                  registration_number: { attrs: {class: 'col-12 col-sm-6'} },
                  tax_number: { attrs: {class: 'col-12 col-sm-6'} },
                  vat_id: { attrs: {class: 'col-12 col-sm-6 q-mr-sm q-mb-md'} },
                  imprint_link: { attrs: { class: 'col-12 col-sm-6' }},
                  privacy_link: { attrs: { class: 'col-12 col-sm-6' }},
                },
          "exclude-fields": [
        'owner_email',
        ]
        }, {
          submit: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" ")
          ])),
          _: 1
        }, 8, ["model-value", "errors", "option-fields", "dark"]),
        _createVNode(ApiForm, {
          modelValue: formData.value.address,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.value.address) = $event)),
          errors: props.errors?.address,
          "option-fields": _unref(mainStore).businessRegisterFormFields?.address.children,
          dark: __props.dark,
          outlined: "",
          "fields-config": {
                  street_and_number: { attrs: {class: 'col-8'} },
                  addition: { attrs: {class: 'col-4'} },
                  city: { attrs: {class: 'col-8'} },
                  zip_code: { attrs: {class: 'col-4'} },
                }
        }, {
          submit: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" ")
          ])),
          _: 1
        }, 8, ["modelValue", "errors", "option-fields", "dark"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_q_skeleton, { style: {"height":"300px","width":"100%"} })
      ]))
}
}

})