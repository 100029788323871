import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flip-card-inner" }
const _hoisted_2 = { class: "flip-card-front" }
const _hoisted_3 = { class: "flip-card-back" }


export default /*@__PURE__*/_defineComponent({
  __name: 'FlipCard',
  props: {
  modelValue: {required: true},
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "flashcard",
    class: _normalizeClass([{ flipped: __props.modelValue }, "flip-card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "front")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "back")
      ])
    ])
  ], 2))
}
}

})