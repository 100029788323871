import {defineStore} from 'pinia';
import Market from 'src/api/market';

interface State {
  items: object;
  itemCategories?: object;
  itemCategoryDetail: object,
  itemOptions?: object,
}

export const useMarketStore = defineStore('market', {

  state: (): State => ({
    items: {},
    itemCategoryDetail: {},
    itemOptions: undefined,
  }),

  actions: {
    fetchItem(uuid: string, cached = true) {
      return new Promise((resolve, reject) => {
        if (cached && this.items[uuid]) return resolve(this.items[uuid])
        return Market.fetchItem(uuid).then(resp => {
          this.items[uuid] = resp.data
          resolve(resp)
        }).catch(err => {
          reject(err)
        })
      })
    },

    fetchCategories(cached = true) {
      return new Promise((resolve, reject) => {
        if (cached && this.itemCategories) return resolve(this.itemCategories)
        return Market.fetchCategories().then(resp => {
          this.itemCategories = resp.data
          resolve(resp.data)
        }).catch(err => {
          reject(err)
        })
      })
    },

    fetchCategory(categoryId: number) {
      if (this.itemCategoryDetail[categoryId]) return this.itemCategoryDetail[categoryId]
      return Market.fetchCategory(categoryId).then(resp => {
        this.itemCategoryDetail[categoryId] = resp.data
        return resp.data
      })
    },

    getItemOptions() {
      if (this.itemOptions !== undefined) return new Promise(resolve => resolve(this.itemOptions))

      return Market.getItemOptions().then(resp => {
        this.itemOptions = resp.data?.actions?.POST || resp.data?.actions?.PUT || resp.data?.actions?.PATCH
        return resp
      }).catch(err => {
        console.error(err)
      })
    }
  }

})
