import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import Cms from 'src/api/cms';
import {onMounted, ref} from 'vue';
import {Page} from 'src/models/cms';
import {useMeta} from 'quasar';
import {getPageUrl} from 'src/etc/helper';
import {useRouter} from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'ImprintPage',
  setup(__props) {

const page = ref<Page>()
const router = useRouter()


onMounted(() => {
  Cms.getPage('imprint').then(resp => {
    page.value = resp.data
  }).catch(err => {
    console.error(err)
  })
})


useMeta(() => {
  return {
    // sets document title
    titleTemplate(title) {
      return `Impressum | ${title}`
    },
    meta: {
      description: {
        name: 'description',
        content: 'Besuche unser Impressum für rechtliche Informationen. Erfahren mehr über unsere Firma, Kontaktdetails und gesetzliche Verantwortlichkeiten.',
      },
    },
    link: {
      canonical: {
        rel: 'canonical', href: getPageUrl('imprint', router) || ''
      }
    }

  }
})

return (_ctx: any,_cache: any) => {
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      (page.value?.content)
        ? (_openBlock(), _createElementBlock("section", {
            key: 0,
            class: "container vhtml-container",
            innerHTML: page.value.content
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})