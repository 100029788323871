import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "overlay"
}

import {onMounted, ref} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FadeOutText',
  props: {
  maxHeight: {
    default: 300,
    type: Number
  },
  trashhold: {
    default: 100,
    type: Number
  },
  backgroundColor: {
    default: 'white',
    type: String
  },
  label: {
    default: 'Alles anzeigen',
    type: String
  }
},
  setup(__props) {

_useCssVars(_ctx => ({
  "775356f2": (__props.backgroundColor)
}))

const wrapperRef = ref()
const isHidden = ref(false)
const props = __props

onMounted(() => {
  if (wrapperRef.value.scrollHeight > props.maxHeight + props.trashhold) {
    isHidden.value = true
  }
})

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "wrapperRef",
    ref: wrapperRef,
    style: _normalizeStyle({'max-height': !isHidden.value ? undefined : `${props.maxHeight}px`, 'overflow': isHidden.value ? 'hidden': 'auto'}),
    class: "fade-out-text-wrapper"
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (isHidden.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "show-more", {}, () => [
            _createVNode(_component_q_btn, {
              color: "primary",
              flat: "",
              "no-caps": "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (isHidden.value = false))
            }, {
              default: _withCtx(() => [
                _createElementVNode("u", null, _toDisplayString(__props.label), 1)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 4))
}
}

})