import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex column no-wrap full-width full-height overflow-hidden-x" }
const _hoisted_2 = {
  key: 0,
  class: "absolute-center"
}
const _hoisted_3 = {
  class: "bg-white bg-white",
  style: {"flex":"1"}
}
const _hoisted_4 = { class: "q-px-md q-pt-xs q-pb-sm text-center" }
const _hoisted_5 = {
  class: "scroll-y q-py-lg",
  style: {"flex-grow":"1"}
}
const _hoisted_6 = {
  class: "row q-px-md",
  style: {"row-gap":"32px"}
}
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "flex" }
const _hoisted_12 = { class: "q-mt-md" }
const _hoisted_13 = { class: "col-12" }
const _hoisted_14 = { class: "q-my-md" }
const _hoisted_15 = { style: {"margin-left":"-16px","margin-right":"-16px"} }
const _hoisted_16 = { class: "scroll-x q-py-sm" }
const _hoisted_17 = { class: "q-px-md q-py-md" }
const _hoisted_18 = {
  class: "flex no-wrap items-center justify-between",
  style: {"column-gap":"12px"}
}
const _hoisted_19 = { class: "col-12" }
const _hoisted_20 = { class: "q-px-md q-py-md" }
const _hoisted_21 = {
  class: "flex no-wrap items-center justify-between",
  style: {"column-gap":"12px"}
}
const _hoisted_22 = { class: "col-12" }
const _hoisted_23 = { class: "row q-col-gutter-lg" }
const _hoisted_24 = { class: "col-12" }
const _hoisted_25 = { class: "col-12" }
const _hoisted_26 = { class: "col-12 col-sm-6" }
const _hoisted_27 = { class: "col-12 col-sm-6" }
const _hoisted_28 = { class: "col-12 col-sm-6" }
const _hoisted_29 = { class: "col-12 col-sm-6" }
const _hoisted_30 = { class: "col-6" }
const _hoisted_31 = { class: "col-6" }
const _hoisted_32 = { class: "col-6" }
const _hoisted_33 = { class: "col-6" }
const _hoisted_34 = { class: "col-6" }
const _hoisted_35 = { class: "col-6" }
const _hoisted_36 = { class: "col-12" }
const _hoisted_37 = { class: "col-12" }

import {computed, onMounted, ref} from 'vue';
import DynamicInput from 'components/qutils/form/DynamicInput.vue';
import SmallNumberSelect from 'src/apps/search/components/SmallNumberSelect.vue';
import AdvancedFilterDialog from 'src/components/filter/AdvancedFilterDialog.vue';
import {useQuasar} from 'quasar';
import {useSearchStore} from 'src/apps/search/store';
import ExtrasFilterDialog from 'components/filter/ExtrasFilterDialog.vue';
import ActiveFilterBadges from 'components/adverts/ActiveFilterBadges.vue';
import {useMainStore} from 'stores/main';
import {useRouter} from 'vue-router';
import {useSubscriptionStore} from 'src/apps/subscription/store';
import {AutocompleteResponse, createSearchAgent, SearchAdapter} from 'src/apps/search/helpers';
import {parseOptionsForQuasar} from 'src/etc/utils';
import {useAdvertStore} from 'src/apps/adverts/store';
import CustomButtonOptionGroup from 'src/apps/core/components/CustomButtonOptionGroup.vue';
import SearchTextInput from 'src/apps/search/components/SearchTextInput.vue';
import Search from 'src/api/search';
import SearchLocationInput from 'src/apps/search/components/SearchLocationInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'VehicleSearchFilter',
  setup(__props) {

const searchStore = useSearchStore()
const $router = useRouter()
const subStore = useSubscriptionStore()

const orderingOptions = [
  {value: '', label: 'Relevanz'},
  {value: '-published', label: 'Neueste zuerst'},
  {value: 'published', label: 'Ältesten zuerst'},
  {value: '-price', label: 'Preis - Absteigend'},
  {value: 'price', label: 'Preis - Aufsteigend'},
  {value: '-vehicle__year', label: 'Baujahr - Absteigend'},
  {value: 'vehicle__year', label: 'Baujahr - Aufsteigend'},
  {value: '-vehicle__mileage', label: 'Kilometerstand - Absteigend'},
  {value: 'vehicle__mileage', label: 'Kilometerstand - Aufsteigend'},
];

const filterData = computed({
  get() {
    return searchStore.filterData
  },
  set(val) {
    searchStore.updateFilterData(val, false)
  }
})

const advertStore = useAdvertStore()
const $q = useQuasar()
const brandOptionsFiltered = ref()
const previewExtras = ['toilet', 'solar', 'shower', 'heater',]
const mainStore = useMainStore()
const loading = ref(true)
const _yearValidation = ref<any>({})
const currentYear = new Date().getFullYear();

const validateYear = (yearValue) => {
  if (!yearValue) return true

  try {
    yearValue = Number.parseInt(yearValue)
  } catch (e) {
    return 'Bitte geben Sie ein gültiges Jahr ein.';
  }

  if (!Number.isInteger(yearValue)) {
    return 'Bitte geben Sie ein gültiges Jahr ein.';
  }

  if (yearValue < 1900 || yearValue > currentYear) {
    return 'Bitte geben Sie ein gültiges Jahr ein.';
  }
  return true;
}

function updateYear(field, value) {
  _yearValidation.value[field] = value
  if (validateYear(value) !== true) {
    return
  }
  filterData.value[field] = value
}

const computedSearchAgentText = computed(() => {
  if (!mainStore.isLoggedIn) return ''
  if (subStore?.accountQuota?.limits?.search_agent_limit === null) return ''

  try {
    return `(${subStore?.accountQuota?.current_usage?.search_agent_limit}/` +
      `${subStore?.accountQuota?.limits?.search_agent_limit})`
  } catch (err) {
    console.error(err)
  }
  return ''
})

const previewExtrasChips = computed(() => {
  return previewExtras.map(key => {
    const extra: any = mainStore.vehicleExtras.find(item => item['value'] === key)

    return {
      icon: extra?.icon ?? '',
      value: extra?.value ?? '',
      name: extra?.name ?? '',
    }
  })
})


const optionResponse = computed((): any => {
  return advertStore.advertOptions
})

const brandOptions = computed(() => {
  return parseOptionsForQuasar(optionResponse.value?.vehicle?.children?.brand?.choices)
})



function createSearchAgentClick() {
  createSearchAgent($q, mainStore, subStore, filterData.value, $router)
}

const brandFilterFn = (val, update) => {
  update(() => {
    if (val === '') {
      brandOptionsFiltered.value = brandOptions.value
    } else {
      const needle = val.toLowerCase()
      brandOptionsFiltered.value = brandOptions.value.filter(
        v => v.label.toLowerCase().indexOf(needle) > -1
      )
    }
  })
}

const buildTypeField = computed(() => {
  const field = optionResponse.value?.vehicle?.children?.build_type
  if (!field) return
  field.choices = field.choices.filter(obj => obj.value !== 'None')
  return field
})

function openVehicleFilterDialog() {
  $q.dialog({
    component: AdvancedFilterDialog,
    componentProps: {
      modelValue: filterData.value,
    }
  })
}

function openExtraDialog() {
  $q.dialog({
    component: ExtrasFilterDialog,
    componentProps: {
      modelValue: filterData.value,
      resultsCount: searchStore.searchResultCount
    }
  })
}

class VehicleSearchAdapter extends SearchAdapter {
  fetchAutocomplete(query): Promise<Array<any>> {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await Search.autocompleteSuggestion(query, 'vehicle')
        const options = resp.data?.options?.map((o: any) => {
          return {
            text: o.text,
            input: o.source.input,
            source: o.source.source,
          } as AutocompleteResponse
        })

        resolve(options)

      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

  fetchHistory(): Promise<Array<any>> {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await Search.fetchUserSearchHistory('vehicle')
        resolve(resp.data)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }
}


onMounted(() => {
  loading.value = true
  advertStore.fetchAdvertOptions().finally(() => {
    loading.value = false
  })
})

return (_ctx: any,_cache: any) => {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_range = _resolveComponent("q-range")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (loading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_q_spinner, { size: "lg" })
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(ActiveFilterBadges, {
              modelValue: filterData.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterData).value = $event)),
              class: "q-px-md",
              removable: "",
              inline: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_btn, {
                    rounded: "",
                    "no-caps": "",
                    outline: "",
                    unelevated: "",
                    onClick: createSearchAgentClick
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Suchauftrag erstellen " + _toDisplayString(computedSearchAgentText.value), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_component_q_separator)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[24] || (_cache[24] = _createElementVNode("div", { class: "c-header text-h4" }, "Wo möchtest du suchen?", -1)),
                _createVNode(SearchLocationInput, {
                  "model-value": _unref(searchStore).searchLocation,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = val => _unref(searchStore).setSearchLocation(val))
                }, null, 8, ["model-value"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[25] || (_cache[25] = _createElementVNode("div", { class: "c-header text-h4" }, "Freitext Suche", -1)),
                _createVNode(SearchTextInput, {
                  modelValue: filterData.value.s,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((filterData.value.s) = $event)),
                  "search-adapter": new VehicleSearchAdapter()
                }, null, 8, ["modelValue", "search-adapter"])
              ]),
              _createElementVNode("section", _hoisted_9, [
                _cache[26] || (_cache[26] = _createElementVNode("div", { class: "c-header text-h4" }, "Inserat von", -1)),
                _createElementVNode("div", null, [
                  _createVNode(_component_q_btn_toggle, {
                    modelValue: filterData.value.is_business,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((filterData.value.is_business) = $event)),
                    spread: "",
                    "no-caps": "",
                    unelevated: "",
                    padding: "md",
                    class: "q-card--bordered",
                    options: [
        {label: 'Alle', value: undefined},
        {label: 'Privat', value: false},
        {label: 'Gewerblich', value: true}
      ]
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("section", _hoisted_10, [
                _cache[28] || (_cache[28] = _createElementVNode("div", { class: "c-header text-h4" }, "Ausbau", -1)),
                _createElementVNode("div", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(previewExtrasChips.value, (extra) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: extra.value
                    }, [
                      _createVNode(_component_q_chip, {
                        color: _unref(searchStore).filterData?.vehicle__extras__slug?.includes(extra.value) ? 'positive' : '',
                        "text-color": _unref(searchStore).filterData?.vehicle__extras__slug?.includes(extra.value) ? 'white' : '',
                        clickable: "",
                        onClick: ($event: any) => (_unref(searchStore).toggleExtraFilter(extra.value))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(extra.icon) + " " + _toDisplayString(extra.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["color", "text-color", "onClick"])
                    ]))
                  }), 128)),
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_chip, {
                      clickable: "",
                      onClick: openExtraDialog
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(mainStore).vehicleExtras?.length) + " weitere ... ", 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_q_btn, {
                    rounded: "",
                    unelevated: "",
                    "no-caps": "",
                    color: "dark",
                    onClick: openExtraDialog
                  }, {
                    default: _withCtx(() => _cache[27] || (_cache[27] = [
                      _createTextVNode("Alle Ausbau-Filter anzeigen ")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("section", _hoisted_13, [
                _cache[29] || (_cache[29] = _createElementVNode("div", { class: "c-header text-h4" }, "Preis", -1)),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(CustomButtonOptionGroup, {
                        modelValue: filterData.value.is_negotiable,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((filterData.value.is_negotiable) = $event)),
                        bordered: "",
                        options: [
                    {
                      label: 'Alles',
                      value: undefined
                    },
                    {
                      label: 'Verhandlungsbasis',
                      value: true
                    },
                    {
                      label: 'Festpreis',
                      value: false
                    },
                  ],
                        class: "q-mx-md",
                        "btn-props": {noCaps: true},
                        "btn-props-active": {'color': 'dark'}
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_q_range, {
                    min: _unref(searchStore).searchResults.vehicle.minPrice,
                    max: _unref(searchStore).searchResults.vehicle.maxPrice,
                    step: 100,
                    "thumb-size": "2rem",
                    "model-value": {min: filterData.value.price_min ?? _unref(searchStore).searchResults.vehicle.minPrice, max: filterData.value.price_max ?? _unref(searchStore).searchResults.vehicle.maxPrice},
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = val => { filterData.value.price_min = val.min; filterData.value.price_max = val.max })
                  }, null, 8, ["min", "max", "model-value"])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_input, {
                      "model-value": filterData.value.price_min ?? _unref(searchStore).searchResults.vehicle.minPrice ?? 0,
                      min: 0,
                      debounce: "500",
                      outlined: "",
                      steps: "1",
                      suffix: "€",
                      type: "number",
                      label: "Minimum",
                      "stack-label": "",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = val => filterData.value.price_min= val)
                    }, null, 8, ["model-value"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_separator, { style: {"width":"30px","height":"3px"} })
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_input, {
                      "model-value": filterData.value.price_max ?? _unref(searchStore).searchResults.vehicle.maxPrice ?? 0,
                      min: 1,
                      debounce: "500",
                      outlined: "",
                      steps: "1",
                      suffix: "€",
                      type: "number",
                      label: "Maximum",
                      "stack-label": "",
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = val => filterData.value.price_max= val)
                    }, null, 8, ["model-value"])
                  ])
                ])
              ]),
              _createElementVNode("section", _hoisted_19, [
                _cache[30] || (_cache[30] = _createElementVNode("div", { class: "c-header text-h4" }, "Kilometerstand", -1)),
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_q_range, {
                    min: _unref(searchStore).searchResults.vehicle.minMileage,
                    max: _unref(searchStore).searchResults.vehicle.maxMileage,
                    step: 1_000,
                    "thumb-size": "2rem",
                    "model-value": {min: filterData.value.vehicle__mileage_min || _unref(searchStore).searchResults.vehicle.minMileage, max: filterData.value.vehicle__mileage_max || _unref(searchStore).searchResults.vehicle.maxMileage},
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = val => { filterData.value.vehicle__mileage_min = val.min; filterData.value.vehicle__mileage_max = val.max })
                  }, null, 8, ["min", "max", "model-value"])
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_input, {
                      "model-value": filterData.value.vehicle__mileage_min ?? _unref(searchStore).searchResults.vehicle.minMileage ?? 0,
                      "stack-label": "",
                      label: "Minimum",
                      min: _unref(searchStore).searchResults.vehicle.minMileage,
                      debounce: "500",
                      outlined: "",
                      steps: "1",
                      suffix: "km",
                      type: "number",
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = val => filterData.value.vehicle__mileage_min = val)
                    }, null, 8, ["model-value", "min"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_separator, { style: {"width":"30px","height":"3px"} })
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_input, {
                      "model-value": filterData.value.vehicle__mileage_max ?? _unref(searchStore).searchResults.vehicle.maxMileage ?? 0,
                      outlined: "",
                      min: _unref(searchStore).searchResults.vehicle.minMileage,
                      "stack-label": "",
                      label: "Maximum",
                      steps: "1",
                      suffix: "km",
                      type: "number",
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = val => filterData.value.vehicle__mileage_max = val)
                    }, null, 8, ["model-value", "min"])
                  ])
                ])
              ]),
              _createElementVNode("section", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _cache[31] || (_cache[31] = _createElementVNode("div", { class: "c-header text-h4" }, "Schlafplätze Minimum", -1)),
                    _createElementVNode("div", null, [
                      _createVNode(SmallNumberSelect, {
                        modelValue: filterData.value.vehicle__beds_min,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((filterData.value.vehicle__beds_min) = $event)),
                        class: "q-px-md",
                        style: {"margin-left":"-16px","margin-right":"-16px"}
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _cache[32] || (_cache[32] = _createElementVNode("div", { class: "c-header text-h4" }, "Sitzplätze Minimum", -1)),
                    _createVNode(SmallNumberSelect, {
                      modelValue: filterData.value.vehicle__seats_min,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((filterData.value.vehicle__seats_min) = $event)),
                      class: "q-px-md",
                      style: {"margin-left":"-16px","margin-right":"-16px"}
                    }, null, 8, ["modelValue"])
                  ]),
                  _cache[35] || (_cache[35] = _createElementVNode("div", { class: "col-12 c-header text-h4" }, "Fahrzeug", -1)),
                  _createElementVNode("div", _hoisted_26, [
                    _createVNode(DynamicInput, {
                      modelValue: filterData.value.vehicle__brand,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((filterData.value.vehicle__brand) = $event)),
                      modelModifiers: { lazy: true },
                      field: optionResponse.value?.vehicle?.children.brand,
                      "use-input": "",
                      outlined: "",
                      "use-chips": "",
                      options: brandOptionsFiltered.value,
                      multiple: "",
                      "map-options": "",
                      dense: "",
                      "emit-value": "",
                      behavior: "menu",
                      required: false,
                      "input-debounce": "0",
                      onFilter: brandFilterFn
                    }, null, 8, ["modelValue", "field", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _createVNode(DynamicInput, {
                      modelValue: filterData.value.vehicle__car_type,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((filterData.value.vehicle__car_type) = $event)),
                      modelModifiers: { lazy: true },
                      field: optionResponse.value?.vehicle?.children?.car_type,
                      dense: "",
                      "emit-value": "",
                      "map-options": "",
                      multiple: "",
                      outlined: "",
                      behavior: "menu",
                      "use-chips": ""
                    }, null, 8, ["modelValue", "field"])
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _createVNode(DynamicInput, {
                      modelValue: filterData.value.vehicle__build_type,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((filterData.value.vehicle__build_type) = $event)),
                      modelModifiers: { lazy: true },
                      field: buildTypeField.value,
                      behavior: "menu",
                      dense: "",
                      "emit-value": "",
                      "map-options": "",
                      multiple: "",
                      outlined: "",
                      "use-chips": ""
                    }, null, 8, ["modelValue", "field"])
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    _createVNode(DynamicInput, {
                      modelValue: filterData.value.vehicle__fuel_type,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((filterData.value.vehicle__fuel_type) = $event)),
                      modelModifiers: { lazy: true },
                      field: optionResponse.value?.vehicle?.children.fuel_type,
                      dense: "",
                      "emit-value": "",
                      "map-options": "",
                      multiple: "",
                      outlined: "",
                      behavior: "menu",
                      "use-chips": ""
                    }, null, 8, ["modelValue", "field"])
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _createVNode(_component_q_input, {
                      debounce: "500",
                      dense: "",
                      label: "Erstzulassung Jahr ab",
                      outlined: "",
                      maxlength: "4",
                      placeholder: "z.B. 2010",
                      rules: [validateYear],
                      "stack-label": "",
                      "model-value": _yearValidation.value.vehicle__registration__gte ?? filterData.value.vehicle__registration__gte,
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = val => updateYear('vehicle__registration__gte', val))
                    }, null, 8, ["rules", "model-value"])
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createVNode(_component_q_input, {
                      debounce: "500",
                      dense: "",
                      label: "Baujahr ab",
                      outlined: "",
                      maxlength: "4",
                      placeholder: "z.B. 2010",
                      "stack-label": "",
                      rules: [validateYear],
                      "model-value": _yearValidation.value.vehicle__year__gte ?? filterData.value.vehicle__year__gte,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = val => updateYear('vehicle__year__gte', val))
                    }, null, 8, ["rules", "model-value"])
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _createVNode(_component_q_checkbox, {
                      modelValue: filterData.value.has_valid_general_inspection,
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((filterData.value.has_valid_general_inspection) = $event)),
                      "false-value": null,
                      dense: "",
                      label: "Gültige HU/AU"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_33, [
                    _createVNode(_component_q_checkbox, {
                      modelValue: filterData.value.vehicle__is_serviced,
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((filterData.value.vehicle__is_serviced) = $event)),
                      "false-value": null,
                      dense: "",
                      label: "Scheckheftgepflegt"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_q_checkbox, {
                        modelValue: filterData.value.vehicle__general_inspection_renew,
                        "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((filterData.value.vehicle__general_inspection_renew) = $event)),
                        "false-value": null,
                        dense: "",
                        label: "HU nach Kauf"
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_35, [
                    _createVNode(_component_q_checkbox, {
                      modelValue: filterData.value.is_virtual_tour,
                      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((filterData.value.is_virtual_tour) = $event)),
                      "false-value": null,
                      dense: "",
                      label: "Online-Besichtigung"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_36, [
                    _createVNode(_component_q_btn, {
                      unelevated: "",
                      rounded: "",
                      "no-caps": "",
                      color: "primary",
                      onClick: openVehicleFilterDialog
                    }, {
                      default: _withCtx(() => _cache[33] || (_cache[33] = [
                        _createTextVNode("Weitere Fahrzeug-Filter ")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_37, [
                    _cache[34] || (_cache[34] = _createElementVNode("div", { class: "c-header text-h4" }, "Sortierung", -1)),
                    _createVNode(_component_q_select, {
                      "model-value": filterData.value.ordering || '',
                      options: orderingOptions,
                      "emit-value": "",
                      behavior: "menu",
                      "map-options": "",
                      outlined: "",
                      "stack-label": false,
                      "onUpdate:modelValue": _cache[23] || (_cache[23] = val => filterData.value.ordering = val === '' ? undefined : val)
                    }, null, 8, ["model-value"])
                  ])
                ])
              ])
            ])
          ])
        ], 64))
  ]))
}
}

})