import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = { class: "flex items-center justify-between q-gutter-x-sm no-wrap q-py-sm q-px-md q-header--bordered" }
const _hoisted_3 = {
  class: "bg-secondary flex justify-center items-center",
  style: {"width":"45px","height":"45px","border-radius":"15px"}
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { style: {"flex-grow":"1"} }

import {Cookies} from 'quasar';
import {onMounted, ref} from 'vue';
import {goToApp} from 'src/apps/core/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'DownloadAppNotification',
  setup(__props) {

const hide = ref<boolean>(true)

function hideAppNotification() {
  Cookies.set('hideAppNotification', 'true', {expires: '1d'})
  hide.value = true
}

onMounted(() => {
  hide.value = (Cookies.get<string>('hideAppNotification') === 'true')
})



return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (!hide.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_q_btn, {
              class: "q-px-none",
              size: "md",
              icon: "fas fa-xmark",
              round: "",
              flat: "",
              onClick: hideAppNotification
            })
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                src: require('assets/campertrader/logo/campertrader_icon_white.svg'),
                width: "30",
                alt: "Camper Trader Logo"
              }, null, 8, _hoisted_4)
            ])
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("div", { style: {"flex-grow":"2"} }, [
            _createElementVNode("div", {
              class: "text-bold",
              style: {"line-height":"1rem"}
            }, "Hol dir die App"),
            _createElementVNode("div", { class: "text-xsmall" }, "Schneller, besser, einfacher")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_q_btn, {
              rounded: "",
              unelevated: "",
              "no-caps": "",
              color: "secondary",
              "no-wrap": "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(goToApp)(_ctx.$q)))
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Zur App")
              ])),
              _: 1
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})