import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row q-col-gutter-md" }
const _hoisted_2 = {
  key: 0,
  class: "col-12"
}
const _hoisted_3 = { class: "flex q-gutter-md no-wrap" }
const _hoisted_4 = {
  key: 1,
  class: "col-12 q-mb-lg"
}
const _hoisted_5 = { style: {"font-size":"1.2rem"} }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "row q-col-gutter-md" }
const _hoisted_8 = { class: "col-12 col-sm-7 col-md-4" }
const _hoisted_9 = { class: "col-12 col-sm-5" }
const _hoisted_10 = { class: "col-12 col-sm-6" }
const _hoisted_11 = { class: "col-12 col-sm-7 col-md-4" }
const _hoisted_12 = { class: "col-12 col-sm-5" }
const _hoisted_13 = {
  key: 2,
  class: "col-12 col-sm-7 col-md-4"
}
const _hoisted_14 = { class: "col-12 col-sm-4" }
const _hoisted_15 = { class: "col-12 col-sm-4" }
const _hoisted_16 = { class: "row q-col-gutter-md q-mb-lg" }
const _hoisted_17 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_18 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_19 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_20 = { class: "row q-col-gutter-md q-my-sm items-center" }
const _hoisted_21 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_22 = { class: "col-12 col-sm-4" }
const _hoisted_23 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_24 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_25 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_26 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_27 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_28 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_29 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_30 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_31 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_32 = { class: "row q-col-gutter-md q-my-sm" }
const _hoisted_33 = { class: "col-12 q-mb-lg" }
const _hoisted_34 = { class: "row q-col-gutter-md items-center" }
const _hoisted_35 = { class: "col-12 col-sm-6" }
const _hoisted_36 = { class: "col-12 col-sm-6" }
const _hoisted_37 = { class: "col-7 col-sm-3" }
const _hoisted_38 = { class: "col-5 col-md-3" }
const _hoisted_39 = { class: "col-12 col-sm-6" }
const _hoisted_40 = { class: "col-12 col-sm-4" }
const _hoisted_41 = { class: "col-12 col-sm-4" }
const _hoisted_42 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_43 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_44 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_45 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_46 = { class: "col-12 col-sm-6 col-md-4" }
const _hoisted_47 = { class: "row q-col-gutter-md q-my-lg" }
const _hoisted_48 = { class: "col-6 col-md-4" }
const _hoisted_49 = { class: "col-6 col-md-4" }
const _hoisted_50 = { class: "col-6 col-md-4" }
const _hoisted_51 = { class: "col-6 col-md-4" }
const _hoisted_52 = { class: "col-6 col-md-4" }
const _hoisted_53 = { class: "row q-col-gutter-md q-my-sm" }
const _hoisted_54 = { class: "col-8 col-md-4" }
const _hoisted_55 = { class: "col-8 col-md-4" }
const _hoisted_56 = { class: "col-4 col-md-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_DynamicInput = _resolveComponent("DynamicInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.mainStore.accountUsage?.advert === 0 && !_ctx.mainStore.accountDetail?.active_account.is_business)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_q_banner, {
              class: "bg-info text-white",
              rounded: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_q_icon, {
                      name: "fas fa-circle-info",
                      size: "xs"
                    })
                  ]),
                  _createElementVNode("div", null, [
                    _cache[47] || (_cache[47] = _createElementVNode("div", { class: "text-h4" }, " Ein Inserat ist kostenlos! ", -1)),
                    _createElementVNode("div", null, [
                      _cache[45] || (_cache[45] = _createTextVNode("Erfahre in den ")),
                      _createVNode(_component_router_link, {
                        to: {name: 'faq-help'},
                        class: "text-white"
                      }, {
                        default: _withCtx(() => _cache[44] || (_cache[44] = [
                          _createTextVNode("FAQs")
                        ])),
                        _: 1
                      }),
                      _cache[46] || (_cache[46] = _createTextVNode(" wie wir uns finanzieren. "))
                    ])
                  ])
                ])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.mainStore.accountDetail?.businesses?.length && !_ctx.isUpdate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_q_banner, { class: "bg-grey-3 rounded-borders full-width" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _cache[48] || (_cache[48] = _createTextVNode(" Inserat einstellen als ")),
                  _createElementVNode("b", null, _toDisplayString(_ctx.mainStore.accountDetail?.active_account.public_name), 1)
                ])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _cache[50] || (_cache[50] = _createElementVNode("div", { class: "text-h3 col-12 text-grey-8" }, " Grundlegendes ", -1)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_DynamicInput, {
              modelValue: _ctx.formData.price,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.price) = $event)),
              modelModifiers: { lazy: true },
              errors: _ctx.errors.price,
              field: _ctx.fields.price,
              "data-field": "price",
              "lazy-rules": "",
              mask: "#.###.###.###",
              "max-length": "10",
              min: "0",
              outlined: "",
              "reverse-fill-mask": "",
              step: "1",
              suffix: "€",
              type: "text",
              "unmasked-value": ""
            }, null, 8, ["modelValue", "errors", "field"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_DynamicInput, {
              modelValue: _ctx.formData.is_negotiable,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.is_negotiable) = $event)),
              modelModifiers: { lazy: true },
              "error-message": _ctx.errors.is_negotiable ? _ctx.errors.is_negotiable.join(' ') : undefined,
              errors: _ctx.errors.is_negotiable,
              field: _ctx.fields.is_negotiable,
              "no-indeterminate": true,
              "data-field": "is_negotiable"
            }, null, 8, ["modelValue", "error-message", "errors", "field"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_DynamicInput, {
              modelValue: _ctx.formData.is_virtual_tour,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.is_virtual_tour) = $event)),
              modelModifiers: { lazy: true },
              "error-message": _ctx.errors.is_virtual_tour ? _ctx.errors.is_virtual_tour.join(' ') : undefined,
              errors: _ctx.errors.is_virtual_tour,
              field: _ctx.fields.is_virtual_tour,
              "no-indeterminate": true,
              "data-field": "is_virtual_tour"
            }, null, 8, ["modelValue", "error-message", "errors", "field"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.contact.phone,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.contact.phone) = $event)),
          modelModifiers: { lazy: true },
          errors: _ctx.errors.contact?.phone,
          field: _ctx.fields.contact.children.phone,
          "data-field": "contact.phone",
          hint: "z.B. +49 15678 123456",
          outlined: ""
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.is_display_phone,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.is_display_phone) = $event)),
          modelModifiers: { lazy: true },
          errors: _ctx.errors.is_display_phone,
          field: _ctx.fields.is_display_phone,
          "no-indeterminate": true,
          "data-field": "is_display_phone"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      (!_ctx.isUpdate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_DynamicInput, {
              modelValue: _ctx.formData.active,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.active) = $event)),
              modelModifiers: { lazy: true },
              errors: _ctx.errors.active,
              field: _ctx.fields.active,
              "data-field": "is_active",
              "no-indeterminate": true,
              label: "Inserat direkt veröffentlichen",
              hint: `Soll das Inserat direkt nach Erstellung öffentlich gemacht werden${_ctx.mainStore.accountDetail?.active_account.is_business ? ' und Abonnementen benachrichtigt werden?' : '?'} `
            }, null, 8, ["modelValue", "errors", "field", "hint"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.mainStore.accountDetail?.active_account.is_business)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
            _cache[49] || (_cache[49] = _createElementVNode("div", { class: "text-h4 q-mt-md col-12 text-muted" }, " Interne Verwaltung (nicht öffentlich) ", -1)),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_DynamicInput, {
                modelValue: _ctx.formData.internal_id,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.internal_id) = $event)),
                modelModifiers: { lazy: true },
                errors: _ctx.errors.internal_id,
                field: _ctx.fields.internal_id,
                "data-field": "internal_id",
                outlined: ""
              }, null, 8, ["modelValue", "errors", "field"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_DynamicInput, {
                modelValue: _ctx.formData.vehicle.vin,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.vehicle.vin) = $event)),
                modelModifiers: { lazy: true },
                errors: _ctx.errors.vehicle?.vin,
                field: _ctx.fields.vehicle.children.vin,
                "data-field": "vehicle.vin",
                outlined: ""
              }, null, 8, ["modelValue", "errors", "field"])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_16, [
      _cache[51] || (_cache[51] = _createElementVNode("div", { class: "text-h3 col-12 q-mt-lg text-grey-8" }, " Fahrzeug ", -1)),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.car_type,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.vehicle.car_type) = $event)),
          modelModifiers: { lazy: true },
          errors: _ctx.errors.vehicle?.car_type,
          field: _ctx.fields.vehicle.children.car_type,
          "data-field": "vehicle.car_type",
          "emit-value": "",
          "map-options": "",
          outlined: ""
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.brand,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formData.vehicle.brand) = $event)),
          modelModifiers: { lazy: true },
          errors: _ctx.errors.vehicle?.brand,
          field: _ctx.fields.vehicle.children.brand,
          "data-field": "vehicle.brand",
          "emit-value": "",
          "map-options": "",
          outlined: "",
          "is-filterable": ""
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.model,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formData.vehicle.model) = $event)),
          modelModifiers: { lazy: true },
          errors: _ctx.errors.vehicle?.model,
          field: _ctx.fields.vehicle.children.model,
          "data-field": "vehicle.model",
          outlined: "",
          placeholder: "z.B. Crafter 2.5 TDI",
          "stack-label": ""
        }, null, 8, ["modelValue", "errors", "field"])
      ])
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("div", _hoisted_21, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.registration,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formData.vehicle.registration) = $event)),
          errors: _ctx.errors.vehicle?.registration,
          field: _ctx.fields.vehicle.children.registration,
          "data-field": "vehicle.registration",
          "fill-mask": "_",
          mask: "##/####",
          outlined: "",
          type: "text"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.mileage,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formData.vehicle.mileage) = $event)),
          errors: _ctx.errors.vehicle?.mileage,
          field: _ctx.fields.vehicle.children.mileage,
          "data-field": "vehicle.mileage",
          mask: "###.###.###.###",
          outlined: "",
          "reverse-fill-mask": "",
          suffix: "km",
          type: "text"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.general_inspection_until,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.formData.vehicle.general_inspection_until) = $event)),
          errors: _ctx.errors.vehicle?.general_inspection_until,
          field: _ctx.fields.vehicle.children.general_inspection_until,
          "data-field": "vehicle.general_inspection_until",
          "fill-mask": "_",
          mask: "##/####",
          outlined: "",
          type: "text"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.general_inspection_renew,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.formData.vehicle.general_inspection_renew) = $event)),
          errors: _ctx.errors.vehicle?.general_inspection_renew,
          field: _ctx.fields.vehicle.children.general_inspection_renew,
          "no-indeterminate": true,
          "data-field": "vehicle.general_inspection_renew"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.year,
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.formData.vehicle.year) = $event)),
          modelModifiers: { lazy: true },
          errors: _ctx.errors.vehicle?.year,
          field: _ctx.fields.vehicle.children.year,
          "data-field": "vehicle.year",
          "emit-value": "",
          "map-options": "",
          outlined: ""
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.engine_power_ps,
          "onUpdate:modelValue": [
            _cache[16] || (_cache[16] = ($event: any) => ((_ctx.formData.vehicle.engine_power_ps) = $event)),
            _cache[17] || (_cache[17] = (val) => _ctx.changeEnginePower(val, 'ps'))
          ],
          errors: _ctx.errors.vehicle?.engine_power_ps,
          field: _ctx.fields.vehicle.children.engine_power_ps,
          "data-field": "vehicle.engine_power_ps",
          min: "0",
          outlined: "",
          "stack-label": "",
          suffix: "PS",
          type: "number"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.engine_power_kw,
          "onUpdate:modelValue": [
            _cache[18] || (_cache[18] = ($event: any) => ((_ctx.formData.vehicle.engine_power_kw) = $event)),
            _cache[19] || (_cache[19] = (val) => _ctx.changeEnginePower(val, 'kw'))
          ],
          errors: _ctx.errors.vehicle?.engine_power_kw,
          field: _ctx.fields.vehicle.children.engine_power_kw,
          "data-field": "vehicle.engine_power_kw",
          min: "0",
          outlined: "",
          "stack-label": "",
          suffix: "kW",
          type: "number"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_28, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.engine_ccm,
          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.formData.vehicle.engine_ccm) = $event)),
          errors: _ctx.errors.vehicle?.engine_ccm,
          field: _ctx.fields.vehicle.children.engine_ccm,
          "data-field": "vehicle.engine_power_ps",
          min: "0",
          outlined: "",
          "stack-label": "",
          suffix: "ccm",
          type: "number"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.fuel_type,
          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.formData.vehicle.fuel_type) = $event)),
          modelModifiers: { lazy: true },
          errors: _ctx.errors.vehicle?.fuel_type,
          field: _ctx.fields.vehicle.children.fuel_type,
          "data-field": "vehicle.fuel_type",
          "emit-value": "",
          "map-options": "",
          outlined: ""
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_30, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.has_damage,
          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.formData.vehicle.has_damage) = $event)),
          errors: _ctx.errors.vehicle?.has_damage,
          field: _ctx.fields.vehicle.children.has_damage,
          "no-indeterminate": true,
          "data-field": "vehicle.has_damage"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_31, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.is_serviced,
          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.formData.vehicle.is_serviced) = $event)),
          errors: _ctx.errors.vehicle?.is_serviced,
          field: _ctx.fields.vehicle.children.is_serviced,
          "no-indeterminate": true,
          "data-field": "vehicle.is_serviced"
        }, null, 8, ["modelValue", "errors", "field"])
      ])
    ]),
    _createElementVNode("div", _hoisted_32, [
      _cache[52] || (_cache[52] = _createElementVNode("div", { class: "text-h3 col-12 q-mt-lg text-grey-8" }, " Zusätzliche Angaben ", -1)),
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createVNode(_component_DynamicInput, {
              modelValue: _ctx.formData.vehicle.is_ready_to_go,
              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.formData.vehicle.is_ready_to_go) = $event)),
              modelModifiers: { lazy: true },
              errors: _ctx.errors.vehicle?.is_ready_to_go,
              field: _ctx.fields.vehicle.children.is_ready_to_go,
              "data-field": "vehicle.is_ready_to_go",
              "emit-value": "",
              "map-options": "",
              outlined: ""
            }, null, 8, ["modelValue", "errors", "field"])
          ]),
          _createElementVNode("div", _hoisted_36, [
            _createVNode(_component_DynamicInput, {
              modelValue: _ctx.formData.vehicle.build_type,
              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.formData.vehicle.build_type) = $event)),
              modelModifiers: { lazy: true },
              errors: _ctx.errors.vehicle?.build_type,
              field: _ctx.fields.vehicle.children.build_type,
              "data-field": "vehicle.build_type",
              "emit-value": "",
              "map-options": "",
              outlined: ""
            }, null, 8, ["modelValue", "errors", "field"])
          ]),
          _createElementVNode("div", _hoisted_37, [
            _createVNode(_component_DynamicInput, {
              modelValue: _ctx.formData.vehicle.admission,
              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.formData.vehicle.admission) = $event)),
              modelModifiers: { lazy: true },
              errors: _ctx.errors.vehicle?.admission,
              field: _ctx.fields.vehicle.children.admission,
              "data-field": "vehicle.admission",
              "emit-value": "",
              "map-options": "",
              outlined: ""
            }, null, 8, ["modelValue", "errors", "field"])
          ]),
          _createElementVNode("div", _hoisted_38, [
            _createVNode(_component_DynamicInput, {
              modelValue: _ctx.formData.vehicle.is_oldtimer,
              "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.formData.vehicle.is_oldtimer) = $event)),
              errors: _ctx.errors.vehicle?.is_oldtimer,
              field: _ctx.fields.vehicle.children.is_oldtimer,
              "no-indeterminate": true,
              "data-field": "vehicle.is_oldtimer"
            }, null, 8, ["modelValue", "errors", "field"])
          ]),
          _createElementVNode("div", _hoisted_39, [
            _createVNode(_component_DynamicInput, {
              modelValue: _ctx.formData.vehicle.roof_system,
              "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.formData.vehicle.roof_system) = $event)),
              modelModifiers: { lazy: true },
              errors: _ctx.errors.vehicle?.roof_system,
              field: _ctx.fields.vehicle.children.roof_system,
              "data-field": "vehicle.roof_system",
              "emit-value": "",
              "map-options": "",
              outlined: ""
            }, null, 8, ["modelValue", "errors", "field"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_40, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.emission_class,
          "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.formData.vehicle.emission_class) = $event)),
          modelModifiers: { lazy: true },
          errors: _ctx.errors.vehicle?.emission_class,
          field: _ctx.fields.vehicle.children.emission_class,
          "data-field": "vehicle.emission_class",
          "emit-value": "",
          "map-options": "",
          outlined: ""
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_41, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.environmental_badge,
          "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.formData.vehicle.environmental_badge) = $event)),
          modelModifiers: { lazy: true },
          errors: _ctx.errors.vehicle?.environmental_badge,
          field: _ctx.fields.vehicle.children.environmental_badge,
          "data-field": "vehicle.environmental_badge",
          "emit-value": "",
          "map-options": "",
          outlined: ""
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_42, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.number_of_owners,
          "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.formData.vehicle.number_of_owners) = $event)),
          errors: _ctx.errors.vehicle?.number_of_owners,
          field: _ctx.fields.vehicle.children.number_of_owners,
          "data-field": "vehicle.number_of_owners",
          min: "0",
          outlined: "",
          type: "number"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_43, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.transmission,
          "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.formData.vehicle.transmission) = $event)),
          modelModifiers: { lazy: true },
          errors: _ctx.errors.vehicle?.transmission,
          field: _ctx.fields.vehicle.children.transmission,
          "data-field": "vehicle.transmission",
          "emit-value": "",
          "map-options": "",
          outlined: ""
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_44, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.drive_shaft_position,
          "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.formData.vehicle.drive_shaft_position) = $event)),
          modelModifiers: { lazy: true },
          errors: _ctx.errors.vehicle?.drive_shaft_position,
          field: _ctx.fields.vehicle.children.drive_shaft_position,
          "data-field": "vehicle.drive_shaft_position",
          "emit-value": "",
          "map-options": "",
          outlined: ""
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_45, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.beds,
          "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.formData.vehicle.beds) = $event)),
          errors: _ctx.errors.vehicle?.beds,
          field: _ctx.fields.vehicle.children.beds,
          "data-field": "vehicle.beds",
          min: "0",
          outlined: "",
          type: "number"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_46, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.seats,
          "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.formData.vehicle.seats) = $event)),
          errors: _ctx.errors.vehicle?.seats,
          field: _ctx.fields.vehicle.children.seats,
          "data-field": "vehicle.seats",
          min: "0",
          outlined: "",
          type: "number"
        }, null, 8, ["modelValue", "errors", "field"])
      ])
    ]),
    _createElementVNode("div", _hoisted_47, [
      _cache[53] || (_cache[53] = _createElementVNode("div", { class: "text-h3 col-12 q-mt-lg text-grey-8" }, " Dimensionen ", -1)),
      _createElementVNode("div", _hoisted_48, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.size_length,
          "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.formData.vehicle.size_length) = $event)),
          errors: _ctx.errors.vehicle?.size_length,
          field: _ctx.fields.vehicle.children.size_length,
          "data-field": "vehicle.size_length",
          "emit-value": "",
          "map-options": "",
          outlined: ""
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_49, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.size_height,
          "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.formData.vehicle.size_height) = $event)),
          errors: _ctx.errors.vehicle?.size_height,
          field: _ctx.fields.vehicle.children.size_height,
          "data-field": "vehicle.size_height",
          "emit-value": "",
          "map-options": "",
          outlined: ""
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _cache[54] || (_cache[54] = _createElementVNode("div", { class: "text-h4 col-12 q-mt-lg text-grey-8" }, " Genaue Maße ", -1)),
      _createElementVNode("div", _hoisted_50, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.length,
          "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.formData.vehicle.length) = $event)),
          errors: _ctx.errors.vehicle?.length,
          field: _ctx.fields.vehicle.children.length,
          "data-field": "vehicle.length",
          min: "0",
          outlined: "",
          "stack-label": "",
          step: "0.01",
          suffix: "m",
          type: "number"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_51, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.height,
          "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.formData.vehicle.height) = $event)),
          errors: _ctx.errors.vehicle?.height,
          field: _ctx.fields.vehicle.children.height,
          "data-field": "vehicle.height",
          min: "0",
          outlined: "",
          "stack-label": "",
          step: "0.01",
          suffix: "m",
          type: "number"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_52, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.width,
          "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.formData.vehicle.width) = $event)),
          errors: _ctx.errors.vehicle?.width,
          field: _ctx.fields.vehicle.children.width,
          "data-field": "vehicle.width",
          min: "0",
          outlined: "",
          "stack-label": "",
          step: "0.01",
          suffix: "m",
          type: "number"
        }, null, 8, ["modelValue", "errors", "field"])
      ])
    ]),
    _createElementVNode("div", _hoisted_53, [
      _createElementVNode("div", _hoisted_54, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.maximum_weight_kg,
          "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.formData.vehicle.maximum_weight_kg) = $event)),
          errors: _ctx.errors.vehicle?.maximum_weight_kg,
          field: _ctx.fields.vehicle.children.maximum_weight_kg,
          "data-field": "vehicle.maximum_weight_kg",
          min: "0",
          outlined: "",
          "stack-label": "",
          step: "1",
          suffix: "kg",
          type: "number"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_55, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.tare_weight_kg,
          "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.formData.vehicle.tare_weight_kg) = $event)),
          errors: _ctx.errors.vehicle?.tare_weight_kg,
          field: _ctx.fields.vehicle.children.tare_weight_kg,
          "data-field": "vehicle.tare_weight_kg",
          min: "0",
          outlined: "",
          "stack-label": "",
          step: "1",
          suffix: "kg",
          type: "number"
        }, null, 8, ["modelValue", "errors", "field"])
      ]),
      _createElementVNode("div", _hoisted_56, [
        _createVNode(_component_DynamicInput, {
          modelValue: _ctx.formData.vehicle.axles,
          "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.formData.vehicle.axles) = $event)),
          "error-message": _ctx.errors.vehicle?.axles ? _ctx.errors.vehicle?.axles.join(' ') : undefined,
          errors: _ctx.errors.vehicle?.axles,
          field: _ctx.fields.vehicle.children.axles,
          "data-field": "vehicle.axles",
          min: "1",
          outlined: "",
          type: "number"
        }, null, 8, ["modelValue", "error-message", "errors", "field"])
      ])
    ])
  ]))
}