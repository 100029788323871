import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "text-center q-my-xl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_basis_data_step = _resolveComponent("basis-data-step")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_stepper_navigation = _resolveComponent("q-stepper-navigation")!
  const _component_q_step = _resolveComponent("q-step")!
  const _component_vehicle_equipment_step = _resolveComponent("vehicle-equipment-step")!
  const _component_extras_step = _resolveComponent("extras-step")!
  const _component_address_step = _resolveComponent("address-step")!
  const _component_images_step = _resolveComponent("images-step")!
  const _component_q_stepper = _resolveComponent("q-stepper")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, {
    padding: _ctx.$q.screen.lt.sm,
    class: "q-py-md header__fix footer__fix"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_form, {
        onSubmit: _withModifiers($setup.submit, ["prevent"])
      }, {
        default: _withCtx(() => [
          ($setup.fields)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([{ container: !_ctx.$q.screen.lt.sm }, "relative-position"])
              }, [
                ($setup.errors?.non_field_errors)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_q_banner, {
                        class: "bg-negative text-white",
                        rounded: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.errors.non_field_errors.join('. ')), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_stepper, {
                  ref: "stepper",
                  modelValue: $setup.step,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.step) = $event)),
                  vertical: !_ctx.$q.screen.gt.sm,
                  animated: "",
                  flat: "",
                  "header-nav": "",
                  "keep-alive": "",
                  onTransition: $setup.changeStep
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_step, {
                      error: $setup.hasError(1),
                      name: 1,
                      "data-step": "1",
                      icon: "fas fa-car",
                      title: "Basisdaten"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_basis_data_step, {
                          modelValue: $setup.formData,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formData) = $event)),
                          errors: $setup.errors,
                          fields: $setup.fields
                        }, null, 8, ["modelValue", "errors", "fields"]),
                        _createVNode(_component_q_stepper_navigation, { class: "flex q-gutter-sm justify-center q-my-md" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_btn, {
                              color: "primary",
                              label: "Weiter",
                              unelevated: "",
                              onClick: _cache[1] || (_cache[1] = 
                  () => {
                    $setup.step = $setup.step + 1;
                  }
                )
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["error"]),
                    _createVNode(_component_q_step, {
                      error: $setup.hasError(2),
                      name: 2,
                      icon: "fa-regular fa-rectangle-list",
                      title: "Fahrzeug Extras"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_vehicle_equipment_step, {
                          modelValue: $setup.formData,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formData) = $event)),
                          errors: $setup.errors,
                          fields: $setup.fields
                        }, null, 8, ["modelValue", "errors", "fields"]),
                        _createVNode(_component_q_stepper_navigation, { class: "flex q-gutter-sm justify-center q-my-md" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_btn, {
                              label: "Zurück",
                              outline: "",
                              onClick: _cache[3] || (_cache[3] = 
                  () => {
                    $setup.step = $setup.step - 1;
                  }
                )
                            }),
                            _createVNode(_component_q_btn, {
                              color: "primary",
                              label: "Weiter",
                              unelevated: "",
                              onClick: _cache[4] || (_cache[4] = 
                  () => {
                    $setup.step = $setup.step + 1;
                  }
                )
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["error"]),
                    _createVNode(_component_q_step, {
                      error: $setup.hasError(3),
                      name: 3,
                      icon: "fas fa-clipboard-list",
                      title: "Ausbau"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_extras_step, {
                          modelValue: $setup.formData,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.formData) = $event)),
                          errors: $setup.errors,
                          fields: $setup.fields,
                          onExtrasFoundInText: _cache[6] || (_cache[6] = (val) => ($setup.extrasFoundInText = val))
                        }, null, 8, ["modelValue", "errors", "fields"]),
                        _createVNode(_component_q_stepper_navigation, { class: "flex q-gutter-sm justify-center q-my-md" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_btn, {
                              label: "Zurück",
                              outline: "",
                              onClick: _cache[7] || (_cache[7] = 
                  () => {
                    $setup.step = $setup.step - 1;
                  }
                )
                            }),
                            _createVNode(_component_q_btn, {
                              color: "primary",
                              label: "Weiter",
                              unelevated: "",
                              onClick: $setup.checkExtrasInText
                            }, null, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["error"]),
                    _createVNode(_component_q_step, {
                      error: $setup.hasError(4),
                      name: 4,
                      icon: "fas fa-home",
                      title: "Adresse"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_address_step, {
                          modelValue: $setup.formData,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.formData) = $event)),
                          errors: $setup.errors,
                          fields: $setup.fields
                        }, null, 8, ["modelValue", "errors", "fields"]),
                        _createVNode(_component_q_stepper_navigation, { class: "flex q-gutter-sm justify-center q-my-md" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_btn, {
                              label: "Zurück",
                              outline: "",
                              onClick: _cache[9] || (_cache[9] = 
                  () => {
                    $setup.step = $setup.step - 1;
                  }
                )
                            }),
                            _createVNode(_component_q_btn, {
                              color: "primary",
                              label: _ctx.$route.params.uuid ? 'Weiter' : 'Speichern & Weiter',
                              unelevated: "",
                              onClick: _cache[10] || (_cache[10] = ($event: any) => {!_ctx.$route.params.uuid ? $setup.submit(false, 5) : $setup.step = 5;})
                            }, null, 8, ["label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["error"]),
                    _createVNode(_component_q_step, {
                      error: $setup.hasError(5),
                      name: 5,
                      icon: "fas fa-images",
                      title: "Bilder",
                      disable: !_ctx.$route.params.uuid
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_images_step, {
                          modelValue: $setup.formData,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.formData) = $event)),
                          errors: $setup.errors,
                          "advert-uuid": $setup.uuid
                        }, null, 8, ["modelValue", "errors", "advert-uuid"]),
                        _createVNode(_component_q_stepper_navigation, { class: "flex q-gutter-sm justify-center q-my-md" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_btn, {
                              label: "Zurück",
                              outline: "",
                              onClick: _cache[12] || (_cache[12] = 
                  () => {
                    $setup.step = $setup.step - 1;
                  }
                )
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["error", "disable"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "vertical", "onTransition"]),
                ($setup.isUpdate || $setup.step === 5 || $setup.submitted)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createVNode(_component_q_btn, {
                        color: "primary",
                        size: "lg",
                        type: "submit",
                        unelevated: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_icon, { name: "fas fa-save" }),
                          _createTextVNode("  " + _toDisplayString(!$setup.creating ? 'Inserat aktualisieren' : 'Inserat erstellen'), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["padding"]))
}