import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import {useRoute} from 'vue-router';
import {computed, ref} from 'vue';
import WidgetAdvertList from 'src/apps/widgets/components/WidgetAdvertList.vue';


import {useMainStore} from 'stores/main';

const __default__ = {
  async preFetch({store, currentRoute, redirect, urlPath}) {
    const mainStore = useMainStore(store);
    const uuid = currentRoute.params.widgetUuid
    return mainStore.fetchWidgetData(uuid).catch(err => {
      const {response} = err
      if ([404, 400].includes(response?.status)) {
        redirect({name: '404', query: {url: urlPath}})
      }
    })
  },
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'GenericAdvertListPage',
  setup(__props) {

_useCssVars(_ctx => ({
  "476feaf2": (cardColor.value),
  "8a00c42a": (borderColor.value),
  "a5e9eaec": (textColor.value),
  "205e4429": (backgroundColor.value)
}))


const mainStore = useMainStore()
const route = useRoute()
const uuid = route.params.widgetUuid?.toString()
const widgetData = {
  asCards: false,
  hideOwner: false,
  showFilter: true,
  showExtras: true,
  container: true,
  ...mainStore.widgetData[uuid]?.widget_data,
}
const textColor = ref(widgetData.textColor)
const cardColor = ref(widgetData.cardColor)
const borderColor = ref(widgetData.borderColor)
const backgroundColor = ref(widgetData.backgroundColor)


const cmpClass = computed(() => {
  return {
    'custom-border-color': !!borderColor.value,
    'custom-background-color': !!backgroundColor.value,
    'custom-card-color': !!cardColor.value,
    'custom-text-color': !!textColor.value,
  }
})

return (_ctx: any,_cache: any) => {
  return (_unref(uuid))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["absolute-full widget-pages", cmpClass.value])
      }, [
        widgetData
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["widget--wrapper", {'container': widgetData.container}])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["widget-pages", cmpClass.value])
              }, [
                _createVNode(WidgetAdvertList, {
                  "widget-uuid": _unref(uuid),
                  "as-cards": widgetData.asCards,
                  "hide-owner": widgetData.hideOwner,
                  "show-extras": widgetData.showExtras,
                  "show-filter": widgetData.showFilter,
                  target: '_blank'
                }, null, 8, ["widget-uuid", "as-cards", "hide-owner", "show-extras", "show-filter"])
              ], 2)
            ], 2))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}
}

})