import {api} from 'boot/axios';
import {SearchType} from 'src/apps/search/types';

export default {
  autocompleteSuggestion: function (searchTerm, searchType: SearchType) {
    return api.post(`search/suggest/${searchType}/`, {s: searchTerm})
  },

  fetchUserSearchHistory: function (searchType: SearchType) {
    return api.get(`search/history/${searchType}/`)
  },
}
