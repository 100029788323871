import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }

import {computed} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BackBtnLayout',
  props: {
  narrow: {type: Boolean, default: false},
  route: {type: String, default: '/'},
},
  setup(__props) {



const hasHistory = computed(() => {
  if (typeof window !== 'undefined') {
    return window.history?.length > 0 && window.history.state?.back !== null;
  }
  return false;
})


return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "lHh Lpr lff" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_no_ssr, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_header, {
            class: "bg-white text-dark",
            "height-hint": "50",
            bordered: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_toolbar, {
                class: _normalizeClass([__props.narrow ? 'c-page-narrow' : 'container', "q-px-none flex q-gutter-x-xs"])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_q_btn, {
                      flat: "",
                      style: {"margin-left":"-12px"},
                      icon: "fas fa-arrow-left",
                      round: "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (hasHistory.value ? _ctx.$router.go(-1) : _ctx.$router.push(__props.route)))
                    })
                  ])
                ]),
                _: 1
              }, 8, ["class"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_page_container, {
        class: _normalizeClass({'c-page-narrow': __props.narrow})
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }))
}
}

})