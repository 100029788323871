import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "flex justify-between no-wrap items-center",
  style: {"column-gap":"8px"}
}
const _hoisted_2 = {
  class: "flex",
  style: {"column-gap":"8px"}
}

import {useDialogPluginComponent} from 'quasar'
import BaseDialogContent from 'src/apps/core/components/BaseDialogContent.vue';
import {Cropper} from 'vue-advanced-cropper';
import {PropType, ref} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageEditorDialog',
  props: {
  imageFiles: {
    type: Array as PropType<File[]>,
    required: true
  },
  aspectRatio: {
    type: Number,
    default: 1
  }
},
  emits: [
  ...useDialogPluginComponent.emits
],
  setup(__props) {

const props = __props



const processedImages = ref<File[]>([]);
const currentIndex = ref(0);
const cropperRef = ref<any>(null);

function getLocalFileUrl(file) {
  return URL.createObjectURL(file)
}

function stencilSize({boundaries}) {
  return {
    width: Math.min(boundaries.height, boundaries.width) - 48,
    height: Math.min(boundaries.height, boundaries.width) - 48,
  };
}

function defaultSize({imageSize}) {
  return {
    width: Math.min(imageSize.height, imageSize.width),
    height: Math.min(imageSize.height, imageSize.width),
  }
}

function zoom(val) {
  if (!cropperRef.value) return
  cropperRef.value?.zoom(val);
}

function rotate(angle) {
  if (!cropperRef.value) {

    console.error('Cropper not found')
  }
  cropperRef.value?.rotate(angle);
}

function saveCroppedImage() {
  const cropper = cropperRef.value as any;
  if (cropper) {
    const canvas = cropper.getResult().canvas;
    if (canvas) {
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], props.imageFiles[currentIndex.value].name, {type: props.imageFiles[currentIndex.value]?.type});
          processedImages.value.push(file);
          if (currentIndex.value >= props.imageFiles.length - 1) {
            onDialogOK(processedImages.value);
          } else {
            currentIndex.value++;
          }
        }
      }, props.imageFiles[currentIndex.value].type);
    }
  } else {
    console.error('Cropper not found');
  }
}

const {dialogRef, onDialogHide, onDialogOK} = useDialogPluginComponent()

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref_key: "dialogRef",
    ref: dialogRef,
    maximized: true,
    onHide: _unref(onDialogHide)
  }, {
    default: _withCtx(() => [
      _createVNode(BaseDialogContent, { "content-classes": "q-pa-none" }, {
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_q_btn, {
                round: "",
                unelevated: "",
                color: "dark",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (rotate(-90)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, { name: "fas fa-undo" })
                ]),
                _: 1
              }),
              _createVNode(_component_q_btn, {
                round: "",
                unelevated: "",
                color: "dark",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (rotate(90)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, { name: "fas fa-redo" })
                ]),
                _: 1
              }),
              _createVNode(_component_q_btn, {
                round: "",
                unelevated: "",
                color: "dark",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (zoom(0.5)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, { name: "fas fa-magnifying-glass-minus" })
                ]),
                _: 1
              }),
              _createVNode(_component_q_btn, {
                round: "",
                unelevated: "",
                color: "dark",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (zoom(2)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, { name: "fas fa-magnifying-glass-plus" })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_q_btn, {
                color: "primary",
                onClick: saveCroppedImage
              }, {
                default: _withCtx(() => [
                  (currentIndex.value >= props.imageFiles.length - 1)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(" Speichern ")
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(" Weiter ")
                      ], 64))
                ]),
                _: 1
              })
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_unref(Cropper), {
            ref_key: "cropperRef",
            ref: cropperRef,
            src: getLocalFileUrl(__props.imageFiles[currentIndex.value]),
            class: "custom-cropper",
            "background-class": "custom-cropper__background",
            "foreground-class": "custom-cropper__foreground",
            "default-size": defaultSize,
            "stencil-size": stencilSize,
            canvas: true,
            "stencil-props": {
          handlers: {},
          movable: false,
          resizable: false,
          aspectRatio: __props.aspectRatio,
          previewClass: 'custom-cropper__stencil',
        },
            "image-restriction": "stencil"
          }, null, 8, ["src", "stencil-props"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onHide"]))
}
}

})