
import {computed, defineComponent, watch} from 'vue';
import {useMainStore} from 'stores/main';
import {useRoute} from 'vue-router';
import DynamicInput from 'components/qutils/form/DynamicInput.vue';

const CONVERSION_CONSTANT_KW_TO_PS = 1.35962


export default defineComponent({
  components: {DynamicInput},
  props: {
    modelValue: {
      required: true,
      type: Object
    },
    errors: {
      required: false,
      default: () => ({}),
      type: Object,
    },
    fields: {
      default: () => ({}),
      type: Object,
    }
  },
  emits: ['update:modelValue'],
  setup(props, ctx) {
    const mainStore = useMainStore()
    const route = useRoute()
    const formData = computed({
      // getter
      get(): any {
        return props.modelValue
      },
      // setter
      set(newValue) {
        ctx.emit('update:modelValue', newValue)
      }
    })

    const isUpdate = computed(() => {
      return !!route.params.uuid;
    });

    const changeEnginePower = function (val, powerType) {
      if (powerType === 'ps') {
        formData.value.vehicle.engine_power_kw = Math.round(val / CONVERSION_CONSTANT_KW_TO_PS)
      } else if (powerType === 'kw') {
        formData.value.vehicle.engine_power_ps = Math.round(val * CONVERSION_CONSTANT_KW_TO_PS)
      }
    }

    const businessOptions = computed(() => {
      let options = [
        {label: 'Privat', value: null}
      ]
      const businesses = mainStore.accountDetail?.businesses
      if (mainStore.accountDetail?.businesses?.length) {
        let _options = businesses?.flatMap(obj => {
          return {label: obj['name'], value: obj['uuid']}
        })
        if (_options) {
          options = options.concat(_options)
        }
      }
      return options
    })

    watch(
      () => formData,
      (val) => {
        ctx.emit('update:modelValue', val)
      },
      {deep: true}
    )

    return {
      changeEnginePower,
      formData,
      businessOptions,
      moneyFormatForComponent: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: ' #',
        precision: 2,
        masked: true
      },
      mainStore,
      isUpdate,
    }
  }
})


