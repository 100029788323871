import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "full-width full-height relative-position" }
const _hoisted_2 = {
  class: "absolute-top-right q-pa-sm",
  style: {"z-index":"400"}
}

import {getCssVar, useDialogPluginComponent} from 'quasar'
import {LCircle, LMap, LTileLayer} from '@vue-leaflet/vue-leaflet';
import {ref} from 'vue';
import MapBusinessIcon from 'src/apps/core/components/icons/MapBusinessIcon.vue';
import MapAdvertIcon from 'src/apps/core/components/icons/MapAdvertIcon.vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'MapDialog',
  props: {
  center: {
    default: undefined,
  },
  zoom: {
    default: 12
  },
  isBusiness: {
    type: Boolean,
    default: false,
  },
},
  emits: [
  ...useDialogPluginComponent.emits
],
  setup(__props) {



const mapRef = ref(null)
const options = ref({
  zoomControl: true
})



const {dialogRef, onDialogHide} = useDialogPluginComponent()

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref_key: "dialogRef",
    ref: dialogRef,
    maximized: _ctx.$q.screen.lt.sm,
    onHide: _unref(onDialogHide)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(LMap), {
          ref_key: "mapRef",
          ref: mapRef,
          options: options.value,
          style: {"height":"100%","width":"100%"},
          center: __props.center,
          zoom: 12
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(LTileLayer), {
              url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
              "layer-type": "base",
              name: "OpenStreetMap"
            }),
            (__props.isBusiness)
              ? (_openBlock(), _createBlock(MapBusinessIcon, {
                  key: 0,
                  "lat-lng": __props.center
                }, null, 8, ["lat-lng"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_unref(LCircle), {
                    "lat-lng": __props.center,
                    radius: 1500,
                    "fill-color": _unref(getCssVar)('secondary'),
                    color: _unref(getCssVar)('secondary')
                  }, null, 8, ["lat-lng", "fill-color", "color"]),
                  _createVNode(MapAdvertIcon, { "lat-lng": __props.center }, null, 8, ["lat-lng"])
                ], 64))
          ]),
          _: 1
        }, 8, ["options", "center"]),
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createVNode(_component_q_btn, {
            icon: "fas fa-close",
            round: "",
            color: "white",
            "text-color": "dark",
            "aria-label": "close",
            unelevated: ""
          }, null, 512), [
            [_directive_close_popup]
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["maximized", "onHide"]))
}
}

})