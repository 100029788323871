import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex column justify-between full-height no-wrap" }
const _hoisted_2 = { class: "text-center q-py-xl" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "q-px-md q-pt-md" }

import SocialMediaLogos from 'components/SocialMediaLogos.vue';
import {useQuasar} from 'quasar';


export default /*@__PURE__*/_defineComponent({
  __name: 'DrawerMenu',
  props: {
  modelValue: {required: true}
},
  emits: ['update:modelValue'],
  setup(__props) {

const $q = useQuasar()






const menuItems = [
  {
    title: 'Suchen',
    icon: 'fas fa-search',
    link: {name: 'search'},
  },
  {
    title: 'Fahrzeug verkaufen',
    icon: 'far fa-plus',
    link: {name: 'createAdvert'},
  },
  {
    title: 'Blog',
    icon: 'far fa-rectangle-list',
    link: '/blog/',
    target: '_blank',
  },
  {
    title: 'Über uns',
    icon: 'fas fa-info-circle',
    link: {name: 'about-us'},
  },
  {
    title: 'Kontakt',
    icon: 'fas fa-info-circle',
    link: {name: 'contact'},
  },
]

if (process.env.DEV) {
  menuItems.push({
    title: 'Developer',
    icon: 'fas fa-cog',
    link: {name: 'developer'},
  })
}


// if not app app
if (!($q.platform.is.nativeMobile)) {
  // add on top to menu items
  menuItems.unshift({
    title: 'Startseite',
    icon: 'fas fa-home',
    link: {name: 'home'},
  })

  menuItems.push({
    title: 'App herunterladen',
    icon: 'fas fa-download',
    link: {name: 'download-app'},
  })
}


return (_ctx: any,_cache: any) => {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_drawer = _resolveComponent("q-drawer")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_unref($q).screen.lt.sm)
    ? (_openBlock(), _createBlock(_component_q_drawer, {
        key: 0,
        "model-value": __props.modelValue,
        draggable: "false",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = val => _ctx.$emit('update:modelValue', val))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  alt: "Camper Trader logo",
                  width: "200px",
                  src: require('src/assets/logos/campertrader_logo_ocean_w_text.svg')
                }, null, 8, _hoisted_3)
              ]),
              _createVNode(_component_q_list, {
                class: "q-py-none",
                padding: "",
                separator: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(menuItems, (item) => {
                    return _withDirectives(_createVNode(_component_q_item, {
                      key: item.title,
                      to: item.link,
                      target: item?.target,
                      clickable: "",
                      exact: "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', false)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { class: "text-bold" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.title), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["to", "target"]), [
                      [_directive_ripple]
                    ])
                  }), 64))
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_q_btn, {
                  to: {name: 'login'},
                  unelevated: "",
                  color: "secondary",
                  rounded: "",
                  size: "lg",
                  class: "full-width"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Anmelden ")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(SocialMediaLogos)
            ])
          ])
        ]),
        _: 1
      }, 8, ["model-value"]))
    : _createCommentVNode("", true)
}
}

})