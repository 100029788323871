<template>
  <div style="width: 100svw; height: 100svh" class="relative-position">
    <div class="absolute-center">
      <q-spinner-tail size="6rem"/>
    </div>
  </div>
</template>

<script setup>
import {onMounted} from 'vue';
import {APPLE_STORE_URL, GOOGLE_PLAY_URL} from 'src/apps/core/static';
import {useQuasar} from 'quasar';
import {useRouter} from 'vue-router';

const $q = useQuasar();
const $router = useRouter()

onMounted(() => {
  let url
  if ($q.platform.is.nativeMobile) {
    $router.replace('/')
    return
  }
  if ($q.platform.is.ios || $q.platform.is.mac || $q.platform.is.safari) {
    // is iOS
    url = APPLE_STORE_URL
    window.open(url, '_self')
  } else if ($q.platform.is.android) {
    // is Android
    url = GOOGLE_PLAY_URL
    window.open(url, '_self')
  } else {
    // is something else
    $router.replace('/')
  }
})


</script>

<style scoped>
/* Add any styles if needed */
</style>
