import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import {useMainStore} from 'stores/main';
import {onMounted, onUnmounted, ref} from 'vue';
import VehicleExtraChip from 'src/components/etc/VehicleExtraChip.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'IndexExtrasSliderNoText',
  props: {
  chipColor: {type: String, default: 'white'},
  size: {type: String, default: 'md'}
},
  emits: ['ready'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const mainStore = useMainStore()
const extraWrapperRef = ref(null)
const extraRef = ref(null)
let extrasInterval = ref()
const activeExtra = ref()

const getRandomExtra = function () {
  emit('ready')
  return mainStore.vehicleExtras[Math.floor(Math.random() * mainStore.vehicleExtras?.length)]
}

const clearExtrasInterval = function () {
  clearInterval(extrasInterval.value)
}


const startInterval = function () {
  clearExtrasInterval()
  extrasInterval.value = setInterval(() => {
    activeExtra.value = getRandomExtra()
  }, 3000)
}

onMounted(() => {
  activeExtra.value = getRandomExtra()
  startInterval()
})

onUnmounted(() => {
  clearExtrasInterval()
})


return (_ctx: any,_cache: any) => {
  return (activeExtra.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          ref_key: "extraWrapperRef",
          ref: extraWrapperRef,
          class: "item"
        }, [
          _createElementVNode("div", {
            ref_key: "extraRef",
            ref: extraRef,
            class: "chip-container"
          }, [
            _createVNode(_Transition, {
              mode: "out-in",
              name: "slide-fade"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(VehicleExtraChip, {
                  key: activeExtra.value?.value,
                  "model-value": activeExtra.value,
                  onmouseenter: clearExtrasInterval,
                  onmouseleave: startInterval,
                  color: __props.chipColor,
                  size: __props.size,
                  "chip-class": "shadow-2"
                }, null, 8, ["model-value", "color", "size"]))
              ]),
              _: 1
            })
          ], 512)
        ], 512)
      ]))
    : _createCommentVNode("", true)
}
}

})