import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "relative-position full-height" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "category-btn--content flex justify-center column items-center full-height" }
const _hoisted_4 = { class: "full-height" }
const _hoisted_5 = {
  class: "flex no-wrap full-height items-center justify-center",
  style: {"column-gap":"12px"}
}
const _hoisted_6 = {
  key: 0,
  class: "flex flex-center"
}
const _hoisted_7 = ["title", "src"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { style: {"white-space":"nowrap"} }
const _hoisted_10 = {
  key: 0,
  class: "absolute-left arrows arrow-left full-height flex justify-center items-center"
}
const _hoisted_11 = {
  key: 0,
  class: "absolute-right arrows arrow-right full-height flex justify-center items-center"
}

import {nextTick, onMounted, onUnmounted, PropType, ref, watch} from 'vue';
import {CategoryItem} from 'src/apps/search/types';


const scrollThresholdPx = 5
const scrollByValue = 150


export default /*@__PURE__*/_defineComponent({
  __name: 'CategoryScroll',
  props: {
  modelValue: {type: Object as PropType<CategoryItem>, default: undefined},
  items: {type: Array<CategoryItem>}
},
  emits: ['update:modelValue'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props

const virtualScrollRef = ref<any>();
const isScrollEnd = ref(false)
const isScrollStart = ref(true)
const isOverflow = ref(true)
const bottomLineRef = ref<HTMLElement | null>(null)


let ticking = false;

const emit = __emit

function clickItem(item: CategoryItem) {
  let _item: undefined | object = undefined
  if (props.modelValue?.name !== item.name) {
    _item = item
  }
  emit('update:modelValue', _item)
}

function scrollRight() {
  if (!virtualScrollRef.value) return;
  const scrollableElement = virtualScrollRef.value;
  const maxScrollLeft = scrollableElement.scrollWidth - scrollableElement.clientWidth;
  const currentScrollLeft = scrollableElement.scrollLeft;
  const remainingScroll = maxScrollLeft - currentScrollLeft;

  // Determine the new scroll position
  let newScrollPosition;
  if (remainingScroll <= scrollByValue * 2) {
    // Snap to the end if less than double scrollByValue remains
    newScrollPosition = maxScrollLeft;
  } else {
    // Otherwise, scroll right by scrollByValue
    newScrollPosition = currentScrollLeft + scrollByValue;
  }

  // Perform the smooth scroll
  scrollableElement.scrollTo({
    left: newScrollPosition,
    behavior: 'smooth'
  });
}

const scrollLeft = () => {
  if (!virtualScrollRef.value) return
  const scrollableElement = virtualScrollRef.value;
  const currentScrollLeft = scrollableElement.scrollLeft;

  let newScrollPosition = currentScrollLeft - scrollByValue;
  if (newScrollPosition < 0) {
    newScrollPosition = 0; // Ensure we don't scroll past the start
  }

  // Use scrollTo with smooth behavior
  scrollableElement.scrollTo({
    left: newScrollPosition,
    behavior: 'smooth'
  });
};

const resetScroll = () => {
  if (virtualScrollRef.value) {
    virtualScrollRef.value.scrollTo({
      left: 0,
      behavior: 'smooth'
    });
  }
};

function checkAndSetOverflow() {
  nextTick(() => {
    if (!virtualScrollRef.value) {
      console.debug('virtualScrollRef not set');
      return
    }
    const element = virtualScrollRef.value
    const isOverflowing = element.scrollWidth > element.clientWidth
    isOverflow.value = isOverflowing
    return isOverflowing
  })
}

watch(
  () => props.items,
  () => {
    checkAndSetOverflow()
  }, {immediate: true}
)

function onScroll() {
  if (virtualScrollRef.value) {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        const element = virtualScrollRef.value
        isScrollEnd.value = (element.clientWidth + element.scrollLeft + scrollThresholdPx) >= element.scrollWidth
        isScrollStart.value = element.scrollLeft <= scrollThresholdPx
        ticking = false;
      });

      ticking = true;
    }
  }
}

onMounted(() => {
  window.addEventListener('resize', onScroll);

  // Check if active item is visible otherwise scroll to position
  if (virtualScrollRef.value) {
    const element = virtualScrollRef.value
    const activeElement = element.querySelector('.active')
    if (activeElement) {
      const activeElementRect = activeElement.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();
      if (activeElementRect.right > elementRect.right || activeElementRect.left < elementRect.left) {
        element.scrollTo({
          left: element.scrollLeft + activeElementRect.left - elementRect.left - 75,
        });
      }
    }
  }
})

function setBottomLinePosition() {
  nextTick(() => {
    if (!bottomLineRef.value || !virtualScrollRef.value) return;

    const element = virtualScrollRef.value;
    const activeElement = element.querySelector('.active');

    if (activeElement) {
      const activeElementRect = activeElement.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();

      // Check if the active element is out of the visible bounds
      const isOutOfView =
        activeElementRect.left < elementRect.left ||
        activeElementRect.right > elementRect.right;

      if (isOutOfView) {
        // If out of view, scroll to the active element
        const leftOffset = activeElementRect.left - elementRect.left + element.scrollLeft;
        element.scrollTo({
          left: leftOffset - 75, // Adjust to make the active element more centered
          behavior: 'smooth',
        });
      }

      // Set the bottom line position
      const leftOffset = activeElement.offsetLeft;
      bottomLineRef.value.style.display = 'block';
      bottomLineRef.value.style.left = `${leftOffset}px`;
      bottomLineRef.value.style.width = `${activeElementRect.width}px`;
    } else {
      bottomLineRef.value.style.display = 'none';
    }
  });
}
onUnmounted(() => {
  window.removeEventListener('resize', onScroll)
})

watch(
  () => props.modelValue,
  () => {
    setBottomLinePosition()
  },
  {immediate: true}
)

__expose({resetScroll})


return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref_key: "virtualScrollRef",
      ref: virtualScrollRef,
      class: "category--wrapper q-px-md full-width full-height text-center flex no-wrap scroll-x relative-position",
      onScroll: onScroll
    }, [
      _createElementVNode("div", {
        ref_key: "bottomLineRef",
        ref: bottomLineRef,
        class: "c-bottom-line"
      }, null, 512),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass([{ 'active': __props.modelValue?.name === item.name }, "category-btn"]),
          onClick: ($event: any) => (clickItem(item))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_q_btn, {
                class: _normalizeClass(["category-btn--btn full-height", {active: __props.modelValue?.name === item.name}]),
                flat: "",
                square: "",
                "no-caps": ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    (item.image)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("img", {
                            alt: "",
                            title: `Filter nach ${item.label}`,
                            src: item.image,
                            height: "18"
                          }, null, 8, _hoisted_7)
                        ]))
                      : (item.icon)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createVNode(_component_q_icon, {
                              name: item.icon,
                              size: "xs"
                            }, null, 8, ["name"])
                          ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(item.label), 1)
                  ])
                ]),
                _: 2
              }, 1032, ["class"])
            ])
          ])
        ], 10, _hoisted_2))
      }), 128))
    ], 544),
    _createVNode(_Transition, {
      "leave-active-class": "animated fadeOut",
      "enter-active-class": "animated fadeIn"
    }, {
      default: _withCtx(() => [
        (isOverflow.value && !isScrollStart.value && _ctx.$q.screen.gt.xs)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", null, [
                _createVNode(_component_q_btn, {
                  size: "sm",
                  color: "white",
                  round: "",
                  dense: "",
                  "text-color": "dark",
                  onClick: scrollLeft
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "fas fa-chevron-left",
                      size: "0.6rem"
                    })
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, {
      "leave-active-class": "animated fadeOut",
      "enter-active-class": "animated fadeIn"
    }, {
      default: _withCtx(() => [
        (isOverflow.value && !isScrollEnd.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", null, [
                _createVNode(_component_q_btn, {
                  size: "sm",
                  color: "white",
                  round: "",
                  dense: "",
                  "text-color": "dark",
                  onClick: scrollRight
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "fas fa-chevron-right",
                      size: "0.6rem"
                    })
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})