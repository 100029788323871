import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row q-col-gutter-md q-my-sm" }
const _hoisted_2 = {
  key: 0,
  class: "col-12"
}
const _hoisted_3 = {
  key: 1,
  class: "q-py-md"
}
const _hoisted_4 = { class: "flex q-gutter-md" }
const _hoisted_5 = { class: "col-12 col-sm-8" }
const _hoisted_6 = { class: "col-12 col-sm-4" }
const _hoisted_7 = { class: "col-5 col-md-3" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "col-12 col-md-6" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "text-positive flex items-center" }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { class: "flex items-center text-negative" }

import 'leaflet/dist/leaflet.css';
import {computed, Ref, ref, watch, WritableComputedRef} from 'vue';
import {useMainStore} from 'stores/main';
import Core from 'src/api/core';
import {debounce} from 'quasar';
import DynamicInput from 'components/qutils/form/DynamicInput.vue';
import {LMap, LMarker, LTileLayer} from '@vue-leaflet/vue-leaflet';


export default /*@__PURE__*/_defineComponent({
  __name: 'AddressStep',
  props: {
  modelValue: {
    required: true,
  },
  errors: {
    required: false
  },
  fields: {
    default: {}
  }
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const emit = __emit
const props = __props

const mainStore = useMainStore()

const isCheckingAddress = ref(false)
const isValidAddress = ref<boolean | undefined>(undefined)
const markers = ref<Array<object>>([])
const mapRef: Ref<any> = ref(null);
const options = ref({zoomControl: false})
const addressError = ref()

const disableMap = () => {
  const map = mapRef.value.leafletObject
  if (!map) {
    console.error('no map')
  }
  map.dragging.disable();
  map.touchZoom.disable();
  map.doubleClickZoom.disable();
  map.scrollWheelZoom.disable();
  map.boxZoom.disable();
  map.keyboard.disable();
  if (map.tap) map.tap.disable();
}

const initMap = async function () {
  disableMap()
}

const checkAddress = debounce(() => {
  isCheckingAddress.value = true
  isValidAddress.value = undefined
  addressError.value = undefined
  markers.value = []

  Core.checkAddress(formData.value.address).then(resp => {
    isValidAddress.value = !!(resp.data.latitude && resp.data.longitude);
    isValidAddress.value = true
    markers.value.push({
      latLng: [parseFloat(resp.data.latitude), parseFloat(resp.data.longitude)]
    })
    mapRef.value.leafletObject.setView([parseFloat(resp.data.latitude), parseFloat(resp.data.longitude)], resp.data.street_and_number ? 16 : 12)
  }).catch(err => {
    if (err.response.data.non_field_errors) {
      addressError.value = 'Adresse konnte nicht ermittelt werden. Bitte überprüfe deine Angaben.'
    } else {
      addressError.value = 'Fehler beim Abrufen der Adresse.'
    }

    isValidAddress.value = false
    console.error(err)
  }).finally(() => {
    isCheckingAddress.value = false
  })
}, 600)

const formData: WritableComputedRef<any> = computed({
  // getter
  get() {
    return props.modelValue
  },
  // setter
  set(newValue) {
    emit('update:modelValue', newValue)
  }
})


watch(() => formData.value.address,
  (address) => {
    if (address?.country && address?.city && address?.zip_code) {
      isCheckingAddress.value = true
      isValidAddress.value = false
      checkAddress()
    }
  },
  {deep: true, immediate: true}
)

return (_ctx: any,_cache: any) => {
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_spinner = _resolveComponent("q-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.errors.address?.non_field_errors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_q_banner, {
            class: "bg-negative text-white",
            rounded: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.errors.address.non_field_errors.join('. ')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _cache[8] || (_cache[8] = _createElementVNode("div", null, [
      _createElementVNode("h4", null, "Warum wir deine Adresse brauchen?"),
      _createElementVNode("p", null, [
        _createTextVNode(" Wir brauchen deine Adresse um bei bei unserer Suchfunktion die Distanzen berechnen zu können."),
        _createElementVNode("br"),
        _createTextVNode(" Deine Adresse "),
        _createElementVNode("b", null, "ist nicht vollständig sichtbar"),
        _createTextVNode(" für andere. ")
      ])
    ], -1)),
    (_unref(mainStore).accountDetail?.active_account?.is_business)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-h4 q-mb-md" }, " Von Geschäftsadresse oder Standort übernehmen ", -1)),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mainStore).accountDetail.active_account.locations, (location) => {
              return (_openBlock(), _createElementBlock("div", {
                key: location.id
              }, [
                _createVNode(_component_q_btn, {
                  color: "grey-6",
                  rounded: "",
                  unelevated: "",
                  onClick: ($event: any) => {formData.value.address = location; formData.value.address.id = undefined}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(location.address_line), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(DynamicInput, {
        modelValue: formData.value.address.street_and_number,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.value.address.street_and_number) = $event)),
        modelModifiers: { lazy: true },
        errors: __props.errors.address?.street_and_number,
        field: __props.fields.address.children.street_and_number,
        outlined: ""
      }, null, 8, ["modelValue", "errors", "field"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(DynamicInput, {
        modelValue: formData.value.address.addition,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.address.addition) = $event)),
        modelModifiers: { lazy: true },
        errors: __props.errors.address?.addition,
        field: __props.fields.address.children.addition,
        outlined: ""
      }, null, 8, ["modelValue", "errors", "field"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(DynamicInput, {
        modelValue: formData.value.address.zip_code,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.value.address.zip_code) = $event)),
        modelModifiers: { lazy: true },
        errors: __props.errors.address?.zip_code,
        field: __props.fields.address.children.zip_code,
        outlined: ""
      }, null, 8, ["modelValue", "errors", "field"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(DynamicInput, {
        modelValue: formData.value.address.city,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData.value.address.city) = $event)),
        modelModifiers: { lazy: true },
        errors: __props.errors.address?.city,
        field: __props.fields.address.children.city,
        outlined: ""
      }, null, 8, ["modelValue", "errors", "field"])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(DynamicInput, {
        modelValue: formData.value.address.country,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formData.value.address.country) = $event)),
        modelModifiers: { lazy: true },
        errors: __props.errors.address?.country,
        field: __props.fields.address.children.country,
        "emit-value": "",
        "map-options": "",
        outlined: ""
      }, null, 8, ["modelValue", "errors", "field"])
    ]),
    _createElementVNode("div", _hoisted_10, [
      (isValidAddress.value && !isCheckingAddress.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_q_icon, { name: "done" }),
              _cache[6] || (_cache[6] = _createTextVNode("  Adresse ist gültig "))
            ])
          ]))
        : (isCheckingAddress.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createVNode(_component_q_spinner, null, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Checking")
                ])),
                _: 1
              })
            ]))
          : (isValidAddress.value === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_q_icon, { name: "fas fa-x" }),
                  _createTextVNode("  " + _toDisplayString(addressError.value), 1)
                ])
              ]))
            : _createCommentVNode("", true),
      _createElementVNode("div", null, [
        _createVNode(_unref(LMap), {
          ref_key: "mapRef",
          ref: mapRef,
          options: options.value,
          style: {"height":"250px"},
          center: [48.47, 10.85],
          zoom: 8,
          onReady: initMap
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(markers.value, (marker) => {
              return (_openBlock(), _createBlock(_unref(LMarker), {
                key: marker,
                "lat-lng": marker.latLng
              }, null, 8, ["lat-lng"]))
            }), 128)),
            _createVNode(_unref(LTileLayer), {
              url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
              "layer-type": "base",
              name: "OpenStreetMap"
            })
          ]),
          _: 1
        }, 8, ["options"])
      ])
    ])
  ]))
}
}

})