import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center q-my-xl" }
const _hoisted_2 = ["innerHTML"]

import Cms from 'src/api/cms';
import {ref} from 'vue';
import {Page} from 'src/models/cms';
import {useMainStore} from 'stores/main';
import {useMeta} from 'quasar';
import {getPageUrl} from 'src/etc/helper';
import {useRouter} from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'PrivacyPage',
  setup(__props) {

const page = ref<Page>()
const router = useRouter()
const mainStore = useMainStore()

Cms.getPage('privacy').then(resp => {
  page.value = resp.data
}).catch(err => {
  console.error(err)
})

useMeta(() => {
  return {
    // sets document title
    titleTemplate(title) {
      return `Datenschutz | ${title}`
    },

    meta: {
      description: {
        name: 'description',
        content: 'Sicherheit und Privatsphäre sind unsere Priorität. Erfahre, wie wir Deine Daten schützen ' +
          'und respektieren gemäß aktueller Datenschutzstandards.',
      },
    },

    link: {
      canonical: {
        rel: 'canonical', href: getPageUrl('privacy', router) || ''
      }
    },
  }
})

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createVNode(_component_q_btn, {
          flat: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(mainStore).showCookieBanner = true))
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Deine Cookie-Einstellungen ändern")
          ])),
          _: 1
        })
      ]),
      (page.value?.content)
        ? (_openBlock(), _createElementBlock("section", {
            key: 0,
            class: "container vhtml-container",
            innerHTML: page.value.content
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})