import {
  CapacitorBrowser,
  CapacitorCore,
  CapacitorDirectory,
  CapacitorFilesystem,
  CapacitorShare
} from 'boot/inject-capacitor';
import ShareModal from 'components/ShareModal.vue';
import Filter from 'src/etc/qutils/filter';
import {api} from 'boot/axios';
import {truncate} from 'src/etc/utils';

export async function fetchModifyAndShareAdvertImage(advert, text, asImage = false) {
  const shareOptions = {
    title: 'Gefunden auf CamperTrader.de',
    text: text,
    url: advert.seo_link,
    dialogTitle: 'Inserat teilen',
  }

  if (asImage && advert.cover_image?.image.path) {
    try {
      const response = await api.get(`adverts/${advert.uuid}/share-image/`, {responseType: 'blob'});
      const blob = await response.data;
      const base64Image = await createOverlayImage(blob, advert?.uuid.slice(0, 4).toUpperCase());

      const fileName = `image_share_${advert?.uuid}.jpg`
      await CapacitorFilesystem.writeFile({
        path: fileName,
        data: base64Image.split(',')[1],
        directory: CapacitorDirectory.External,
      });

      const fileUri = await CapacitorFilesystem.getUri({
        directory: CapacitorDirectory.External,
        path: fileName,
      });

      shareOptions['url'] = fileUri.uri
      shareOptions['text'] = shareOptions['text'] + ` • ${advert?.seo_link}`
    } catch (error) {
      console.error('Error sharing image:', error);
    }
  }

  console.debug('shareOptions', JSON.stringify(shareOptions))
  await CapacitorShare.share(shareOptions)
}

function loadLogoImage(src): Promise<any> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const convertedSrc = CapacitorCore.convertFileSrc(src);
    image.src = convertedSrc;
    image.onload = () => {
      console.log('Image loaded successfully');
      resolve(image);
    };
    image.onerror = (error) => {
      console.error('Failed to load image:', JSON.stringify(error));
      reject(new Error('Failed to load logo image from ' + convertedSrc));
    };
  });
}

async function createOverlayImage(blob, advertId) {
  const canvas = await initializeCanvasWithImage(blob);
  const ctx = canvas?.getContext('2d');
  if (!canvas || !ctx) {
    return convertBlobToBase64(blob);
  }
  const overlayText = `ID: ${advertId}`;
  drawOverlayText(canvas, ctx, overlayText);

  // Load the SVG logo
  const logo = await loadLogoImage('logo/campertrader_logo_final_ocean_600.png');

  // Calculate the logo dimensions
  const logoHeight = Math.min(canvas.height * 0.15, canvas.width * 0.15); // 15% of the smaller dimension of the image
  const logoWidth = (logo.width / logo.height) * logoHeight;

  // Calculate the logo position
  const padding = 20; // Adjust as needed
  const logoX = padding;
  const logoY = padding;

  // Draw the logo on the canvas
  ctx.drawImage(logo, logoX, logoY, logoWidth, logoHeight);

  return canvas.toDataURL('image/jpeg');
}
function initializeCanvasWithImage(imageBlob): Promise<any> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      reject('ctx not set');
      return
    }

    image.src = URL.createObjectURL(imageBlob);
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      resolve(canvas);
    };
  });
}

function drawOverlayText(canvas, ctx, text) {
  const lines = text.split('\n').length;
  const fontSize = Math.floor(canvas.height * 0.05);
  const lineHeight = fontSize * 1.2;
  const padding = 20; // Adjust as needed
  const textX = canvas.width - padding; // Adjusted to position text on the right
  const initialTextY = canvas.height - (lines * lineHeight);

  ctx.font = `bold ${fontSize}px Arial`;
  ctx.fillStyle = 'white';
  ctx.strokeStyle = 'black';
  ctx.lineWidth = Math.floor(fontSize * 0.1);
  ctx.textAlign = 'right'; // Adjusted to align text to the right

  text.split('\n').forEach((line, index) => {
    const textY = initialTextY + index * lineHeight;
    ctx.fillStyle = 'white';
    ctx.strokeText(line, textX, textY);
    ctx.fillText(line, textX, textY);
  });
}


function convertBlobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = () => reject('Failed to convert to base64');
  });
}

export async function shareAdvert($q, advert, asImage = false) {
  const formattedPrice = Filter.currency({
    value: advert.price,
    maximumFractionDigits: 0,
  })
  const text = `${advert?.seo_title} • ${formattedPrice}`;
  if ($q.platform.is.nativeMobile) {
    const {value} = await CapacitorShare.canShare()

    if (value) {
      try {
        console.debug('fetchModifyAndShareAdvertImage')
        if ($q.platform.is.ios) {
          // iOS does not support sharing images directly, so we share the image as a link
          asImage = false
        }
        await fetchModifyAndShareAdvertImage(advert, text, asImage)
      } catch (e: any) {
        console.warn(e)
      }
      return
    }
  }

  // if not native or sharing image failed, show the share modal
  $q.dialog({
    component: ShareModal,

    // props forwarded to your custom component
    componentProps: {
      url: advert?.seo_link,
      text: text,
      mtmSource: 'AdvertDetail'
    },
  });
}


export async function shareItem($q, item) {
  if ($q.platform.is.nativeMobile) {
    const shareOptions = {
      title: 'Gefunden auf CamperTrader.de',
      text: truncate(item.description_clean, 200),
      url: item.seo_link,
      dialogTitle: 'Inserat teilen',
    }
    await CapacitorShare.share(shareOptions)
    return
  }


  // if not native or sharing image failed, show the share modal
  $q.dialog({
    component: ShareModal,

    // props forwarded to your custom component
    componentProps: {
      url: item?.seo_link,
      text: truncate(item.description_clean, 200),
      mtmSource: 'ItemDetail'
    },
  });
}

export function clickSaleSign($q, advert) {
  const url = new URL(`adverts/${advert.uuid}/sale-sign/`, process.env.API_BASE)

  if ($q.platform.is.nativeMobile) {
    CapacitorBrowser.open({url: url.href})
  } else {
    const link = document.createElement('a');
    link.href = url.href;
    link.target = '_blank';
    link.download = 'verkaufsschild.pdf'; // Specify the file name
    link.click();
  }
}
