<template>
  <div class="row q-my-sm">
    <div class="col-6 q-mb-md">
      <DynamicInput
        v-model.lazy="formData.vehicle.equipment.color"
        :errors="errors.vehicle?.equipment?.color"
        :field="fields.vehicle.children.equipment.children.color"
        emit-value
        data-cy="color"
        map-options
        outlined
      />
    </div>
    <div class="col-6"></div>
    <div class="col-6">
      <DynamicInput
        v-model="formData.vehicle.all_wheel_drive"
        :errors="errors.vehicle?.all_wheel_drive"
        :field="fields.vehicle.children.all_wheel_drive"
        :no-indeterminate="true"
        data-cy="all_wheel_drive"
      />
    </div>


    <div class="col-6">
      <DynamicInput
        v-model="formData.vehicle.particle_filter"
        :error="!!errors.vehicle?.particle_filter"
        :field="fields.vehicle.children.particle_filter"
        :no-indeterminate="true"
      />
    </div>


    <div class="col-6">
      <DynamicInput
        v-model.lazy="formData.vehicle.equipment.metallic"
        :errors="errors.vehicle?.equipment?.metallic"
        :field="fields.vehicle.children.equipment.children.metallic"
        :no-indeterminate="true"
      />
    </div>

    <div class="col-6">
      <DynamicInput
        v-model="formData.vehicle.equipment.abs"
        :errors="errors.vehicle?.equipment?.abs"
        :field="fields.vehicle.children.equipment.children.abs"
        :no-indeterminate="true"
      />
    </div>

    <div class="col-6">
      <DynamicInput
        v-model.lazy="formData.vehicle.equipment.esp"
        :errors="errors.vehicle?.equipment?.esp"
        :field="fields.vehicle.children.equipment.children.esp"
        :no-indeterminate="true"
      />
    </div>

    <div class="col-6">
      <DynamicInput
        v-model.lazy="formData.vehicle.equipment.ac"
        :error="errors.vehicle?.equipment?.ac"
        :field="fields.vehicle.children.equipment.children.ac"
        :no-indeterminate="true"
      />
    </div>

    <div class="col-6">
      <DynamicInput
        v-model.lazy="formData.vehicle.equipment.cruise_control"
        :errors="errors.vehicle?.equipment?.cruise_control"
        :field="fields.vehicle.children.equipment.children.cruise_control"
        :no-indeterminate="true"
      />
    </div>

    <div class="col-6">
      <DynamicInput
        v-model.lazy="formData.vehicle.equipment.trailer_hitch"
        :errors="errors.vehicle?.equipment?.trailer_hitch"
        :field="fields.vehicle.children.equipment.children.trailer_hitch"
        :no-indeterminate="true"
      />
    </div>

    <div class="col-6">
      <DynamicInput
        v-model.lazy="formData.vehicle.equipment.parking_assistance"
        :errors="errors.vehicle?.equipment?.parking_assistance"
        :field="fields.vehicle.children.equipment.children.parking_assistance"
        :no-indeterminate="true"
      />
    </div>

    <div class="col-6">
      <DynamicInput
        v-model.lazy="formData.vehicle.equipment.rear_view_camera"
        :errors="errors.vehicle?.equipment?.rear_view_camera"
        :field="fields.vehicle.children.equipment.children.rear_view_camera"
        :no-indeterminate="true"
      />
    </div>

    <div class="col-6">
      <DynamicInput
        v-model.lazy="formData.vehicle.equipment.stationary_heating"
        :errors="!!errors.vehicle?.equipment?.stationary_heating"
        :field="fields.vehicle.children.equipment.children.stationary_heating"
        :no-indeterminate="true"
      />
    </div>

    <div class="col-6">
      <DynamicInput
        v-model.lazy="formData.vehicle.equipment.seat_heating"
        :errors="errors.vehicle?.equipment?.seat_heating"
        :field="fields.vehicle.children.equipment.children.seat_heating"
        :no-indeterminate="true"
      />
    </div>


  </div>
</template>
<script setup>
import {computed, watch} from 'vue';
import DynamicInput from 'components/qutils/form/DynamicInput.vue';

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: {
    required: true,
  },
  errors: {
    required: false
  },
  fields: {
    default: {}
  }
})

const formData = computed({
  // getter
  get() {
    return props.modelValue
  },
  // setter
  set(newValue) {
    emit('update:modelValue', newValue)
  }
})

watch(
  () => formData,
  (val) => {
    emit('update:modelValue', val)
  },
  {deep: true}
)

</script>
