import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-start q-gutter-md no-wrap" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "banner-content" }
const _hoisted_4 = {
  key: 0,
  class: "text-bold"
}
const _hoisted_5 = ["innerHTML"]


export default /*@__PURE__*/_defineComponent({
  __name: 'BannerNotification',
  props: {
  faIcon: {type: String,},
  level: {type: String},
  title: {type: String},
  message: {type: String},
  isDismissible: {type: Boolean},
},
  emits: ['dismiss'],
  setup(__props) {






return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_banner = _resolveComponent("q-banner")!

  return (_openBlock(), _createBlock(_component_q_banner, _mergeProps({
    rounded: "",
    "inline-actions": "",
    class: `bg-${__props.level} text-white`
  }, _ctx.$attrs), _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (__props.faIcon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_q_icon, {
                name: __props.faIcon,
                class: "q-mt-sm",
                color: "white",
                size: "1.6rem"
              }, null, 8, ["name"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "title", {}, () => [
            (__props.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(__props.title), 1))
              : _createCommentVNode("", true)
          ]),
          _renderSlot(_ctx.$slots, "default", {}, () => [
            (__props.message)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "banner-text",
                  innerHTML: __props.message
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 2
  }, [
    (__props.isDismissible)
      ? {
          name: "action",
          fn: _withCtx(() => [
            _createVNode(_component_q_btn, {
              flat: "",
              color: "white",
              round: "",
              icon: "fas fa-close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dismiss')))
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1040, ["class"]))
}
}

})