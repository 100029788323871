import {ObjectDirective} from 'vue';

interface OnLoadDirectiveBinding {
  value?: (el: HTMLElement) => void;
}

const OnEleLoad: ObjectDirective<HTMLElement, OnLoadDirectiveBinding | any> = {
  mounted(el, binding) {
    if (typeof binding.value === 'function') {
      const callbackFunction = binding.value as (ele: HTMLElement) => void;
      callbackFunction(el)
    }
  }
};

export default OnEleLoad;
