import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-py-lg container" }
const _hoisted_2 = {
  key: 0,
  class: "absolute-center"
}
const _hoisted_3 = {
  key: 1,
  class: "row",
  style: {"row-gap":"32px"}
}
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "q-pa-md bg-grey-3 rounded-borders" }
const _hoisted_8 = {
  key: 0,
  class: "q-mb-md"
}
const _hoisted_9 = { class: "col-12" }

import {useMainStore} from 'stores/main';
import {computed, onMounted, ref, toRef} from 'vue';
import BusinessServiceSelectDialog from 'src/apps/business/components/BusinessServiceSelectDialog.vue';
import {Dialog} from 'quasar';
import {useSearchStore} from 'src/apps/search/store';
import {findElementById, toggleList} from 'src/apps/core/utils';
import SearchTextInput from 'src/apps/search/components/SearchTextInput.vue';
import Search from 'src/api/search';
import {AutocompleteResponse, SearchAdapter} from 'src/apps/search/helpers';
import SearchLocationInput from 'src/apps/search/components/SearchLocationInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DealerSearchFilter',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {


const props = __props
const emit = __emit
const modelValue = toRef(props, 'modelValue', {})

const mainStore = useMainStore()
const searchStore = useSearchStore()
const loading = ref(false)

const orderingOptions = [
  {value: '', label: 'Relevanz'},
  {value: 'effective_name', label: 'Name (A-Z)'},
  {value: '-effective_name', label: 'Name (Z-A)'},
]

onMounted(() => {
  fetchBusinessOptions()
})

function fetchBusinessOptions() {
  loading.value = true
  mainStore.fetchBusinessServicesAvailable().finally(() => {
    loading.value = false
  })
}

const filterData = computed({
  get() {
    return modelValue.value
  },
  set(val) {
    emit('update:modelValue', val)
  }
})


function openSelectDialog() {
  Dialog.create({
    component: BusinessServiceSelectDialog,
    componentProps: {
      selected: services.value,
      showAvailable: true,
    },
    transitionShow: 'slide-up',
    transitionHide: 'slide-down',
  }).onOk((data: any) => {
    searchStore.filterData.services = data.flatMap(obj => obj.id)
  })
}

const services = computed(() => {
  const serviceIds = searchStore.filterData.services ?? []
  const services: any = []
  serviceIds.forEach(id => {
    const ele = findElementById(mainStore.businessServices, id)
    if (ele) {
      services.push(ele)
    }
  })
  return services
})

function removeService(service: any) {
  toggleList(service.id, searchStore.filterData.services)
}

class DealerSearchAdapter extends SearchAdapter {
  fetchAutocomplete(query): Promise<Array<AutocompleteResponse>> {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await Search.autocompleteSuggestion(query, 'dealer')
        const options = resp.data?.options?.map((o: any) => {
          return {
            text: o.text,
            input: o.source.input,
            source: o.source.source,
          } as AutocompleteResponse
        })

        resolve(options)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

  fetchHistory(): Promise<Array<any>> {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await Search.fetchUserSearchHistory('dealer')
        resolve(resp.data)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }
}



return (_ctx: any,_cache: any) => {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (loading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_q_spinner, { size: "lg" })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "c-header text-h4" }, "Wo möchtest du suchen?", -1)),
            _createVNode(SearchLocationInput, {
              "model-value": _unref(searchStore).searchLocation,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = val => _unref(searchStore).setSearchLocation(val))
            }, null, 8, ["model-value"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "c-header text-h4" }, "Was suchst du?", -1)),
            _createVNode(SearchTextInput, {
              modelValue: filterData.value.s,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filterData.value.s) = $event)),
              "search-adapter": new DealerSearchAdapter()
            }, null, 8, ["modelValue", "search-adapter"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (services.value?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(services.value, (service) => {
                      return (_openBlock(), _createBlock(_component_q_chip, {
                        key: service.id,
                        removable: "",
                        onRemove: ($event: any) => (removeService(service))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(service.ancestor_path.flatMap(obj => obj.name).join(' > ')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onRemove"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_q_btn, {
                unelevated: "",
                "no-caps": "",
                rounded: "",
                color: "dark",
                onClick: openSelectDialog
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Leistungen auswählen")
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "c-header text-h4" }, "Sortierung", -1)),
            _createVNode(_component_q_select, {
              "model-value": filterData.value.ordering || '',
              options: orderingOptions,
              "emit-value": "",
              behavior: "menu",
              "map-options": "",
              outlined: "",
              "stack-label": false,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = val => filterData.value.ordering = val === '' ? undefined : val)
            }, null, 8, ["model-value"])
          ])
        ]))
  ]))
}
}

})