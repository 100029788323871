import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, TransitionGroup as _TransitionGroup, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "q-mb-lg" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "bg-white rounded-borders" }
const _hoisted_5 = { class: "text-grey-7 absolute-center text-center full-width" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "q-pa-sm full-width" }
const _hoisted_8 = { class: "flex" }
const _hoisted_9 = { class: "ellipsis text-bold" }
const _hoisted_10 = {
  key: 1,
  class: "text-small"
}
const _hoisted_11 = {
  key: 2,
  class: "text-small"
}
const _hoisted_12 = {
  key: 3,
  class: "text-small"
}
const _hoisted_13 = { class: "q-mt-lg" }
const _hoisted_14 = { class: "relative-position" }
const _hoisted_15 = { class: "full-width c-img-thumbnail-wrapper relative-position" }
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  key: 0,
  class: "absolute-bottom flex text-white",
  style: {"background-color":"rgba(0,0,0,.35)"}
}
const _hoisted_18 = { class: "full-width flex justify-between" }
const _hoisted_19 = {
  key: 0,
  class: "absolute-full c-image-btns",
  style: {"background":"rgba(0,0,0,0.45)"}
}
const _hoisted_20 = { class: "flex items-center justify-center full-height" }
const _hoisted_21 = { style: {"font-size":"5rem","color":"rgba(255,255,255, 0.8)"} }

import {onMounted, ref, toRef} from 'vue'
import {AvertSrcSetImage} from 'src/models/advert'
import {humanFileSize, toBase64} from 'src/etc/utils'
import Adverts from 'src/api/adverts'
import AdvertsApi from 'src/api/adverts'
import Draggable from 'vuedraggable'
import {useMainStore} from 'stores/main'
import ImageEditor from 'src/components/imageEditor/ImageEditor.vue'
import {useQuasar} from 'quasar'
import {CapacitorFilesystem} from 'boot/inject-capacitor';

interface fileListItem {
  file?: File | undefined
  uploading?: boolean
  errors: Array<string>
  progress?: number
  success?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AdvertImageUpload',
  props: {
  modelValue: {
    type: Array<AvertSrcSetImage>
  },
  advertUuid: {
    type: String,
    required: true,
  }
},
  setup(__props) {

const props = __props

const $q = useQuasar()
const mainStore = useMainStore()
const humanFileSizeFn = humanFileSize

const advertImages = ref<Array<AvertSrcSetImage>>([])
const uploadingFiles = ref<Array<fileListItem>>([])
const drag = ref(false)
const dropAreaDragOver = ref(false)
const tmpList = ref<Array<AvertSrcSetImage>>([])
const images = toRef(props, 'modelValue').value
const fileInput = ref<HTMLInputElement | null>(null)
const validationErrors = ref([])
const disableDragging = ref(false)

const MAX_IMAGES = mainStore.siteConfig['static_settings'].advert_max_images
const MAX_IMAGE_SIZE_IN_MB = mainStore.siteConfig['static_settings'].advert_max_imagesize_in_mb

if (images) {
  advertImages.value = toRef(props, 'modelValue').value || []
  tmpList.value = [...advertImages.value]
}
const requestPermissions = async () => {
  if ($q.platform.is.nativeMobile) {
    try {
      await CapacitorFilesystem.requestPermissions()
      console.log('Filesystem permissions granted')
    } catch (error) {
      console.error('Filesystem permission error:', error)
    }
  }
}

onMounted(() => {
  requestPermissions()
})

const clickInput = async function () {
  if (!fileInput.value) {
    return
  }
  validationErrors.value = []
  fileInput.value.click()
}

const uploadFile = async function (fileItem: fileListItem) {
  if (!fileItem?.file) {
    console.error('No fileItem found', fileItem)
    return
  }

  fileItem.progress = 0
  console.log('fileItem', fileItem.file)
  let base64Image
  try {
    base64Image = await toBase64(fileItem.file);
    console.log('base64Image', base64Image)
  } catch (err) {
    console.error('Error converting image to base64', err.toString())
    fileItem.errors = ['Fehler beim Konvertieren des Bildes.']
    fileItem.uploading = false
    return
  }

  try {
    const resp = await Adverts.uploadAdvertImages(props.advertUuid, {
        images: [{image: base64Image}],
      },
      {
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            fileItem.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          }
        }
      })
    fileItem.success = true
    if (resp.data?.length) {
      resp.data.forEach(obj => {
        advertImages.value.push(obj)
      })
    }
  } catch (err: any) {
    console.error(err)
    fileItem.errors = ['Fehler beim Hochladen.']
    if (err.response?.data?.non_field_errors) {
      fileItem.errors = [...err.response?.data?.non_field_errors]
    }
  }

  fileItem.uploading = false
}

const processFiles = async function () {
  for (let index = 0; index < uploadingFiles.value.length; index++) {
    const item: fileListItem = uploadingFiles.value[index];
    if (item.uploading === true) {
      await uploadFile(item)
    }
  }
}

const addFile = async function (file: File, allowDuplicates = true) {
  if (!allowDuplicates && uploadingFiles.value?.find(obj => obj.file?.name === file.name && obj.file?.size === file.size)) {
    return
  }

  const fileSizeInMB = file.size / (1024 * 1024)
  if (fileSizeInMB > MAX_IMAGE_SIZE_IN_MB) {
    uploadingFiles.value.push({
      file,
      uploading: false,
      errors: [`Größe für Datei überschritten (max. ${MAX_IMAGE_SIZE_IN_MB} MB)`]
    })
    return
  }

  uploadingFiles.value.push({
    file,
    uploading: true,
    errors: []
  })
}
const changeFile = async function () {
  if (!fileInput.value || !fileInput.value?.files) {
    return
  }

  for (let index = 0; index < fileInput.value.files.length; index++) {
    const file: File = fileInput.value.files[index];
    const buffer = await file.arrayBuffer();
    const clone = new File([buffer], file.name, {type: file.type});
    await addFile(clone)
  }

  await processFiles()
}

const getLocalFileUrl = function (file) {
  return URL.createObjectURL(file)
}

const onChange = function (e) {
  disableDragging.value = true
  Adverts.setAdvertImagePostion(props.advertUuid, e.moved.element.uuid, e.moved.newIndex).then(() => {
    tmpList.value = advertImages.value
  }).catch(err => {
    advertImages.value = [...tmpList.value]
    console.error(err)
  }).finally(() => {
    disableDragging.value = false
  })
}

const drop = async function (e) {
  for (let index = 0; index < e.dataTransfer.files.length; index++) {
    await addFile(e.dataTransfer.files[index])
  }

  await processFiles()
}

const deleteImage = function (imageUuid) {
  disableDragging.value = true
  AdvertsApi.deleteAdvertImage(props.advertUuid, imageUuid).then(() => {
    advertImages.value = advertImages.value?.filter(
      (obj) => obj['uuid'] !== imageUuid
    );
  }).finally(() => {
    disableDragging.value = false
  })
};

const removeFromUploadQueue = function (pos) {
  uploadingFiles.value.splice(pos, 1)
}

const editImage = function (uuid) {
  $q.dialog({
    component: ImageEditor,

    // props forwarded to your custom component
    componentProps: {
      advertUuid: props.advertUuid,
      imageUuid: uuid,
      // ...more..props...
    }
  }).onOk((data) => {
    const idx = advertImages.value.findIndex(obj => obj.uuid === data.uuid);
    Adverts.fetchAdvertImage(props.advertUuid, data.uuid).then(resp => {
      advertImages.value[idx] = resp.data
    })
  })
}

return (_ctx: any,_cache: any) => {
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_linear_progress = _resolveComponent("q-linear-progress")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_banner, {
        class: "bg-grey-3",
        rounded: ""
      }, {
        default: _withCtx(() => [
          (_unref(MAX_IMAGES))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _cache[10] || (_cache[10] = _createTextVNode(" Pro Inserat sind ")),
                _createElementVNode("b", null, "max. " + _toDisplayString(_unref(MAX_IMAGES)) + " Bilder", 1),
                _createTextVNode(" erlaubt. (" + _toDisplayString(advertImages.value.length || 0) + "/" + _toDisplayString(_unref(MAX_IMAGES)) + ") ", 1)
              ]))
            : _createCommentVNode("", true),
          (_unref(MAX_IMAGE_SIZE_IN_MB))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _cache[11] || (_cache[11] = _createTextVNode(" Ein Bild darf ")),
                _createElementVNode("b", null, "max. " + _toDisplayString(_unref(MAX_IMAGE_SIZE_IN_MB)) + " MB", 1),
                _cache[12] || (_cache[12] = _createTextVNode(" groß sein. "))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      (advertImages.value?.length < _unref(MAX_IMAGES))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["c-image-drop-area rounded-borders relative-position q-pa-md cursor-pointer", {'drag-over': dropAreaDragOver.value}]),
            onClick: clickInput,
            onDrop: _cache[0] || (_cache[0] = _withModifiers((evt) => { drop(evt); dropAreaDragOver.value = false}, ["prevent"])),
            onDragenter: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
            onDragover: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (dropAreaDragOver.value = true), ["prevent"])),
            onDragleave: _cache[3] || (_cache[3] = ($event: any) => (dropAreaDragOver.value = false)),
            onDragend: _cache[4] || (_cache[4] = ($event: any) => (dropAreaDragOver.value = false))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_q_icon, {
                size: "2rem",
                name: "fas fa-upload"
              }),
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "text-small" }, "Klicken oder Dateien hineinziehen", -1)),
              _createElementVNode("input", {
                ref_key: "fileInput",
                ref: fileInput,
                multiple: true,
                class: "hidden",
                name: "files",
                type: "file",
                accept: "image/png, image/gif, image/jpeg",
                onChange: changeFile
              }, null, 544)
            ])
          ], 34))
        : _createCommentVNode("", true),
      (uploadingFiles.value?.length)
        ? (_openBlock(), _createBlock(_component_q_list, {
            key: 1,
            class: "q-py-md",
            separator: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_TransitionGroup, {
                appear: "",
                "leave-active-class": "animated fadeOut",
                "enter-active-class": "animated fadeOut"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(uploadingFiles.value, (image, index) => {
                    return (_openBlock(), _createBlock(_component_q_item, {
                      key: index,
                      dense: "",
                      onContextmenu: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, { avatar: "" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createElementVNode("img", {
                                class: "block",
                                src: getLocalFileUrl(image.file),
                                style: {"width":"60px","aspect-ratio":"1","object-fit":"cover"}
                              }, null, 8, _hoisted_6)
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("div", _hoisted_9, _toDisplayString(image.file?.name) + " (" + _toDisplayString(_unref(humanFileSizeFn)(image.file?.size, true)) + ") ", 1)
                              ]),
                              _createElementVNode("div", null, [
                                (image.uploading)
                                  ? (_openBlock(), _createBlock(_component_q_linear_progress, {
                                      key: 0,
                                      size: "lg",
                                      rounded: "",
                                      value: (image.progress ?? 0) / 100,
                                      color: "warning"
                                    }, null, 8, ["value"]))
                                  : _createCommentVNode("", true),
                                (image.uploading)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, "Bild wird hochgeladen (" + _toDisplayString(_unref(humanFileSizeFn)(image.file?.size, true)) + ")", 1))
                                  : (image.success)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                        _createVNode(_component_q_icon, {
                                          name: "fas fa-check",
                                          color: "positive"
                                        }),
                                        _cache[14] || (_cache[14] = _createTextVNode("  Datei hochgeladen "))
                                      ]))
                                    : (image.errors?.length)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                          _createVNode(_component_q_icon, {
                                            name: "fas fa-close",
                                            color: "negative"
                                          }),
                                          _createTextVNode("  " + _toDisplayString(image.errors.join('. ')), 1)
                                        ]))
                                      : _createCommentVNode("", true)
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        (!image.uploading)
                          ? (_openBlock(), _createBlock(_component_q_item_section, {
                              key: 0,
                              side: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_btn, {
                                  icon: "fas fa-close",
                                  round: "",
                                  dense: "",
                                  flat: "",
                                  onClick: ($event: any) => (removeFromUploadQueue(index))
                                }, null, 8, ["onClick"])
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    (advertImages.value?.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _cache[16] || (_cache[16] = _createStaticVNode("<div class=\"q-mb-md q-mt-xl text-center\" data-v-78d66566><div data-v-78d66566><div class=\"fa-stack fa-2x\" data-v-78d66566><i class=\"fa-solid fa-up-down-left-right fa-stack-2x text-grey-6\" data-v-78d66566></i><i class=\"fa-solid fa-arrow-pointer fa-stack-1x text-grey-8\" data-v-78d66566></i></div></div><div class=\"text-muted\" data-v-78d66566> Bilder verschieben um Reihenfolge zu ändern </div></div>", 1)),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_unref(Draggable), {
              modelValue: advertImages.value,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((advertImages).value = $event)),
              "item-key": "uuid",
              class: _normalizeClass(["c-draggable disable-long-press", {'c-disabled': disableDragging.value}]),
              style: {"display":"grid","grid-template-columns":"repeat(auto-fill, minmax(240px, 1fr))","gap":"8px"},
              "swap-threshold": 1,
              animation: 150,
              easing: "cubic-bezier(0.83, 0, 0.17, 1)",
              disabled: disableDragging.value,
              "force-fallback": true,
              delay: 200,
              "delay-on-touch-only": true,
              onContextmenu: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["prevent"])),
              onStart: _cache[8] || (_cache[8] = ($event: any) => (drag.value=true)),
              onEnd: _cache[9] || (_cache[9] = ($event: any) => (drag.value=false)),
              onChange: onChange
            }, {
              item: _withCtx(({element, index}) => [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_q_card, {
                    class: "overflow-hidden",
                    flat: ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("img", {
                          class: "c-img-thumbnail",
                          src: element.image.webp
                        }, null, 8, _hoisted_16),
                        (!drag.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                              _createElementVNode("div", _hoisted_18, [
                                _createVNode(_component_q_btn, {
                                  flat: "",
                                  onClick: _withModifiers(($event: any) => (editImage(element.uuid)), ["stop"])
                                }, {
                                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                                    _createTextVNode("Bild Bearbeiten")
                                  ])),
                                  _: 2
                                }, 1032, ["onClick"]),
                                _createVNode(_component_q_btn, {
                                  flat: "",
                                  round: "",
                                  onClick: _withModifiers(($event: any) => (deleteImage(element.uuid)), ["stop"])
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                      name: "fas fa-trash",
                                      size: "xs"
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      (drag.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("div", null, [
                                _createElementVNode("span", _hoisted_21, _toDisplayString(index + 1), 1)
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ])
              ]),
              _: 1
            }, 8, ["modelValue", "class", "disabled"])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})