import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

import {PropType} from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomButtonOptionGroup',
  props: {
  modelValue: {
    required: true,
  },
  options: {
    type: Object as PropType<Array<{ value?: any; label: string }>>,
    required: true,
  },
  btnProps: {
    type: Object,
    default: () => ({}),
  },
  btnPropsActive: {
    type: Object,
    default: () => ({}),
  },
  small: {
    type: Boolean,
    default: false,
  },
  bordered: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update:modelValue'],
  setup(__props) {

const props = __props



function btnPropsFn(item) {
  let _props = {
    ...props.btnProps
  }
  if (props.modelValue === item.value) {
    _props = {
      ..._props,
      ...props.btnPropsActive,
    }
  }
  return _props
}

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_btn_group = _resolveComponent("q-btn-group")!

  return (_openBlock(), _createBlock(_component_q_btn_group, {
    class: _normalizeClass({'bordered': __props.bordered}),
    flat: __props.bordered
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (item, index) => {
        return (_openBlock(), _createBlock(_component_q_btn, _mergeProps({ ref_for: true }, btnPropsFn(item), {
          key: item.value,
          label: item.label,
          style: {height: !__props.small ? '3rem' : undefined},
          onClick: ($event: any) => (_ctx.$emit('update:modelValue', item.value))
        }), {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, `item${index}`, {
              item: item,
              index: index
            })
          ]),
          _: 2
        }, 1040, ["label", "style", "onClick"]))
      }), 128))
    ]),
    _: 3
  }, 8, ["class", "flat"]))
}
}

})