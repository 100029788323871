import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, withModifiers as _withModifiers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {onMounted, ref} from 'vue'
import {useQuasar} from 'quasar';
import {CapacitorBrowser} from 'boot/inject-capacitor';


export default /*@__PURE__*/_defineComponent({
  __name: 'SecureEmail',
  props: {
  email: {
    type: String,
      required: true
  },
  subject: {
    type: String,
      required: false,
  }
},
  setup(__props) {

const emailRef = ref()
const loading = ref(true)
const $q = useQuasar()


const props = __props

onMounted(() => {
  const rest = props.email.split('.')[0]
  const tld = props.email.split('.')[1]
  const user = rest.split('@')[0]
  const domain = rest.split('@')[1]
  emailRef.value.dataset.hi = user
  emailRef.value.dataset.de = domain
  emailRef.value.dataset.this = tld
  loading.value = false
})

const click = function () {
  const url = `mailto:${emailRef.value.dataset.hi}@${emailRef.value.dataset.de}.${emailRef.value.dataset.this}?subject=${props.subject || ''}`
  if ($q.platform.is.nativeMobile) {
    CapacitorBrowser.open({url})
  } else {
    window.open(url, '_blank')
  }
}


return (_ctx: any,_cache: any) => {
  const _component_q_spinner = _resolveComponent("q-spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("a", _mergeProps({
      ref_key: "emailRef",
      ref: emailRef,
      class: "cryptedmail",
      href: "#"
    }, _ctx.$attrs, {
      onClick: _withModifiers(click, ["prevent"])
    }), null, 16), [
      [_vShow, !loading.value]
    ]),
    (loading.value)
      ? (_openBlock(), _createBlock(_component_q_spinner, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})