import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex no-wrap scroll-x q-pb-sm" }


export default /*@__PURE__*/_defineComponent({
  __name: 'SmallNumberSelect',
  props: {
  modelValue: {
    type: Number,
    required: false
  },
  defaultAttr: {
    type: Object,
    default: () => {
      return {
        outline: false,
      }
    }
  },
  activeAttr: {
    type: Object,
    default: () => {
      return {
        color: 'primary',
        textColor: 'white',
      }
    }
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {



const emit = __emit


return (_ctx: any,_cache: any) => {
  const _component_q_chip = _resolveComponent("q-chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_q_chip, _mergeProps(!__props.modelValue ? __props.activeAttr: __props.defaultAttr, {
        class: "q-pa-lg rounded-borders",
        clickable: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('update:modelValue', undefined)))
      }), {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Alle ")
        ])),
        _: 1
      }, 16)
    ]),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
      return _createElementVNode("div", { key: i }, [
        _createVNode(_component_q_chip, _mergeProps({ ref_for: true }, __props.modelValue === i ? __props.activeAttr : __props.defaultAttr, {
          class: "q-pa-lg rounded-borders",
          clickable: "",
          onClick: ($event: any) => (emit('update:modelValue', i))
        }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(i), 1)
          ]),
          _: 2
        }, 1040, ["onClick"])
      ])
    }), 64)),
    _createElementVNode("div", null, [
      _createVNode(_component_q_chip, _mergeProps((__props.modelValue ?? 0) >= 6 ? __props.activeAttr : __props.defaultAttr, {
        class: "q-pa-lg rounded-borders",
        clickable: "",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('update:modelValue', 6)))
      }), {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("6+ ")
        ])),
        _: 1
      }, 16)
    ])
  ]))
}
}

})