import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "relative-position" }
const _hoisted_2 = { class: "flex q-gutter-y-lg column no-wrap" }
const _hoisted_3 = { class: "text-h3 font-fjalla-one" }
const _hoisted_4 = { class: "q-mt-md" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "text-h1 font-inter" }
const _hoisted_7 = {
  key: 0,
  class: "q-mt-xs text-muted"
}
const _hoisted_8 = {
  key: 0,
  class: "text-strike q-mr-xs"
}
const _hoisted_9 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_10 = { class: "flex column q-gutter-y-sm" }
const _hoisted_11 = {
  key: 0,
  class: "bestseller-tag"
}
const _hoisted_12 = { class: "flex justify-center items-center" }

import {computed, PropType} from 'vue';

interface Prices {
  month?: number,
  year?: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PricingBox',
  props: {
  title: {type: String},
  description: {type: String},
  color: {
    type: String,
    default: 'grey-3'
  },
  textColor: {
    type: String,
  },
  prices: {
    type: Object as PropType<Prices>,
    required: true,
  },
  bulletList: {
    type: Array<string>,
    default: () => []
  },
  chipLabel: {
    type: String,
    default: undefined
  },
  chipColor: {
    type: String,
    default: undefined,
  },
  isBestseller: {
    default: false
  },
  btnLabel: {
    type: String,
    default: 'Abonnieren'
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  interval: {
    type: String,
  },
  trialDaysMonth: {
    type: Number,
    required: false,
  },
  btnLoading: {
    type: Boolean,
    default: false,
  },
  hideButtons: {
    type: Boolean,
    default: false,
  }
},
  emits: ['btn-click'],
  setup(__props) {



const props = __props

const priceBoxClass = computed(() => {
  return [
    `bg-${props.color}`,
    `text-${props.textColor}`,
  ].join(' ')
})

const pricePerMonthCmp = computed(() => {
  if (!props.prices || !Object.keys(props.prices as object).length) return
  if (!props.interval) return
  if (props?.interval === 'year' && props.prices?.year) {
    return props.prices.year / 12
  }
  return props.prices[props.interval]
})

return (_ctx: any,_cache: any) => {
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, {
      flat: "",
      bordered: "",
      style: {"border-radius":"1rem"},
      class: "overflow-hidden"
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createVNode(_component_q_card_section, {
            class: _normalizeClass(["q-py-lg", priceBoxClass.value])
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", null, [
                  _createVNode(_component_q_chip, {
                    style: _normalizeStyle({visibility: !__props.chipLabel > 0 ? 'hidden' : undefined}),
                    dense: "",
                    size: "sm",
                    color: __props.chipColor,
                    class: "q-px-xs q-ma-none"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(__props.chipLabel), 1)
                    ]),
                    _: 1
                  }, 8, ["style", "color"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(__props.title), 1),
                  _createElementVNode("div", {
                    style: _normalizeStyle({visibility: __props.trialDaysMonth ? undefined : 'hidden'})
                  }, [
                    _createVNode(_component_q_badge, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(__props.trialDaysMonth) + " Tage kostenlos testen", 1)
                      ]),
                      _: 1
                    })
                  ], 4),
                  _createElementVNode("div", _hoisted_4, _toDisplayString(__props.description || ' '), 1)
                ]),
                (__props.prices && __props.prices[__props.interval] !== undefined)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$filters.currency({value: pricePerMonthCmp.value})), 1),
                      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "q-mt-sm" }, "/ Monat", -1)),
                      (__props.interval === 'year')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            (__props.prices.month * 12 > __props.prices.year)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$filters.currency({value: __props.prices['month'] * 12})), 1))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", {
                              class: _normalizeClass({'text-primary': __props.prices.month * 12 > __props.prices.year})
                            }, _toDisplayString(_ctx.$filters.currency({value: __props.prices['year']})) + " /Jahr ", 3)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (!__props.hideButtons)
                  ? _renderSlot(_ctx.$slots, "action-button", { key: 1 }, () => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_q_btn, {
                          loading: __props.btnLoading,
                          class: "full-width",
                          outline: "",
                          disable: __props.isActive,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('btn-click')))
                        }, {
                          default: _withCtx(() => [
                            (!__props.isActive)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode(_toDisplayString(__props.btnLabel), 1)
                                ], 64))
                              : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                  _createVNode(_component_q_icon, { name: "done" }),
                                  _cache[2] || (_cache[2] = _createTextVNode("  Aktiv "))
                                ]))
                          ]),
                          _: 1
                        }, 8, ["loading", "disable"])
                      ])
                    ])
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 3
          }, 8, ["class"])
        ]),
        _renderSlot(_ctx.$slots, "bullet-points", { bulletList: __props.bulletList }, () => [
          (__props.bulletList?.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_q_separator),
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_10, [
                      _cache[3] || (_cache[3] = _createElementVNode("div", null, [
                        _createElementVNode("b", null, "Deine Vorteile:")
                      ], -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.bulletList, (item, i) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: i,
                          class: "flex q-gutter-x-md no-wrap"
                        }, [
                          _createElementVNode("div", null, [
                            _createVNode(_component_q_icon, { name: "done" })
                          ]),
                          _createElementVNode("div", null, _toDisplayString(item), 1)
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 3
    }),
    (__props.isBestseller)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_q_chip, {
              square: "",
              clickable: false,
              ripple: false,
              color: "warning",
              "text-color": "white",
              class: "text-bold text-uppercase"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Bestseller ")
              ])),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})