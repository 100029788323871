export const stepFieldMapping = {
  1: [
    'price',
    'is_negotiable',
    'contact.phone',
    'is_display_phone',
    'internal_id',
    'vehicle.vin',
    'vehicle.brand',
    'vehicle.model',
    'vehicle.car_type',
    'vehicle.registration',
    'vehicle.mileage',
    'vehicle.general_inspection_until',
    'vehicle.year',
    'vehicle.engine_power_ps',
    'vehicle.engine_power_kw',
    'vehicle.engine_ccm',
    'vehicle.fuel_type',
    'vehicle.has_damage',
    'vehicle.is_serviced',
    'vehicle.is_ready_to_go',
    'vehicle.build_type',
    'vehicle.admission',
    'vehicle.is_oldtimer',
    'vehicle.roof_system',
    'vehicle.emission_class',
    'vehicle.environmental_badge',
    'vehicle.number_of_owners',
    'vehicle.transmission',
    'vehicle.drive_shaft_position',
    'vehicle.general_inspection_renew',
    'vehicle.beds',
    'vehicle.seats',
    'vehicle.size_length',
    'vehicle.size_height',
    'vehicle.length',
    'vehicle.height',
    'vehicle.width',
    'vehicle.maximum_weight_kg',
    'vehicle.vehicle.tare_weight_kg',
    'vehicle.axles'
  ],
  2: [
    'vehicle.equipment.colo',
    'vehicle.all_wheel_drive',
    'vehicle.particle_filter',
    'vehicle.equipment.metallic',
    'vehicle.equipment.abs',
    'vehicle.equipment.esp',
    'vehicle.equipment.ac',
    'vehicle.equipment.cruise_control',
    'vehicle.equipment.trailer_hitch',
    'vehicle.equipment.parking_assistance',
    'vehicle.equipment.rear_view_camera',
    'vehicle.equipment.stationary_heating',
    'vehicle.equipment.seat_heating',
  ],
  3: [
    'description',
    'youtube_link',
    'vehicle.extras',
    'vehicle.figures.clean_water_tank_in_l',
    'vehicle.figures.greywater_tank_in_l',
    'vehicle.figures.solar_in_w',
    'vehicle.figures.gas_vol_in_l',
    'vehicle.figures.inverter_in_w',
    'vehicle.figures.battery_capacity_in_ah',
    'vehicle.figures.boiler_vol_in_l',
  ],
  4: [
    'address.non_field_errors',
    'address.street_and_number',
    'address.addition',
    'address.zip_code',
    'address.city',
    'address.state',
    'address.country',
  ],

  5: [
    'images',
  ],
}

export function flattenObject(obj, parentKey = undefined, sep = '.') {
  const flattenedObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      let newKey
      if (parentKey) {
        newKey = parentKey + sep + key;
      } else {
        newKey = key
      }
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        Object.assign(flattenedObj, flattenObject(obj[key], newKey, sep));
      } else {
        flattenedObj[newKey] = obj[key];
      }
    }
  }
  return flattenedObj
}


export function stepHasError(stepNum: number, errors) {
  const _errors = flattenObject(errors)
  for (const err in _errors) {
    if (stepFieldMapping[stepNum].includes(err)) {
      return true
    }
  }
}
