import script from "./ItemCardImage.vue?vue&type=script&setup=true&lang=ts"
export * from "./ItemCardImage.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QImg});
