import script from "./PrivacyPage.vue?vue&type=script&lang=ts&setup=true"
export * from "./PrivacyPage.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QBtn});
