import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container flex justify-center column" }
const _hoisted_2 = { class: "q-pb-xl q-pt-md" }
const _hoisted_3 = {
  class: "flex text-muted text-xsmall justify-center footer__fix",
  style: {"column-gap":"8px"}
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

import {useMeta, useQuasar} from 'quasar';
import {getPageUrl} from 'src/etc/helper';
import LoginForm from 'src/apps/auth/components/LoginForm.vue';
import {useRouter} from 'vue-router';
import {onMounted, ref} from 'vue';
import {CapacitorApp, CapacitorLiveUpdate} from 'boot/inject-capacitor';
import packageJson from 'app/package.json';



import {Cookies} from 'quasar';

const __default__ = {
  preFetch({ssrContext}) {
    const cookies = process.env.SERVER
      ? Cookies.parseSSR(ssrContext)
      : Cookies // otherwise we're on client
    if (cookies.has('token')) {
      console.debug('Removed Cookie "token": login page')
      cookies.remove('token', {path: '/'})
    }
  }
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'LoginPage',
  setup(__props) {

const router = useRouter()

const $q = useQuasar()
const build = ref()
const bundle = ref()
const version = ref()
const platform = ref()


onMounted(() => {
  if ($q.platform.is.nativeMobile) {
    CapacitorApp.getInfo().then(info => {
      version.value = info?.version
      build.value = info?.build
      platform.value = `${$q.platform.is.ios ? 'iOS' : 'Android'}`
    })

    CapacitorLiveUpdate.getBundle().then(({bundleId}) => {
      bundle.value = bundleId
    })

  } else {
    version.value = packageJson.version
    platform.value = 'Web'
  }
})


useMeta(() => {
  return {
    // sets document title
    titleTemplate(title) {
      return `Login | ${title}`
    },

    link: {
      canonical: {
        rel: 'canonical', href: getPageUrl('login', router) || ''
      }
    },

    // meta tags
    meta: {
      description: {
        name: 'description',
        content: 'Logge dich bei Camper Trader ein, um ein Inserat zu erstellen, ' +
          'verwalten oder um andere Benutzer kontaktieren zu können.'
      },
    },

    noscript: {
      default: 'Please enable Javascript to use this website.'
    }
  }
})

return (_ctx: any,_cache: any) => {
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "justify-center flex" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(LoginForm)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, "Platform: " + _toDisplayString(platform.value ?? 'unknown'), 1),
          _createElementVNode("div", null, "Version: " + _toDisplayString(version.value ?? 'unknown'), 1),
          (build.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Build: " + _toDisplayString(build.value), 1))
            : _createCommentVNode("", true),
          (bundle.value || bundle.value === null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Bundle: " + _toDisplayString(bundle.value === null ? '-' : bundle.value), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})