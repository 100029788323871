<template>
  <q-select
    :error="!!errors ? true : undefined"
    :error-message="errors ? errors.join(' ') : undefined"
    v-bind="$attrs"
    :behavior="behavior"
    :options="fieldOptions"
    emit-value
    map-options
  >
    <template v-for="(name, i) in $slots" #[name]>
      <slot :name="name" :index="i"/>
    </template>

    <template #option="{ itemProps, opt, selected, toggleOption }">
      <template v-if="withToggle">
        <q-item v-bind="itemProps">
          <q-item-section>
            <q-item-label>{{ opt.label }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-toggle :model-value="selected" @update:model-value="toggleOption(opt)"/>
          </q-item-section>
        </q-item>
      </template>

      <template v-else>
        <q-item v-bind="itemProps" active-class="bg-grey-3">
          <q-item-section>
            <q-item-label>{{ opt.label }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <q-checkbox :model-value="selected" @update:model-value="toggleOption(opt)"/>
          </q-item-section>
        </q-item>
      </template>
    </template>
  </q-select>
</template>

<script>
import {computed} from 'vue'

export default {
  name: 'ApiSelect',
  props: {
    errors: {default: undefined},
    options: {
      type: Array,
      default: () => []
    },
    field: {
      type: Object
    },
    behavior: {
      default: 'menu'
    },
    withToggle: {
      default: false,
      type: Boolean
    }
  },
  setup(props) {
    const fieldOptions = computed(() => {
      return props.options.flatMap((obj) => {
        return {
          label: obj['display_name'],
          value: obj['value']
        }
      })
    })

    return {
      fieldOptions,
    }
  }
}
</script>

<style scoped>

</style>
