import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center items-center q-gutter-lg"
}
const _hoisted_2 = { class: "flex column items-center" }
const _hoisted_3 = {
  key: 0,
  class: "text-center text-grey-5"
}

import SrcsetImg from 'components/qutils/SrcsetImg.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ListPartner',
  props: {
  modelValue: {
    type: Array,
    required: true,
  },
  width: {
    type: String,
    default: undefined,
  },
  maxWidth: {
    type: String,
    default: undefined,
  },
  maxHeight: {
    type: String,
    default: undefined,
  },
  height: {
    type: String,
    default: undefined,
  },
  hideLink: {
    default: false,
    type: Boolean
  },
  disableLink: {
    default: false,
    type: Boolean
  }
},
  setup(__props) {




return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (__props.modelValue)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.modelValue, (partner) => {
          return (_openBlock(), _createElementBlock("div", {
            key: partner.uuid,
            class: "col-grow"
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(!__props.disableLink ? 'a' : 'div'), _mergeProps({
              href: partner.link,
              style: {"text-decoration":"unset","width":"100%","display":"inline-block"},
              target: "_blank",
              ref_for: true
            }, {'rel': 'external noopener nofollow'}), {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(SrcsetImg, {
                    alt: partner.title,
                    height: __props.height,
                    src: partner.logo_image?.path,
                    srcset: partner.logo_image?.srcset,
                    width: __props.width,
                    style: _normalizeStyle({maxWidth: __props.maxWidth, maxHeight: __props.maxHeight}),
                    fit: "contain"
                  }, null, 8, ["alt", "height", "src", "srcset", "width", "style"]),
                  (!__props.hideLink)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_q_icon, { name: "fas fa-external-link" }),
                        _createTextVNode("  " + _toDisplayString(partner.title), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1040, ["href"]))
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})