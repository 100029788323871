import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row q-gutter-y-md" }
const _hoisted_3 = { class: "col-12 col-sm-6" }
const _hoisted_4 = { class: "flex column no-wrap items-center" }
const _hoisted_5 = { class: "full-width flex justify-center" }
const _hoisted_6 = { class: "col-12 col-sm-6 flex content-center" }
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = ["innerHTML"]

import Cms from 'src/api/cms';
import {reactive, ref} from 'vue';
import {Page} from 'src/models/cms';
import {useMeta, useQuasar} from 'quasar';
import SocialMediaLogos from 'src/components/SocialMediaLogos.vue';
import LogoAndText from 'src/components/LogoAndText.vue';
import Core from 'src/api/core';
// import {VueRecaptcha} from 'vue-recaptcha';
import {getPageUrl} from 'src/etc/helper';
import WhatsAppChatBtn from 'src/components/etc/WhatsAppChatBtn.vue';
import {useRouter} from 'vue-router';
import ApiForm from 'components/qutils/form/ApiForm.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactPage',
  setup(__props) {

const $q = useQuasar();
const page = ref<Page>();
const submitted = ref<boolean>(false);
const formData = reactive({});
const errors = ref(undefined);
const fields = ref(undefined);

const recaptchaToken = ref();
// const loadingRecaptcha = ref(true);
const recaptcha = ref(null);
const router = useRouter();

Cms.getPage('contact')
  .then((resp) => {
    page.value = resp.data;
  })
  .catch((err) => {
    console.error(err);
  });

Core.fetchContactForm()
  .then((resp) => {
    fields.value = resp.data.actions.POST;
  })
  .catch((err) => {
    console.error(err);
  });

// const verify = function (response) {
//   recaptchaToken.value = response;
// };
//
// const render = function (/*id*/) {
//   loadingRecaptcha.value = false;
// };
//
// const recaptchaKey = computed(() => {
//   try {
//     return process.env.GOOGLE_RECAPTCHA_SITE_KEY;
//   } catch (e) {
//     console.error('GOOGLE_RECAPTCHA_SITE_KEY not defined');
//   }
//   return '';
// });

const onSubmit = function (data) {
  $q.loading.show();
  Core.createContactMessage({...data, ...{recaptcha: recaptchaToken.value}})
    .then(() => {
      submitted.value = true;
    })
    .catch((err) => {
      errors.value = err.response?.data;
      if (!err.response) {
        console.error(err);
      }
    })
    .finally(() => {
      $q.loading.hide();
      if (recaptcha.value) {
        (recaptcha.value as any).reset();
      }
    });
};

useMeta(() => {
  return {
    // sets document title
    titleTemplate(title) {
      return `Kontakt | ${title}`;
    },

    link: {
      canonical: {
        rel: 'canonical',
        href: getPageUrl('contact', router) || '',
      },
    },

    // meta tags
    meta: {
      description: {
        name: 'description',
        content:
          'Hast du Fragen oder vielleicht sogar Anregungen zu Camper Trader? Lass uns darüber sprechen.',
      },
    },
  };
});

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-py-xl" }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "col-12 text-center font-fjalla-one q-mb-xl" }, [
            _createElementVNode("h1", { class: "q-py-none" }, "Kontaktiere uns gerne bei Fragen")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(LogoAndText, { dark: "" }),
              _createVNode(SocialMediaLogos, { dark: "" })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(WhatsAppChatBtn, {
                number: "4915679366374",
                style: {"width":"200px"}
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            (!submitted.value)
              ? (_openBlock(), _createBlock(ApiForm, {
                  key: 0,
                  modelValue: formData,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData) = $event)),
                  errors: errors.value,
                  "fields-config": {
                  message: {
                    attrs: { type: 'textarea' },
                  },
                  recaptcha: {
                    type: 'input',
                    attrs: { type: 'hidden' },
                  },
                },
                  "option-fields": fields.value,
                  outlined: "",
                  onSubmit: onSubmit
                }, {
                  submit: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_q_btn, {
                        color: "primary",
                        type: "submit",
                        unelevated: ""
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("Anfrage absenden ")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "errors", "option-fields"]))
              : (_openBlock(), _createBlock(_component_q_banner, {
                  key: 1,
                  class: "bg-positive text-white full-width text-center"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Vielen Dank für deine Nachricht. Bitte habe etwas Geduld, wir bearbeiten dein Anliegen so schnell es geht! ")
                  ])),
                  _: 1
                }))
          ])
        ])
      ]),
      (page.value?.content)
        ? (_openBlock(), _createElementBlock("section", {
            key: 0,
            class: "container vhtml-container",
            innerHTML: page.value.content
          }, null, 8, _hoisted_8))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})