import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-bold" }

import {computed} from 'vue';

type LimitType = undefined | 'default' | 'advertLimit' | 'searchAgentLimit';

// Interface for the props
interface Props {
  limitType: LimitType;
}



export default /*@__PURE__*/_defineComponent({
  __name: 'LimitReachedBanner',
  props: {
    limitType: {}
  },
  setup(__props: any) {

const textMapping = {
  'default': 'Du hast das Limit für diese Aktion erreicht.',
  'advertLimit': 'Du hast dein Limit erreicht und kannst keine weiteren Fahrzeug Inserate schalten.',
  'searchAgentLimit': 'Du kannst keine weiteren Suchaufträge anlegen. Limit erreicht.',
}

// Define a type for the prop
const props = __props

const limitText = computed(() => {
  if (!props.limitType || !Object.keys(textMapping).includes(props.limitType)) return textMapping['default']
  return textMapping[props.limitType]
})

return (_ctx: any,_cache: any) => {
  const _component_q_banner = _resolveComponent("q-banner")!

  return (_openBlock(), _createBlock(_component_q_banner, {
    class: "bg-warning text-center",
    rounded: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(limitText.value), 1)
    ]),
    _: 1
  }))
}
}

})