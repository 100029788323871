
import {defineComponent, onMounted} from 'vue';
import {useMeta} from 'quasar';
import {useRoute, useRouter} from 'vue-router';
import {getPageUrl} from 'src/etc/helper'

export default defineComponent({
  name: 'ErrorNotFound',
  setup() {
    const route = useRoute()
    const router = useRouter()
    onMounted(() => {
      if (route.query.url) {
        window.history.replaceState(undefined, '', route.query.url.toString());
      }
    })

    useMeta(() => {
      return {
        // sets document title
        titleTemplate() {
          return 'Seite nicht gefunden | 404'
        },
        link: {
          canonical: {
            rel: 'canonical', href: getPageUrl('404', router) || ''
          }
        }
      }
    })
  }
});

