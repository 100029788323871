import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "container",
  style: {"margin-top":"3rem"}
}
const _hoisted_2 = { class: "flex text-center justify-center no-wrap about-us-img--wrapper" }
const _hoisted_3 = { style: {"margin-left":"-2rem","z-index":"-1"} }
const _hoisted_4 = ["innerHTML"]

import Cms from 'src/api/cms';
import {ref} from 'vue';
import {Page} from 'src/models/cms';
import {useMeta} from 'quasar';
import {getPageUrl} from 'src/etc/helper';
import {useRouter} from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'AboutUsPage',
  setup(__props) {

const page = ref<Page>()
const router = useRouter()

Cms.getPage('about-us').then(resp => {
  page.value = resp.data
}).catch(err => {
  console.error(err)
})

useMeta(() => {
  return {
    // sets document title
    titleTemplate(title) {
      return `Über uns | ${title}`
    },
    link: {
      canonical: {
        rel: 'canonical', href: getPageUrl('about-us', router) || ''
      }
    },
    meta: {
      description: {
        name: 'description',
        content: 'Alles über das Camper Trader Team, über unsere Campervan-Plattform Idee und unseren Werdegang.',
      },
    }

  }
})


return (_ctx: any,_cache: any) => {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("section", {
        class: "content text-center font-inter",
        style: {"margin-top":"4rem"}
      }, [
        _createElementVNode("h1", { class: "text-uppercase" }, "Über uns")
      ], -1)),
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_q_img, {
              src: require('assets/sandra_square.jpeg')
            }, null, 8, ["src"]),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-h3 font-inter" }, "Sandra", -1))
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_img, {
              src: require('assets/dominik.jpeg')
            }, null, 8, ["src"]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-h3 font-inter" }, "Dominik", -1))
          ])
        ])
      ]),
      (page.value?.content)
        ? (_openBlock(), _createElementBlock("section", {
            key: 0,
            class: "container vhtml-container",
            innerHTML: page.value.content
          }, null, 8, _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})