import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import {computed, ref} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectReasonForm',
  props: {
  options: {
    default: () => [],
    type: Array,
  },
  modelValue: {
    required: false,
  },
},
  emits: ['update:modelValue'],
  setup(__props) {



const reason = ref();
const otherReason = ref();
const props = __props;

const getOptions = computed(() => {
  const options = (props.options as any) || [];
  options.push({label: 'Anderer Grund', value: 'other'});
  return options;
});

return (_ctx: any,_cache: any) => {
  const _component_q_option_group = _resolveComponent("q-option-group")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_q_option_group, {
        modelValue: reason.value,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((reason).value = $event)),
          _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', reason.value)))
        ],
        options: getOptions.value,
        type: "radio"
      }, null, 8, ["modelValue", "options"])
    ]),
    (reason.value === 'other')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_q_input, {
            modelValue: otherReason.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((otherReason).value = $event)),
            label: "Anderer Grund",
            outlined: "",
            type: "text",
            onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:modelValue', otherReason.value)))
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})