import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {computed} from 'vue';
import AdvertImageUpload from 'src/components/adverts/AdvertImageUpload.vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'ImagesStep',
  props: {
  modelValue: {
    required: true,
  },
  errors: {
    required: false,
    type: Object,
  },
  advertUuid: {
    type: String,
    required: true,
  }
},
  emits: ['update:modelValue', 'update:files'],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const props = __props;


const formData = computed({
  // getter
  get() {
    return props.modelValue;
  },
  // setter
  set(newValue) {
    emit('update:modelValue', newValue);
  },
}) as any;



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(AdvertImageUpload, {
      modelValue: formData.value.images,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.value.images) = $event)),
      "advert-uuid": __props.advertUuid
    }, null, 8, ["modelValue", "advert-uuid"])
  ]))
}
}

})