import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "relative-position",
  style: {"border-radius":"15px","overflow":"hidden"}
}
const _hoisted_2 = {
  class: "absolute-top-right q-pa-sm",
  style: {"z-index":"400"}
}

import {LCircle, LMap, LTileLayer} from '@vue-leaflet/vue-leaflet';
import {ref} from 'vue';
import MapDialog from 'src/apps/core/dialogs/MapDialog.vue';
import {getCssVar, useQuasar} from 'quasar';
import MapBusinessIcon from 'src/apps/core/components/icons/MapBusinessIcon.vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'AdvertLocationMap',
  props: {
  lat: {
    required: true,
  },
  lng: {
    required: true,
  },
  zoom: {
    default: 10
  },
  isBusiness: {
    default: false,
    type: Boolean
  }
},
  setup(__props) {

const props = __props

const mapRef = ref(null)
const $q = useQuasar()
const options = ref({
  zoomControl: false,
  attributionControl: false
})


const disableMap = () => {
  if (!mapRef.value) return
  const map = mapRef.value['leafletObject'] as any
  if (!map) {
    console.error('no map')
    return
  }
  map.dragging.disable();
  map.touchZoom.disable();
  map.doubleClickZoom.disable();
  map.scrollWheelZoom.disable();
  map.boxZoom.disable();
  map.keyboard.disable();
  if (map.tap) map.tap.disable();
}

const initMap = async function () {
  disableMap()
}


function onButtonClick() {
  $q.dialog({
    component: MapDialog,

    // props forwarded to your custom component
    componentProps: {
      center: [props.lat, props.lng],
      zoom: props.zoom,
      isBusiness: props.isBusiness
    }
  })
}


return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(LMap), {
      ref_key: "mapRef",
      ref: mapRef,
      "use-global-leaflet": false,
      options: options.value,
      style: _normalizeStyle([{"width":"100%"}, {aspectRatio: _unref($q).screen.lt.sm ? '16/9' : '3/1'}]),
      center: [__props.lat, __props.lng],
      zoom: __props.zoom,
      onReady: initMap
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(LTileLayer), {
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          "layer-type": "base",
          name: "OpenStreetMap"
        }),
        (!__props.isBusiness)
          ? (_openBlock(), _createBlock(_unref(LCircle), {
              key: 0,
              "lat-lng": [__props.lat, __props.lng],
              radius: 1500,
              "fill-color": _unref(getCssVar)('secondary') || undefined,
              color: _unref(getCssVar)('secondary') || undefined
            }, null, 8, ["lat-lng", "fill-color", "color"]))
          : (_openBlock(), _createBlock(MapBusinessIcon, {
              key: 1,
              "lat-lng": [__props.lat, __props.lng]
            }, null, 8, ["lat-lng"]))
      ]),
      _: 1
    }, 8, ["options", "style", "center", "zoom"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_btn, {
        class: "c-btn-resize",
        "text-color": "dark",
        round: "",
        onClick: onButtonClick
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_icon, {
            name: "fas fa-up-right-and-down-left-from-center",
            size: "xs"
          })
        ]),
        _: 1
      })
    ])
  ]))
}
}

})