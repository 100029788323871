import script from "./AppMainLayout.vue?vue&type=script&setup=true&lang=ts"
export * from "./AppMainLayout.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QNoSsr from 'quasar/src/components/no-ssr/QNoSsr.js';
import qInstall from "../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QPageContainer,QNoSsr});
