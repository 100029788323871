import script from "./DealerSearchListItem.vue?vue&type=script&setup=true&lang=ts"
export * from "./DealerSearchListItem.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QImg from 'quasar/src/components/img/QImg.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';import Ripple from 'quasar/src/directives/ripple/Ripple.js';
import qInstall from "../../../../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QImg,QIcon,QAvatar,QSkeleton});qInstall(script, 'directives', {Ripple});
