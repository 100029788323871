import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, TransitionGroup as _TransitionGroup, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "c-avatar-wrapper q-py-lg flex no-wrap q-mb-xl" }

import {onMounted, onUnmounted, ref} from 'vue';
import core from 'src/api/core';
import {useRouter} from 'vue-router';

const LIST_LENGTH = 5

export default /*@__PURE__*/_defineComponent({
  __name: 'UserListWidget',
  props: {
  labelClass: {type: String, default: 'text-dark'}
},
  setup(__props) {


const userList = ref()
const router = useRouter()



const fetchUserList = () => {
  return core.fetchWidgetUserList().then(resp => {
    userList.value = resp.data
    return resp
  })
}


let invervalNextUser
let nextUserIndex = ref(LIST_LENGTH)
let tmpUserList = ref()

const currentIndex = ref(Math.floor(LIST_LENGTH / 2))

const nextUserAnimation = function () {
  currentIndex.value = Math.floor(Math.floor(LIST_LENGTH / 2))

  if (!Array.isArray(tmpUserList.value)) return
  // remove one at start
  tmpUserList.value.splice(0, 1)
  nextUserIndex.value++

  if (nextUserIndex.value >= userList.value.length) {
    nextUserIndex.value = 0
  }
  tmpUserList.value.push(userList.value[nextUserIndex.value])
}

const animateNextUser = function () {
  if (invervalNextUser) clearInterval(invervalNextUser)
  invervalNextUser = setInterval(nextUserAnimation, 2500)
}

const clickUser = function (index, uuid) {
  if (currentIndex.value === index) {
    const routeData = router.resolve({name: 'profile', params: {uuid}});
    window.open(routeData.href, '_blank')
  }
  currentIndex.value = index
}

const stopAnimation = function () {
  clearInterval(invervalNextUser)
}

const intersect = function (entry): void {
  if (entry.isIntersecting) {
    animateNextUser()
  } else {
    stopAnimation()
  }
}

onMounted(async () => {
  await fetchUserList()
  tmpUserList.value = [...userList.value].slice(0, LIST_LENGTH)
})

onUnmounted(() => {
  stopAnimation()
})


return (_ctx: any,_cache: any) => {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!
  const _directive_intersection = _resolveDirective("intersection")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_no_ssr, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_TransitionGroup, { name: "list" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tmpUserList), (user, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: user?.uuid,
                  class: _normalizeClass(["q-avatar-container relative-position", {'open': currentIndex.value === index}])
                }, [
                  _createVNode(_component_q_avatar, {
                    class: "c-avatar shadow-3",
                    size: "4rem",
                    onClick: ($event: any) => (clickUser(index, user.uuid)),
                    onMouseleave: animateNextUser,
                    onMouseover: stopAnimation
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_img, {
                        alt: user.name,
                        src: user.avatar.path,
                        "no-spinner": "",
                        "no-transition": ""
                      }, null, 8, ["alt", "src"])
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createElementVNode("div", {
                    class: _normalizeClass([__props.labelClass, "c-avatar-name font-fjalla-one no-wrap ellipsis"])
                  }, _toDisplayString(user.name), 3)
                ], 2))
              }), 128))
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ])), [
    [_directive_intersection, intersect]
  ])
}
}

})