import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-12 flex column q-gutter-y-sm" }
const _hoisted_2 = { class: "full-width" }

import ApiForm from 'components/qutils/form/ApiForm.vue';
// import {VueRecaptcha} from 'vue-recaptcha';
import {ref} from 'vue';
import {useMainStore} from 'stores/main';


export default /*@__PURE__*/_defineComponent({
  __name: 'SignupForm1',
  props: {
  errors: {default: undefined},
  loading: {default: false}
},
  emits: ['submit'],
  setup(__props, { emit: __emit }) {

const emit = __emit



const submitted = ref(false);
const formData = ref({});
const mainStore = useMainStore()
mainStore.fetchSignupFormFields()

// ReCaptcha not working in app
// const recaptchaToken = ref();
// const loadingRecaptcha = ref(true);
// const recaptcha = ref(null);
//
// const verify = function (response) {
//   recaptchaToken.value = response;
// };
//
// const render = function (/*id*/) {
//   loadingRecaptcha.value = false;
// };
//
// const recaptchaKey = computed(() => {
//   try {
//     return process.env.GOOGLE_RECAPTCHA_SITE_KEY;
//   } catch (e) {
//     console.error('GOOGLE_RECAPTCHA_SITE_KEY not defined');
//   }
//   return '';
// });

function onSubmit() {
  emit('submit', {
    ...formData.value,
    // recaptcha: recaptchaToken.value
  })
}


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (!submitted.value)
    ? (_openBlock(), _createBlock(ApiForm, {
        key: 0,
        modelValue: formData.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData).value = $event)),
        errors: __props.errors,
        "fields-config": {
                  email: { attrs: { type: 'email' } },
                  password1: {
                    attrs: {
                      autocomplete: 'off',
                      'hide-bottom-space': true,
                    },
                  },
                  password2: {
                    attrs: {
                      class: 'col-12 q-mb-lg',
                      autocomplete: 'off',
                    },
                  },
                  is_newsletter: { attrs: { class: 'col-12' } },
                  recaptcha: { type: 'input', attrs: { type: 'hidden' } },
                },
        "option-fields": _unref(mainStore).signupFormFields,
        outlined: "",
        "exclude-fields": ['business_data', 'plan', 'payment_method'],
        onSubmit: onSubmit
      }, {
        extras: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createVNode(_component_router_link, {
                to: { name: 'privacy' },
                target: "_blank"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Datenschutzbestimmungen ")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        submit: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_q_btn, {
              loading: __props.loading,
              color: "primary full-width",
              size: "lg",
              type: "submit"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Registieren ")
              ])),
              _: 1
            }, 8, ["loading"])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "errors", "option-fields"]))
    : _createCommentVNode("", true)
}
}

})