import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "q-mb-md flex column q-gutter-md" }

import Auth from 'src/api/auth';
import {ref} from 'vue';
import BannerNotification from 'src/apps/core/components/BannerNotification.vue';

export interface BannerNotification {
  uuid?: string,
  title?: string,
  message?: string,
  level?: string,
  fa_icon?: string,
  is_dismissible?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BannerNotificationList',
  setup(__props) {

const loading = ref(false)
const notifications = ref<Array<BannerNotification>>()
const loadingDismiss = ref(false)

const getNotifications = () => {
  loading.value = true
  Auth.getUserNotificationBanner().then(resp => {
    notifications.value = resp.data
  }).catch(err => {
    console.error(err)
  }).finally(() => {
    loading.value = false
  })
}
getNotifications()

const dismissNotification = (pos) => {
  if (!notifications.value?.length) return
  const uuid = notifications.value[pos]['uuid']

  loadingDismiss.value = true
  Auth.postUserNotification(uuid).then(() => {
    notifications.value?.splice(pos, 1)
  }).catch(err => {
    console.error(err)
  }).finally(() => {
    loadingDismiss.value = false
  })
}

return (_ctx: any,_cache: any) => {
  return (notifications.value?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notifications.value, (notification, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: notification.uuid
            }, [
              _createVNode(BannerNotification, {
                title: notification.title,
                message: notification.message,
                level: notification.level,
                "fa-icon": notification.fa_icon,
                "is-dismissible": notification?.is_dismissible,
                onDismiss: ($event: any) => (dismissNotification(index))
              }, null, 8, ["title", "message", "level", "fa-icon", "is-dismissible", "onDismiss"])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})