import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bg-white rounded-borders" }
const _hoisted_2 = { class: "relative-position rounded-borders overflow-hidden" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "absolute-top-right"
}
const _hoisted_5 = { class: "q-pa-lg" }
const _hoisted_6 = {
  key: 1,
  class: "absolute-center"
}
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "absolute-center full-width" }
const _hoisted_9 = { class: "q-ma-lg q-pa-sm bg-negative rounded-borders text-white" }
const _hoisted_10 = {
  class: "flex items-center no-wrap",
  style: {"column-gap":"12px"}
}
const _hoisted_11 = {
  class: "flex column justify-center items-center full-height text-center",
  style: {"row-gap":"12px"}
}

import {useMainStore} from 'stores/main';
import {computed, PropType, ref, watchEffect} from 'vue';
import {toBase64} from 'src/etc/utils';
import Core from 'src/api/core';
import DragImagesOrder from 'components/imageUpload/DragImagesOrder.vue';
import {useQuasar} from 'quasar';
import ImageEditorDialog from 'components/imageUpload/ImageEditorDialog.vue';


export interface Image {
  uuid: string
  path: string
}

interface fileListItem {
  file?: File | undefined
  uploading?: boolean
  errors: Array<string>
  progress?: number
  success?: boolean
  uploadedImage?: any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageUploader',
  props: {
  images: {type: Array as PropType<Array<Image>>, default: () => []},
  aspectRatio: {type: Number, default: 1},
  cropperAspectRatio: {type: Number, default: 1},
  containerColClasses: {
    default: 'col-6 col-sm-4 col-md-3 col-lg-2',
  }
},
  emits: ['images', 'update:images', 'change:images'],
  setup(__props, { emit: __emit }) {


const $emit = __emit

const props = __props

const mainStore = useMainStore()

const dropAreaDragOver = ref(false)
const fileInput = ref<HTMLInputElement | null>(null)
const validationErrors = ref([])
const uploadingFiles = ref<Array<fileListItem>>([])
const $q = useQuasar()

const MAX_IMAGES = mainStore.siteConfig['static_settings'].advert_max_images
const MAX_IMAGE_SIZE_IN_MB = mainStore.siteConfig['static_settings'].advert_max_imagesize_in_mb

function getImageBlur(progress: number) {
  return {
    filter: `blur(${Math.round(5 - (progress * (5 / 100)))}px)`,
  }
}

const containerClass = computed(() => {
  return `${props.containerColClasses}`
})

async function clickInput() {
  if (!fileInput.value) {
    return
  }
  validationErrors.value = []
  fileInput.value.click()
}

function getLocalFileUrl(file) {
  try {
    return URL.createObjectURL(file)
  } catch (e) {
    console.error('Error creating local file url', e)
    return ''
  }
}

async function uploadFile(fileItem: fileListItem) {
  if (!fileItem?.file) {
    console.error('No fileItem found', fileItem)
    return
  }

  fileItem.progress = 0
  console.debug('fileItem', fileItem.file)
  let base64Image
  try {
    base64Image = await toBase64(fileItem.file);
    console.debug('base64Image', base64Image)
  } catch (err: any) {
    console.error('Error converting image to base64', err.toString())
    fileItem.errors = ['Fehler beim Konvertieren des Bildes.']
    fileItem.uploading = false
    return
  }

  try {
    const resp = await Core.uploadTmpImage(
      {image: base64Image},
      {
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            fileItem.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          }
        }
      })

    fileItem.success = true

    if (resp.data?.uuid) {
      fileItem.uploadedImage = resp.data
      $emit('update:images', [...props.images, {
        uuid: resp.data.uuid,
        path: resp.data.url,
      }])
    }

  } catch (err: any) {
    console.error(err)
    fileItem.errors = ['Fehler beim Hochladen.']
    if (err.response?.data?.non_field_errors) {
      fileItem.errors = [...err.response?.data?.non_field_errors]
    }
  }

  fileItem.uploading = false
}


watchEffect(() => {
  $emit('images', uploadingFiles.value)
})

async function addFile(file: File) {
  const fileSizeInMB = file.size / (1024 * 1024)
  if (fileSizeInMB > MAX_IMAGE_SIZE_IN_MB) {
    uploadingFiles.value.push({
      file,
      uploading: false,
      errors: [`Größe für Datei überschritten (max. ${MAX_IMAGE_SIZE_IN_MB} MB)`]
    })
    return
  }

  uploadingFiles.value.push({
    file,
    uploading: true,
    errors: []
  })
}


async function processFiles() {
  for (let index = 0; index < uploadingFiles.value.length; index++) {
    const item: fileListItem = uploadingFiles.value[index];
    if (item.uploading === true) {
      await uploadFile(item)
    }
  }
}

async function drop(e) {
  for (let index = 0; index < e.dataTransfer.files.length; index++) {
    await addFile(e.dataTransfer.files[index])
  }

  await processFiles()
}


async function changeFile() {
  if (!fileInput.value || !fileInput.value?.files) {
    return
  }

  const imageFiles: Array<File> = []
  for (let index = 0; index < fileInput.value.files.length; index++) {
    const file: File = fileInput.value.files[index];
    const buffer = await file.arrayBuffer();
    const clone = new File([buffer], file.name, {type: file.type});
    // await addFile(clone)
    imageFiles.push(clone)
  }

  $q.dialog({
    component: ImageEditorDialog,
    componentProps: {
      imageFiles: imageFiles,
      aspectRatio: props.cropperAspectRatio || props.aspectRatio
    }
  }).onOk(async (files: Array<File>) => {
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      await addFile(file)
    }
    await processFiles()
  })
}

function removeFromUploadQueue(pos) {
  uploadingFiles.value.splice(pos, 1)
}

const currentlyUploadingImages = computed(() => {
  const _uuids = props.images.flatMap(obj => obj.uuid)
  return uploadingFiles.value.filter(obj => !_uuids.includes(obj.uploadedImage?.uuid) && obj.uploading)
})



return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_circular_progress = _resolveComponent("q-circular-progress")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["c-image-drop-area rounded-borders relative-position q-pa-sm cursor-pointer", {'drag-over': dropAreaDragOver.value}]),
      onDrop: _cache[3] || (_cache[3] = _withModifiers((evt) => { drop(evt); dropAreaDragOver.value = false}, ["prevent"])),
      onDragenter: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"])),
      onDragover: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (dropAreaDragOver.value = true), ["prevent"])),
      onDragleave: _cache[6] || (_cache[6] = ($event: any) => (dropAreaDragOver.value = false)),
      onDragend: _cache[7] || (_cache[7] = ($event: any) => (dropAreaDragOver.value = false))
    }, [
      _createElementVNode("div", null, [
        _createVNode(DragImagesOrder, {
          "model-value": __props.images,
          "aspect-ratio": __props.aspectRatio,
          "col-classes": __props.containerColClasses,
          onChange: _cache[1] || (_cache[1] = val => $emit('change:images', val)),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = val => $emit('update:images', val))
        }, {
          append: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentlyUploadingImages.value, (image, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(containerClass.value),
                draggable: false,
                onContextmenu: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("img", {
                    class: "block",
                    src: getLocalFileUrl(image.file),
                    style: _normalizeStyle([{"object-fit":"cover","width":"100%"}, {aspectRatio: __props.aspectRatio, ...getImageBlur(image.progress ?? 0)}]),
                    draggable: false
                  }, null, 12, _hoisted_3),
                  (!image.uploading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_q_btn, {
                            class: "absolute-center",
                            icon: "fas fa-times",
                            color: "white",
                            "text-color": "dark",
                            padding: "xs",
                            round: "",
                            onClick: ($event: any) => (removeFromUploadQueue(index))
                          }, null, 8, ["onClick"])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (image.uploading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_q_circular_progress, {
                          value: image.progress ?? 0,
                          size: "3rem",
                          thickness: 0.4,
                          color: "teal",
                          "track-color": "white"
                        }, null, 8, ["value"])
                      ]))
                    : _createCommentVNode("", true),
                  (!image.uploading && image.errors.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", null, [
                                _createVNode(_component_q_icon, {
                                  name: "fas fa-circle-exclamation",
                                  size: "sm"
                                })
                              ]),
                              _createElementVNode("div", null, _toDisplayString(image.errors.join('. ')), 1)
                            ])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ], 34))
            }), 128)),
            _createElementVNode("div", {
              class: _normalizeClass(containerClass.value)
            }, [
              (__props.images?.length < _unref(MAX_IMAGES))
                ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 0,
                    style: _normalizeStyle({aspectRatio: __props.aspectRatio}),
                    class: "relative-position full-height bg-grey-3 rounded-borders q-pa-md",
                    onClick: clickInput
                  }, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_q_icon, {
                        size: "1.7rem",
                        name: "fas fa-circle-plus "
                      }),
                      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-bold" }, "Fotos hinzufügen", -1)),
                      _createElementVNode("input", {
                        ref_key: "fileInput",
                        ref: fileInput,
                        multiple: true,
                        class: "hidden",
                        name: "files",
                        type: "file",
                        accept: "image/png, image/gif, image/jpeg",
                        onChange: changeFile
                      }, null, 544)
                    ])
                  ], 4)), [
                    [
                      _directive_ripple,
                      void 0,
                      void 0,
                      { early: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ], 2)
          ]),
          _: 1
        }, 8, ["model-value", "aspect-ratio", "col-classes"])
      ])
    ], 34)
  ]))
}
}

})