import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "absolute-center text-center flex q-gutter-y-md justify-center column" }
const _hoisted_2 = { class: "text-h2 font-inter" }
const _hoisted_3 = ["href"]

import {onMounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import LogoAndText from 'src/components/LogoAndText.vue';

const timeoutMs = 3000

export default /*@__PURE__*/_defineComponent({
  __name: 'ExternalLinkRedirect',
  setup(__props) {

const router = useRouter()
const route = useRoute()
const counterNum = ref(timeoutMs / 1000)

const url = route.query.url


onMounted(() => {
  if (!url) {
    router.replace('/')
  } else {
    const counter = setInterval(() => {
      counterNum.value = counterNum.value - 1
    }, 1000)
    setTimeout(() => {
      clearInterval(counter)
      counterNum.value = 0
      window.location.replace(url as string)
    }, timeoutMs)
  }
})

return (_ctx: any,_cache: any) => {
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-h3 font-inter" }, "Du verlässt", -1)),
        _createVNode(LogoAndText, { class: "q-mb-xl" }),
        _createElementVNode("div", _hoisted_2, "Weiterleitung in " + _toDisplayString(counterNum.value) + " Sekunden ...", 1),
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            href: _unref(url),
            class: "text-white",
            rel: "noopener noopener"
          }, [
            _createElementVNode("pre", null, _toDisplayString(_unref(url)), 1)
          ], 8, _hoisted_3),
          _cache[0] || (_cache[0] = _createElementVNode("div", null, "Klicke auf den Link falls du nicht automatisch weitergeleitet wirst.", -1))
        ])
      ])
    ]),
    _: 1
  }))
}
}

})