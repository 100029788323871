import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {computed} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FlagIcon',
  props: {
  countryCode: {
    type: String, default: 'de',
    validator: (value: string) => {
      return value.length === 2;
    }
  }
},
  setup(__props) {

const props = __props

const flagEmoji = computed(() => {
  // Each country code character needs to be mapped to its regional indicator symbol.
  // Unicode regional indicators range starts at 0x1F1E6 for 'A', so we need to calculate the offset
  // from 'A' for each letter in the country code, then add that offset to the base Unicode point for 'A'.
  const base = 127397; // Unicode point for regional indicator symbol letter A
  if (props.countryCode.length === 2) {
    const high = props.countryCode.toUpperCase().charCodeAt(0);
    const low = props.countryCode.toUpperCase().charCodeAt(1);
    return String.fromCodePoint(high + base) + String.fromCodePoint(low + base);
  }
  return 'Invalid code'; // You can handle invalid input as necessary
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", null, _toDisplayString(flagEmoji.value), 1))
}
}

})