
import {computed, defineComponent} from 'vue';
import {useMeta} from 'quasar';
import {getPageUrl} from 'src/etc/helper';
import {useMainStore} from 'stores/main';
import {useRouter} from 'vue-router';
import FadeOutText from 'components/qutils/FadeOutText.vue';
import SecureEmail from 'components/qutils/SecureEmail.vue';

export default defineComponent({
  name: 'FaqAndHelpPage',
  components: {SecureEmail, FadeOutText},
  async preFetch({store}) {
    const mainStore = useMainStore(store)
    return mainStore.fetchFAQs()
  },
  setup() {
    const mainStore = useMainStore()
    const router = useRouter()
    const faq = computed<Array<any>>(() => {
      return mainStore.faqs || []
    })

    useMeta(() => {
      return {
        // sets document title
        titleTemplate(title) {
          return `FAQ | ${title}`
        },

        meta: {
          description: {
            name: 'description',
            content: 'Hast du Fragen? Unsere FAQ und Hilfebereich bietet schnelle ' +
              'Antworten und Lösungen zu Deinen Anliegen. Unterstützung, wann immer du sie brauchst.',
          },
        },

        link: {
          canonical: {
            rel: 'canonical', href: getPageUrl('faq-help', router) || ''
          }
        },

        noscript: {
          default: 'Please enable Javascript to use this website.'
        }
      }
    })

    return {
      faq,
    }
  }
})
