
import {defineComponent, inject} from 'vue';
import HeaderToolbar from 'src/components/HeaderToolbar.vue';
import FooterBar from 'src/components/FooterBar.vue';
import DrawerMenu from 'src/components/DrawerMenu.vue';


export default defineComponent({
  name: 'MainLayout',
  components: {DrawerMenu, HeaderToolbar, FooterBar},
  setup () {
    const drawer = inject('drawer')
    return {
      drawer
    }
  }
});
