import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "col-4 bg-grey-3 relative-position" }
const _hoisted_2 = {
  class: "absolute-bottom-right",
  style: {"padding":"4px 10px"}
}
const _hoisted_3 = {
  key: 1,
  style: {"min-height":"200px"},
  class: "full-height full-width"
}
const _hoisted_4 = { class: "justify-between flex column full-height" }
const _hoisted_5 = { class: "text-weight-medium text-h4" }
const _hoisted_6 = { class: "text-grey-8" }
const _hoisted_7 = {
  key: 0,
  class: "text-muted text-small ellipsis-2-lines"
}
const _hoisted_8 = { class: "q-mt-md" }
const _hoisted_9 = { class: "text-grey-8 q-gutter-xs flex items-center" }
const _hoisted_10 = { class: "advert-stats flex q-gutter-sm q-mt-sm" }

import {PropType, ref} from 'vue';
import {useQuasar} from 'quasar';
import useItemUtils from 'src/apps/market/utils/useItemUtils';
import {shareItem} from 'src/apps/adverts/helper';
import {Item} from 'src/models/market';


export default /*@__PURE__*/_defineComponent({
  __name: 'AccountItemListItem',
  props: {
  item: {
    type: Object as PropType<Item>,
    required: true
  }
},
  emits: [],
  setup(__props, { emit: __emit }) {

const emit = __emit
const {deleteItem, toggleStatus} = useItemUtils(emit);
const disableToggle = ref({})
const $q = useQuasar()



function toggle(item) {
  disableToggle.value[item.uuid] = true
  toggleStatus(item).then(resp => {
    if (!resp?.data?.status) return
    item.status = resp.data.status
    item.status_display = resp.data.status_display
  }).finally(() => {
    disableToggle.value[item.uuid] = undefined
  })
}

const share = function (item) {
  shareItem($q, item)
};

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    bordered: "",
    flat: "",
    style: {"overflow":"hidden"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, {
        horizontal: !_unref($q).screen.lt.sm,
        class: _normalizeClass({'q-pa-none': _unref($q).screen.lt.sm})
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_link, {
              to: {name: 'item-detail', params: {slug: __props.item.seo_slug, uuid: __props.item.uuid}}
            }, {
              default: _withCtx(() => [
                (__props.item.cover_image?.image_thumbnail)
                  ? (_openBlock(), _createBlock(_component_q_img, {
                      key: 0,
                      width: "100%",
                      height: "100%",
                      src: __props.item.cover_image?.image_thumbnail?.path,
                      fit: "cover"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_q_icon, { name: "fas fa-images" }),
                          _createTextVNode("  " + _toDisplayString(__props.item.images_count), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["src"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[4] || (_cache[4] = [
                      _createElementVNode("div", { class: "absolute-center text-muted" }, "Keine Bilder", -1)
                    ])))
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createVNode(_component_q_card_section, { class: "col" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", null, _toDisplayString([__props.item.item_type_display, __props.item.offer_type_display].join(' - ').trim()), 1),
                  _createVNode(_component_router_link, {
                    to: {name: 'item-detail', params: {slug: __props.item.seo_slug, uuid: __props.item.uuid}}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(__props.item.title), 1)
                    ]),
                    _: 1
                  }, 8, ["to"]),
                  _createElementVNode("span", _hoisted_6, [
                    _createTextVNode("  " + _toDisplayString(_ctx.$filters.currency({
                  value: __props.item.price,
                  minimumFractionDigits: 0
                })), 1),
                    (__props.item.is_negotiable)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(" VB")
                        ], 64))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (__props.item.description)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(__props.item.description), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_q_btn, {
                      flat: "",
                      icon: "fas fa-pencil",
                      round: "",
                      size: "12px",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({name: 'update-item', params: {uuid: __props.item.uuid}})))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, { delay: 800 }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode(" Bearbeiten ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_btn, {
                      flat: "",
                      icon: "fas fa-trash",
                      round: "",
                      size: "12px",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(deleteItem)(__props.item)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, { delay: 800 }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode(" Löschen ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_btn, {
                      flat: "",
                      icon: "fas fa-share-alt",
                      round: "",
                      size: "12px",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (share(__props.item)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, { delay: 800 }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode(" Teilen ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_toggle, {
                      disable: disableToggle.value[__props.item.uuid],
                      label: `${__props.item.status_display}`,
                      "model-value": __props.item.status === 'active',
                      "checked-icon": "check",
                      color: "positive",
                      "unchecked-icon": "clear",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (toggle(__props.item)))
                    }, null, 8, ["disable", "label", "model-value"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_q_icon, { name: "visibility" }),
                      _cache[9] || (_cache[9] = _createTextVNode(" ")),
                      _createElementVNode("small", null, _toDisplayString(__props.item.views_count), 1),
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode("Aufrufe von Nutzern")
                        ])),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_q_icon, { name: "star" }),
                      _cache[11] || (_cache[11] = _createTextVNode(" ")),
                      _createElementVNode("small", null, _toDisplayString(__props.item.favorised_count), 1),
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("Von Nutzern gespeichert")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("small", null, "Erstellt " + _toDisplayString(_ctx.$filters.dateTimeFormat(__props.item.created_at)), 1)
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["horizontal", "class"])
    ]),
    _: 1
  }))
}
}

})