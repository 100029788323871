import {Notify, useQuasar} from 'quasar';
import SelectReasonModal from 'src/components/modals/SelectReasonModal.vue';
import {Item} from 'src/models/market';
import Market from 'src/api/market';

export default function useItemUtils(emit: any = undefined) {
  const $q = useQuasar();

  const deleteItem = async (item: Item) => {
    return new Promise(async (resolve, reject) => {
      const resp = await Market.getReasonChoices()
      $q.dialog({
        component: SelectReasonModal,
        componentProps: {
          options: resp.data,
          title: `"${item.title}" löschen?`,
          message: 'Bist du sicher, dass du dein Inserat löschen möchtest? Gibt es dafür einen Grund?',
          btnLabel: 'Löschen',
          btnColor: 'negative',
        },
      }).onOk(async payload => {
        try {
          await Market.deleteItem(item.uuid, payload)
          Notify.create({
            message: `"${item.title}" wurde gelöscht.`
          })
          emit('deletedItem')
          resolve(payload)
        } catch (err) {
          console.error(err)
          reject(err)
        }
      });
    })
  };

  const toggleStatus = function (item) {
    return new Promise<any>(async (resolve, reject) => {
      let status
      if (item.status === 'active') {
        status = 'inactive'
      } else if (item.status === 'inactive') {
        status = 'active'
      } else {
        reject('Invalid item status')
      }
      try {
        const resp = await Market.changeItemStatus(item.uuid, status)
        return resolve(resp)
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

  return {
    deleteItem,
    toggleStatus,
  };
}
