import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = ["href"]


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomLink',
  props: {
  href: {
    type: String,
    default: undefined,
  },
  noLink: {
    type: Boolean,
    default: false,
  },
  to: {
    type: [String, Object],
    default: undefined,
  }
},
  setup(__props) {




return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (!__props.noLink && __props.href)
    ? (_openBlock(), _createElementBlock("a", _mergeProps({ key: 0 }, _ctx.$attrs, { href: __props.href }), [
        _renderSlot(_ctx.$slots, "default")
      ], 16, _hoisted_1))
    : (!__props.noLink && __props.to)
      ? (_openBlock(), _createBlock(_component_router_link, _mergeProps({ key: 1 }, _ctx.$attrs, { to: __props.to }), _createSlots({ _: 2 }, [
          _renderList(_ctx.$slots, (_, name) => {
            return {
              name: name,
              fn: _withCtx((data) => [
                _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)))
              ])
            }
          })
        ]), 1040, ["to"]))
      : (_openBlock(), _createBlock(_resolveDynamicComponent('div'), _normalizeProps(_mergeProps({ key: 2 }, _ctx.$attrs)), {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 16))
}
}

})