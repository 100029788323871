import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  ref: "swiperRef",
  "autoplay-delay": "2000",
  "slides-per-view": "auto",
  "space-between": "30",
  style: {"height":"100px"},
  "disable-on-interaction": "false",
  navigation: "true",
  loop: "true"
}
const _hoisted_2 = ["href", "onClick"]
const _hoisted_3 = ["alt", "src"]

import {ref} from 'vue';
import Partner from 'src/api/partner';


export default /*@__PURE__*/_defineComponent({
  __name: 'PartnerCarousel',
  props: {
  modelValue: {
    required: false,
    type: Array
  }
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const loading = ref(true)
const partner = ref<Array<any>>([])



const emit = __emit

Partner.getPartnerList({placement: 'start'}).then(resp => {
  partner.value = resp.data
  emit('update:modelValue', partner.value)
}).catch(err => {
  console.error(err)
}).finally(() => {
  loading.value = false
})

const trackClick = function (partnerTitle) {
  const _paq = (window as any)._paq || []
  _paq.push(['trackEvent', 'PartnerCarousel', 'Click', partnerTitle]);
}

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("swiper-container", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(partner.value, (item) => {
        return (_openBlock(), _createElementBlock("swiper-slide", {
          key: item.id,
          class: "c-slide"
        }, [
          _createElementVNode("a", {
            href: item.link,
            rel: "nofollow noopener external",
            target: "_blank",
            class: "inline-block full-height",
            onClick: ($event: any) => (trackClick(item.title))
          }, [
            _createElementVNode("img", {
              style: {"object-fit":"contain","height":"100%"},
              alt: item.title,
              src: item.logo_image?.path
            }, null, 8, _hoisted_3)
          ], 8, _hoisted_2)
        ]))
      }), 128))
    ], 512)
  ], 512)), [
    [_vShow, partner.value]
  ])
}
}

})