import script from "./ExtrasStep.vue?vue&type=script&lang=ts&setup=true"
export * from "./ExtrasStep.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QBanner,QSelect,QInput,QBtn,QIcon});
