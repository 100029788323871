import Payment from 'src/api/payment';
import {CapacitorBrowser} from 'boot/inject-capacitor';

export function openPortal($q, route) {
  return new Promise(async (resolve, reject) => {
    let url
    try {
      const response = await Payment.getPortalUrl(
        new URL(route.fullPath, process.env.BASE_URL).href,
        $q.platform.is.nativeMobile,
      )
      url = response.data
    } catch (error) {
      console.error('Error creating customer portal session', error)
      reject(error)
    }

    if (!$q.platform.is.nativeMobile) {
      window.open(url, '_blank')
    } else {
      await CapacitorBrowser.open({url})
    }
    resolve({})
  })
}
