import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import AllSearchFilter from 'src/apps/search/components/searchFilter/AllSearchFilter.vue';
import {DEFAULT_SEARCH_TYPE} from 'src/apps/search/vars';
import {searchFilterComponentMapping} from 'src/apps/search/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchFilterWrapper',
  props: {
  searchType: {type: String, default: DEFAULT_SEARCH_TYPE},
  componentProps: {type: Object, default: undefined}
},
  emits: ['update:modelValue'],
  setup(__props) {






return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(searchFilterComponentMapping)[__props.searchType] ?? AllSearchFilter), _mergeProps(__props.componentProps, {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = val => _ctx.$emit('update:modelValue', val))
  }), null, 16))
}
}

})