import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { key: 0 }

import {ref, watchEffect} from 'vue'
import {debounce} from 'quasar';


export default /*@__PURE__*/_defineComponent({
  __name: 'ResponsiveItemSlider',
  props: {
  infiniteScroll: {
    default: true,
  },
  infiniteScrollOffset: {
    default: 1200,
  },
  loading: {
    default: false,
  },
  loadingMore: {
    default: false,
  },
  items: {
    required: true,
    default: () => [],
    type: Array
  },
  itemWidth: {
    default: '80vw',
  },
  itemMaxWidth: {default: '350px'},
  itemMinWidth: {default: '250px'},
  itemClass: {default: ''},
  wrapperClass: {required: false},
  wrapperStyle: {required: false},
  colClasses: {default: 'col-6 col-md-4'},
  onlyVertical: {default: false},
  isMarginLeft: {default: true},
  withContainer: {default: true},
  skeletonAmount: {default: 6},
  skeletonWidth: {default: '350px'},
  skeletonHeight: {default: '350px'},
  skeletonAmountMobile: {default: 2},
  id: {required: false, type: String},
  saveScrollPos: {default: true},
  store: {required: false}
},
  emits: ['load'],
  setup(__props, { emit: __emit }) {

const props = __props

const emit = __emit

const mobileWrapperRef = ref()
const scrollEventListenerTriggered = ref(false)
const scrollPosKey = `${props.id}_scroll_pos`

const savePosition = function (store, key, pos) {
  if (!store) return
  store[key] = pos
}

const loadPosition = function (store, key) {
  if (store?.hasOwnProperty(key) && mobileWrapperRef.value) {
    const pos = Number.parseInt(store[key])
    mobileWrapperRef.value.scrollTo(pos, 0)
  }
}

const handleScroll = debounce((e) => {
  if (props.id && props.saveScrollPos) {
    try {
      savePosition(props.store, scrollPosKey, e.target.scrollLeft)
    } catch (e) {
      console.error(e)
    }
  }

  if (!props.infiniteScroll) return
  if (scrollEventListenerTriggered.value) return
  const curPos = (e.target.scrollLeft || 0) + e.target.clientWidth
  const endPos = e.target.scrollWidth
  if (curPos + props.infiniteScrollOffset >= endPos) {
    scrollEventListenerTriggered.value = true
    emit('load')
    scrollEventListenerTriggered.value = false
  }
})

watchEffect(() => {
  if (props.items?.length && props.saveScrollPos) {
    try {
      loadPosition(props.store, scrollPosKey)
    } catch (e) {
      console.error(e)
    }
  }
})


return (_ctx: any,_cache: any) => {
  const _component_q_spinner_dots = _resolveComponent("q-spinner-dots")!
  const _component_q_skeleton = _resolveComponent("q-skeleton")!

  return (_ctx.$q.screen.xs || __props.onlyVertical )
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (!__props.loading && __props.items)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              ref_key: "mobileWrapperRef",
              ref: mobileWrapperRef,
              class: _normalizeClass([__props.wrapperClass, "c-mobile-wrapper flex no-wrap q-px-sm"]),
              style: _normalizeStyle(__props.wrapperStyle),
              onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(handleScroll) && _unref(handleScroll)(...args)))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: i,
                  class: _normalizeClass(__props.itemClass + (!__props.isMarginLeft && i === 0 ? ' q-ml-none' : ''))
                }, [
                  _createElementVNode("div", {
                    style: _normalizeStyle({width: __props.itemWidth, maxWidth: __props.itemMaxWidth, minWidth: __props.itemMinWidth, height: '100%'})
                  }, [
                    _renderSlot(_ctx.$slots, "default", {
                      item: item,
                      index: i
                    })
                  ], 4)
                ], 2))
              }), 128)),
              (__props.loadingMore)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _renderSlot(_ctx.$slots, "loadMoreWrapper", {}, () => [
                      _createElementVNode("div", {
                        style: _normalizeStyle({width: `calc(${__props.itemWidth} / 2)`}),
                        class: "full-height flex items-center justify-center"
                      }, [
                        _createVNode(_component_q_spinner_dots, { size: "5rem" })
                      ], 4)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ], 38))
          : _createCommentVNode("", true),
        (__props.loading)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["flex no-wrap q-px-sm", __props.wrapperClass ?? undefined]),
              style: {"overflow-x":"scroll"}
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.skeletonAmount, (i) => {
                return (_openBlock(), _createElementBlock("div", { key: i }, [
                  _createElementVNode("div", {
                    style: _normalizeStyle({width: __props.itemWidth})
                  }, [
                    _renderSlot(_ctx.$slots, "skeletonMobile", {}, () => [
                      _createVNode(_component_q_skeleton, {
                        width: __props.skeletonWidth,
                        height: __props.skeletonHeight
                      }, null, 8, ["width", "height"])
                    ])
                  ], 4),
                  _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1))
                ]))
              }), 128))
            ], 2))
          : _createCommentVNode("", true)
      ], 64))
    : (__props.items)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(__props.withContainer ? 'container' : '')
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([__props.wrapperClass, "row"]),
            style: _normalizeStyle(__props.wrapperStyle)
          }, [
            (!__props.loading)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(__props.items, (item, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: i,
                    class: _normalizeClass(`${__props.colClasses} ${__props.itemClass}`)
                  }, [
                    _renderSlot(_ctx.$slots, "default", {
                      index: i,
                      item: item
                    })
                  ], 2))
                }), 128))
              : _createCommentVNode("", true),
            (__props.loading)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(__props.skeletonAmount, (i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: i,
                    class: _normalizeClass(`${__props.colClasses} ${__props.itemClass}`)
                  }, [
                    _renderSlot(_ctx.$slots, "skeleton", {}, () => [
                      _createVNode(_component_q_skeleton, {
                        width: __props.skeletonWidth,
                        height: __props.skeletonHeight
                      }, null, 8, ["width", "height"])
                    ])
                  ], 2))
                }), 128))
              : _createCommentVNode("", true)
          ], 6)
        ], 2))
      : _createCommentVNode("", true)
}
}

})