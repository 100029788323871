import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, unref as _unref, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container q-py-md"
}
const _hoisted_2 = { class: "q-mb-lg" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  id: "images",
  class: "q-mb-lg"
}
const _hoisted_6 = { class: "q-my-sm" }
const _hoisted_7 = { class: "q-mb-lg" }
const _hoisted_8 = { class: "row q-col-gutter-md" }
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "col-12 col-sm-3" }
const _hoisted_12 = { class: "col-12 col-sm-4" }
const _hoisted_13 = {
  id: "category",
  class: "col-12 q-mb-md"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "q-mb-lg" }
const _hoisted_16 = { class: "q-card--bordered q-py-sm q-px-md rounded-borders inline-block" }
const _hoisted_17 = {
  key: 1,
  class: "text-center text-bold q-mb-sm"
}
const _hoisted_18 = {
  key: 2,
  class: "q-py-md"
}
const _hoisted_19 = { class: "col-12" }
const _hoisted_20 = { class: "col-12" }
const _hoisted_21 = {
  key: 0,
  class: "col-12 col-sm-3"
}
const _hoisted_22 = { class: "col-7" }
const _hoisted_23 = { class: "col-5" }
const _hoisted_24 = { class: "col-12" }
const _hoisted_25 = { class: "col-12" }
const _hoisted_26 = {
  key: 1,
  class: "col-12"
}
const _hoisted_27 = {
  key: 2,
  class: "col-12"
}
const _hoisted_28 = {
  id: "termsAccepted",
  class: "col-12 q-mb-lg"
}
const _hoisted_29 = { class: "col-12" }

import ImageUploader from 'components/imageUpload/ImageUploader.vue';
import {computed, onMounted, ref} from 'vue';
import DynamicInput from 'components/qutils/form/DynamicInput.vue';
import {Item} from 'src/models/market';
import {Loading, QSpinnerGears, useQuasar} from 'quasar';
import Market from 'src/api/market';
import CategorySelectDialog from 'src/apps/market/dialogs/CategorySelectDialog.vue';
import {useRoute, useRouter} from 'vue-router';
import deepmerge from 'deepmerge';


import {useMarketStore} from 'src/apps/market/store';

const __default__ = {
  async preFetch({store, currentRoute}) {
    const marketStore = useMarketStore(store)
    await marketStore.getItemOptions()

    const isUpdate = currentRoute.name === 'update-item'

    if (isUpdate) {
      const uuid = currentRoute.params.uuid.toString()
      try {
        await marketStore.fetchItem(uuid, false)
      } catch (err) {
        console.error(err)
      }
    }
    return
  }

}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'CreateUpdatePage',
  setup(__props) {

const errors = ref<any>({})
const marketStore = useMarketStore()
const isSubmitted = ref(true)
const images = ref([])
const termsAccepted = ref(false)
const formRef = ref(null)
const category = ref()
const $q = useQuasar()
const $route = useRoute()
const $router = useRouter()

const isUpdate = computed(() => {
  return $route.matched.some((route) => route.name === 'update-item')
})

const fields = computed<any>(() => {
  return marketStore?.itemOptions
})

function openCategorySelectDialog() {
  $q.dialog({
    component: CategorySelectDialog
  }).onOk((data) => {
    category.value = data
  })
}

const itemTypeChoices = computed(() => {
  return fields.value?.item_type?.choices?.map((choice) => {
    return {
      label: choice.display_name,
      value: choice.value
    }
  }) ?? []
})

const offerTypeChoices = computed(() => {
  return fields.value?.offer_type?.choices?.map((choice) => {
    return {
      label: choice.display_name,
      value: choice.value
    }
  }) ?? []
})

const formData = ref<Item | any>({
  address: {},
  item_type: itemTypeChoices.value.length ? itemTypeChoices.value[0].value : undefined,
  offer_type: offerTypeChoices.value.length ? offerTypeChoices.value[0].value : 'give_away',
  shipping: 'not_specified',
})

function onSubmit() {
  let error = false
  if (images.value.length === 0) {
    errors.value = {
      image_uuids: ['Du musst mindestens ein Bild hochladen.']
    }
    document.getElementById('images')?.scrollIntoView()
    error = true
  }

  if (!category.value || category.value?.id) {
    errors.value = {
      category_id: ['Bitte wähle eine Kategorie aus.']
    }
    document.getElementById('category')?.scrollIntoView()
    error = true
  }
  if (!termsAccepted.value) {

    errors.value = {
      termsAccepted: ['Bitte akzeptiere die Nutzungsbedingungen.']
    }
    document.getElementById('termsAccepted')?.scrollIntoView()
    error = true
  }

  if (error) {
    return
  }

  isSubmitted.value = true


  Loading.show({
    message: 'Anzeige wird erstellt...',
    spinner: QSpinnerGears,
  })

  if (isUpdate.value) {
    const uuid = $route.params.uuid.toString()

    Market.updateItem(uuid, {
      ...formData.value,
      image_uuids: images.value?.flatMap((image: any) => image.uuid),
      category_id: category.value?.length ? category.value[category.value.length - 1].id : undefined,
    }).then(() => {
      marketStore.fetchItem(uuid, false).finally(() => {
        $q.notify({
          message: 'Anzeige erfolgreich aktualisiert',
        })
        $router.push({
          name: 'item-detail',
          params: {uuid: uuid}
        })
      })
    }).catch(err => {
      if (err.response.data) {
        errors.value = err.response.data
      } else {
        console.error(err)
        return err
      }
    }).finally(() => {
      Loading.hide()
    })

  } else {
    Market.createItem({
      ...formData.value,
      image_uuids: images.value?.flatMap((image: any) => image.uuid),
      category_id: category.value.length ? category.value[category.value.length - 1].id : undefined,
    }).then((resp) => {
      $router.replace({name: 'create-item-finish', params: {uuid: resp.data.uuid}})
    }).catch((err) => {
      if (err.response.data) {
        errors.value = err.response.data
      } else {
        console.error(err)
        return err
      }
    }).finally(() => {
      Loading.hide()
    })
  }

}

const validatePrice = (val: string) => {
  const regex = /^(?!0\d)\d*$/;
  if (val) {
    return regex.test(val) || 'Bitte geben Sie einen gültigen Preis ein';
  }
}

function onChange(e) {
  console.log('change:images', e)
  // if (e.moved && isUpdate.value) {
  //   const uuid = $route.params.uuid.toString()
  //   const newIndex = e.moved.newIndex
  //   const oldIndex = e.moved.oldIndex
  //   const imageUuid = e.moved.element.uuid
  //   const _images = images.value
  //   console.log(newIndex, imageUuid)
  //   Market.setItemImagePostion(uuid, imageUuid, newIndex).catch(e => {
  //     console.error(e)
  //
  //     // reset positions
  //     const temp = images.value[newIndex];
  //     images.value[newIndex] = images.value[oldIndex];
  //     images.value[oldIndex] = temp;
  //   })
  // }
}

onMounted(() => {
  if (isUpdate.value) {
    const uuid = $route.params.uuid.toString()
    const item = marketStore.items[uuid]

    if (item) {
      formData.value = deepmerge(
        formData.value,
        item,
      )
      category.value = item.categories.length ? item.categories[0].ancestor_path : undefined
      images.value = item.images.length ? item.images.flatMap(img => {
        return {uuid: img.uuid, path: img.image.path}
      }) : []
    }
  }
})


return (_ctx: any,_cache: any) => {
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_option_group = _resolveComponent("q-option-group")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "footer__fix" }, {
    default: _withCtx(() => [
      (fields.value)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (!isUpdate.value)
                ? (_openBlock(), _createElementBlock("h2", _hoisted_3, "Anzeige aufgeben"))
                : (_openBlock(), _createElementBlock("h2", _hoisted_4, "Anzeige ändern"))
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[16] || (_cache[16] = _createElementVNode("h4", { class: "q-mb-md" }, " Bilder hochladen ", -1)),
              _createVNode(ImageUploader, {
                images: images.value,
                "onUpdate:images": [
                  _cache[0] || (_cache[0] = ($event: any) => ((images).value = $event)),
                  _cache[1] || (_cache[1] = ($event: any) => (errors.value.image_uuids = undefined))
                ],
                "aspect-ratio": 3/4,
                "cropper-aspect-ratio": 3/4,
                "onChange:images": onChange
              }, null, 8, ["images"]),
              _createElementVNode("div", _hoisted_6, [
                (errors.value?.image_uuids)
                  ? (_openBlock(), _createBlock(_component_q_banner, {
                      key: 0,
                      rounded: "",
                      dense: "",
                      class: "bg-negative text-white q-px-md"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(errors.value?.image_uuids?.join(' ')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createVNode(_component_q_form, {
              ref_key: "formRef",
              ref: formRef,
              autocomplete: "off",
              onSubmit: _withModifiers(onSubmit, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_q_option_group, {
                        modelValue: formData.value.item_type,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.value.item_type) = $event)),
                        options: itemTypeChoices.value,
                        color: "primary",
                        inline: ""
                      }, null, 8, ["modelValue", "options"])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_q_option_group, {
                        modelValue: formData.value.offer_type,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData.value.offer_type) = $event)),
                        options: offerTypeChoices.value,
                        color: "primary",
                        inline: ""
                      }, null, 8, ["modelValue", "options"])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.price,
                        "onUpdate:modelValue": [
                          _cache[4] || (_cache[4] = ($event: any) => ((formData.value.price) = $event)),
                          _cache[5] || (_cache[5] = ($event: any) => (errors.value.price = undefined))
                        ],
                        outlined: "",
                        disable: formData.value.offer_type === 'give_away',
                        field: fields.value.price,
                        errors: errors.value?.price,
                        type: "text",
                        rules: [validatePrice],
                        suffix: ",00 €",
                        "hide-hint": false,
                        "input-class": "text-right",
                        placeholder: "0",
                        "stack-label": ""
                      }, null, 8, ["modelValue", "disable", "field", "errors", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.is_negotiable,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((formData.value.is_negotiable) = $event)),
                        field: fields.value.is_negotiable,
                        "no-indeterminate": true
                      }, null, 8, ["modelValue", "field"]),
                      (errors.value.is_negotiable)
                        ? (_openBlock(), _createBlock(_component_q_banner, {
                            key: 0,
                            rounded: "",
                            dense: "",
                            class: "bg-negative text-white q-px-md"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(errors.value.is_negotiable.join(' ')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      (category.value?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createElementVNode("div", _hoisted_15, [
                              _cache[17] || (_cache[17] = _createElementVNode("div", { class: "q-mb-sm" }, "Ausgewählte Kategorie", -1)),
                              _createElementVNode("div", _hoisted_16, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.value, (cat, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: cat.id,
                                    style: {"display":"inline"}
                                  }, [
                                    _createElementVNode("b", null, _toDisplayString(cat.name), 1),
                                    (index + 1 < category.value.length)
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                          _createTextVNode(" >")
                                        ], 64))
                                      : _createCommentVNode("", true)
                                  ]))
                                }), 128))
                              ])
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_17, " Keine Kategorie ausgewählt ")),
                      _createElementVNode("div", null, [
                        _createVNode(_component_q_btn, {
                          class: _normalizeClass([{'full-width': _unref($q).screen.lt.sm}, "q-py-sm"]),
                          unelevated: "",
                          "no-caps": "",
                          color: "dark",
                          rounded: "",
                          onClick: _cache[7] || (_cache[7] = ($event: any) => {openCategorySelectDialog(); errors.value.category_id = undefined})
                        }, {
                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createTextVNode(" Kategorie auswählen ")
                          ])),
                          _: 1
                        }, 8, ["class"])
                      ]),
                      (errors.value.category_id)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                            _createVNode(_component_q_banner, {
                              rounded: "",
                              dense: "",
                              class: "bg-negative text-white q-px-md"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(errors.value.category_id.join(' ')), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.title,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((formData.value.title) = $event)),
                        outlined: "",
                        field: fields.value.title,
                        errors: errors.value?.title
                      }, null, 8, ["modelValue", "field", "errors"])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.description,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((formData.value.description) = $event)),
                        outlined: "",
                        type: "textarea",
                        autogrow: "",
                        field: fields.value.description,
                        errors: errors.value?.description,
                        "input-style": {minHeight: '150px'}
                      }, null, 8, ["modelValue", "field", "errors"])
                    ]),
                    (formData.value.item_type === 'offer')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          _createVNode(DynamicInput, {
                            modelValue: formData.value.shipping,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((formData.value.shipping) = $event)),
                            outlined: "",
                            field: fields.value.shipping,
                            errors: errors.value?.shipping,
                            "emit-value": "",
                            "map-options": ""
                          }, null, 8, ["modelValue", "field", "errors"])
                        ]))
                      : _createCommentVNode("", true),
                    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "col-12" }, [
                      _createElementVNode("h4", null, "Ort")
                    ], -1)),
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.address.city,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((formData.value.address.city) = $event)),
                        field: fields.value?.address?.children.city,
                        errors: errors.value.address?.city,
                        outlined: ""
                      }, null, 8, ["modelValue", "field", "errors"])
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.address.zip_code,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((formData.value.address.zip_code) = $event)),
                        field: fields.value?.address?.children.zip_code,
                        errors: errors.value.address?.zip_code,
                        outlined: ""
                      }, null, 8, ["modelValue", "field", "errors"])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.address.street_and_number,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((formData.value.address.street_and_number) = $event)),
                        field: fields.value?.address?.children.street_and_number,
                        errors: errors.value.address?.street_and_number,
                        outlined: ""
                      }, null, 8, ["modelValue", "field", "errors"])
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.address.country,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((formData.value.address.country) = $event)),
                        field: fields.value?.address?.children.country,
                        errors: errors.value.address?.country,
                        outlined: ""
                      }, null, 8, ["modelValue", "field", "errors"])
                    ]),
                    (errors.value?.address?.non_field_errors)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                          _createVNode(_component_q_banner, {
                            class: "bg-negative text-white",
                            rounded: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(errors.value.address.non_field_errors.join('. ')), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (errors.value?.non_field_errors)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                          _createVNode(_component_q_banner, {
                            class: "bg-negative text-white",
                            rounded: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(errors.value.non_field_errors.join('. ')), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(_component_q_checkbox, {
                        modelValue: termsAccepted.value,
                        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((termsAccepted).value = $event)),
                        label: "Nutzungsbedindungen",
                        color: "primary"
                      }, null, 8, ["modelValue"]),
                      (errors.value.termsAccepted)
                        ? (_openBlock(), _createBlock(_component_q_banner, {
                            key: 0,
                            rounded: "",
                            dense: "",
                            class: "bg-negative text-white q-px-md"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(errors.value.termsAccepted.join(' ')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_component_q_btn, {
                        class: _normalizeClass({'full-width': _unref($q).screen.lt.sm}),
                        rounded: "",
                        type: "submit",
                        "no-caps": "",
                        unelevated: "",
                        color: "dark",
                        size: "lg"
                      }, {
                        default: _withCtx(() => _cache[19] || (_cache[19] = [
                          _createTextVNode(" Anzeige aufgeben ")
                        ])),
                        _: 1
                      }, 8, ["class"])
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 512)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})