import {defineStore} from 'pinia';

export const usePersistentSessionStore = defineStore('persistentSession', {
  state: () => ({
  }),
  actions: {
  }
})

export const usePersistentLocalStore = defineStore('persistentLocal', {
  state: () => ({
    favoritesTab: 'item' as 'item' | 'advert',
    profileTab: 'item' as 'item' | 'advert',
  }),
  actions: {
    updateFavoritesTab(val) {
      this.favoritesTab = val;
    },
    updateProfileTab(val) {
      this.profileTab = val;
    },
  }
})
