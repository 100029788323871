import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "flex justify-center no-wrap",
  style: {"white-space":"nowrap"}
}
const _hoisted_2 = { class: "flex column q-gutter-y-sm items-center" }
const _hoisted_3 = { style: {"line-height":"0.625rem","font-size":"0.7rem"} }

import {useRoute} from 'vue-router';
import {computed} from 'vue';
import {useMainStore} from 'stores/main';
import {getAccountPageParams} from 'src/etc/helper';
import {useQuasar} from 'quasar';
import SwitchAccountDialog from 'src/apps/auth/components/dialogs/SwitchAccountDialog.vue';
import {openCreateChooserDialog} from 'src/apps/core/utils';

interface MenuItem {
  key: string,
  label?: string,
  icon?: string,
  extraData?: any,
  to?: object | undefined | string,
  click?: any,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PageFooter',
  setup(__props) {

const route = useRoute()
const mainStore = useMainStore()
const $q = useQuasar()

function isCurrentPath(item) {
  if (!item?.to?.name) return false
  return route.name === item.to.name
}

const menuCmp = computed((): Array<MenuItem> => {
  if (!mainStore.isLoggedIn) {
    return menu
  }

  let _menu = menuLoggedIn
  if (mainStore.accountDetail?.active_account.avatar?.path) {
    _menu = _menu.filter(obj => obj.key !== 'profile')
    const avatarPath = mainStore.accountDetail.active_account.avatar.path
    _menu = [
      ..._menu,
      {key: 'profile', label: 'Profil', extraData: {avatar: avatarPath}, to: getAccountPageParams(mainStore)},
    ]
  }

  if (mainStore.messagesUnread) {
    _menu = _menu.map((obj) => {
      if (obj.key === 'messenger') {
        return {...obj, extraData: {count: mainStore.messagesUnread}}
      }
      return obj
    })

  }

  return _menu
})

function openChooser() {
  openCreateChooserDialog($q, mainStore)
}

const menu: Array<MenuItem> = [
  {key: 'explore', label: 'Erkunden', icon: 'fas fa-search', to: {name: 'search'}},
  {key: 'favorites', label: 'Merkliste', icon: 'far fa-bookmark', to: {name: 'favorites'}},
  {key: 'createAdvert', label: 'Verkaufen', icon: 'far fa-plus-square', click: openChooser},
  {key: 'login', label: 'Login', icon: 'far fa-circle-user', to: {name: 'login'}},
]

if (process.env.DEV) {
  // menu.push({key: 'dev', label: 'Dev', icon: 'fas fa-cogs', to: {name: 'developer'}},)
}

const menuLoggedIn: Array<MenuItem> = [
  {key: 'explore', label: 'Erkunden', icon: 'fas fa-search', to: {name: 'search'}},
  {key: 'favorites', label: 'Merkliste', icon: 'far fa-bookmark', to: {name: 'favorites'}},
  {key: 'createAdvert', label: 'Verkaufen', icon: 'far fa-plus-square', click: openChooser},
  {key: 'messenger', label: 'Nachrichten', icon: 'far fa-comments', to: {name: 'messenger'}},
  {key: 'profile', label: 'Profil', icon: 'far fa-circle-user'},
]

function holdClick() {
  $q.dialog({
    component: SwitchAccountDialog,
  })
}

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_footer = _resolveComponent("q-footer")!
  const _directive_touch_hold = _resolveDirective("touch-hold")!

  return (_openBlock(), _createBlock(_component_q_footer, {
    id: "c-footer-menu",
    reveal: "",
    bordered: "",
    class: "q-ma-none text-grey-7 bg-white",
    "height-hint": 60,
    style: {"z-index":"2001"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_toolbar, { class: "q-pa-none" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar_title, null, {
            default: _withCtx(() => [
              _createElementVNode("nav", null, [
                _createElementVNode("div", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuCmp.value, (item, i) => {
                    return _withDirectives((_openBlock(), _createBlock(_component_q_btn, {
                      key: i,
                      style: {"width":"clamp(50px, 75px , 20%)"},
                      to: item.to,
                      "no-caps": "",
                      flat: "",
                      draggable: false,
                      class: _normalizeClass([{'text-tertiary': isCurrentPath(item)}, "text-center q-py-sm disable-long-press"]),
                      onClick: () => item.click ? item.click() : undefined,
                      onContextmenu: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          (item?.icon)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createVNode(_component_q_icon, {
                                  name: `${item.icon}`
                                }, null, 8, ["name"]),
                                (item.extraData?.count)
                                  ? (_openBlock(), _createBlock(_component_q_badge, {
                                      key: 0,
                                      rounded: "",
                                      color: "negative",
                                      floating: ""
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.extraData?.count), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true)
                              ], 64))
                            : _createCommentVNode("", true),
                          (item.key === 'profile')
                            ? (_openBlock(), _createBlock(_component_q_avatar, {
                                key: 1,
                                class: "bg-grey-3",
                                size: "24px"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_img, {
                                    "no-spinner": "",
                                    "no-transition": "",
                                    src: item.extraData?.avatar,
                                    alt: "User avatar"
                                  }, null, 8, ["src"])
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_3, _toDisplayString(item.label), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["to", "class", "onClick"])), [
                      [
                        _directive_touch_hold,
                        item.key === 'profile' ? holdClick : undefined,
                        void 0,
                        { capture: true }
                      ]
                    ])
                  }), 128))
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})