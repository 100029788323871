<template>
  <q-dialog
    ref="dialogRef"
    :maximized="$q.screen.lt.sm"
    transition-show="slide-up"
    transition-hide="slide-down"
    @hide="onDialogHide"
  >
    <base-dialog-content content-classes="q-pa-none">
      <template v-if="navigationStack?.length !== 0" #header>
        <div class="flex no-wrap items-center">
          <q-btn
            v-if="navigationStack?.length !== 0"
            :icon="'fas fa-arrow-left'"
            round
            color="white"
            text-color="dark"
            aria-label="close"
            style="margin-left: -8px"
            unelevated
            @click="goBack"
          />

          <div>
            <h4>{{ currentCategory?.name }}</h4>
          </div>
        </div>
      </template>

      <template #default>
        <template v-if="loading">
          <div class="relative-position full-height" style="min-height: 250px">
            <div class="absolute-center">
              <q-spinner size="xl"></q-spinner>
            </div>
          </div>
        </template>
        <template v-else>
          <q-list v-if="categories" separator>
            <q-item
              v-for="category in navigationStack.length ? currentCategory.children : categories"
              :key="category.id"
              v-ripple
              clickable
              class="category-item"
              :class="{'selected': category?.id && selectedCategories.includes(category)}"
              @click="selectCategory(category)"
            >
              <q-item-section>
                <q-item-label>
                  <div class="flex no-wrap items-center" style="column-gap: 12px">
                    <div>
                      {{ category.name }}
                    </div>
                    <div v-if="category.children?.length">
                      <q-icon name="fas fa-caret-right"/>
                    </div>
                    <div v-if="category?.id && selectedCategories.includes(category)">
                      <q-icon size="sm" name="fas fa-square-check"/>
                    </div>
                  </div>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>

        </template>
      </template>

      <template #footer>
        <div v-if="services?.length" class="q-mb-md">
          <q-chip v-for="cat in selectedCategories" :key="cat.id" removable @remove="remove(cat)">
            {{ cat.ancestor_path.flatMap(obj => obj.name).join(' > ') }}
          </q-chip>
        </div>
        <div class="flex no-wrap justify-end" style="column-gap: 12px">
          <q-btn v-close-popup unelevated no-caps>
            Abbrechen
          </q-btn>
          <q-btn unelevated no-caps color="dark" @click="onOKClick">
            Ok
          </q-btn>
        </div>
      </template>
    </base-dialog-content>
  </q-dialog>
</template>

<script setup>
import {useDialogPluginComponent} from 'quasar'
import BaseDialogContent from 'src/apps/core/components/BaseDialogContent.vue';
import {onMounted, ref} from 'vue';
import {useMainStore} from 'stores/main';
import {toggleList} from 'src/apps/core/utils';

const categories = ref()
const currentCategory = ref()
const selectedCategories = ref([])
const navigationStack = ref([])
const loading = ref(true)
const mainStore = useMainStore()

const props = defineProps({
  selected: {type: Array, default: () => []},
  showAvailable: {type: Boolean, default: false},
})


const selectCategory = (category) => {
  if (category.children && category.children.length) {
    navigationStack.value.push(category)
    currentCategory.value = category
  } else {
    toggleList(category, selectedCategories.value)
  }
}

const goBack = () => {
  if (navigationStack.value.length) {
    currentCategory.value = navigationStack.value.pop()
  } else {
    currentCategory.value = undefined
  }
}

function fetchCategories() {
  let fetchFn = mainStore.fetchBusinessServices
  if (props.showAvailable) {
    fetchFn = mainStore.fetchBusinessServicesAvailable
  }
  fetchFn().then(({data}) => {
    categories.value = data
    loading.value = false
  })
}

defineEmits([
  ...useDialogPluginComponent.emits
])

onMounted(() => {
  fetchCategories()
  selectedCategories.value = [...props.selected]
})

const {dialogRef, onDialogHide, onDialogOK} = useDialogPluginComponent()

function onOKClick() {
  onDialogOK(selectedCategories.value)
}

function remove(category) {
  toggleList(category, selectedCategories.value)
}
</script>

<style scoped lang="scss">
.category-item {
  &.selected {
    background-color: $grey-3;
  }
}
</style>
