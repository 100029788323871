import script from "./DownloadAppNotification.vue?vue&type=script&setup=true&lang=ts"
export * from "./DownloadAppNotification.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QHeader from 'quasar/src/components/header/QHeader.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QHeader,QBtn});
