import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"margin":"6rem 0"} }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "q-my-xl flex justify-center items-center" }
const _hoisted_4 = {
  key: 0,
  class: "container"
}
const _hoisted_5 = { class: "flex column q-gutter-y-md" }
const _hoisted_6 = {
  class: "text-h3 font-inter-bold q-mb-md text-primary",
  style: {"white-space":"pre-line"}
}
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_secure_email = _resolveComponent("secure-email")!
  const _component_fade_out_text = _resolveComponent("fade-out-text")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-my-lg" }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-h1 font-inter-bold" }, "Brauchst du Hilfe?", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_icon, {
              class: "q-mr-md",
              name: "fas fa-envelope",
              size: "lg"
            }),
            _createVNode(_component_secure_email, {
              email: "kontakt@campertrader.de",
              style: {"font-size":"clamp(1rem, 5vw, 2rem)"}
            })
          ])
        ])
      ]),
      (_ctx.faq)
        ? (_openBlock(), _createElementBlock("section", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faq, (item) => {
                return (_openBlock(), _createBlock(_component_q_card, {
                  key: item.question,
                  bordered: "",
                  flat: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_card_section, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(item.question), 1),
                        _createVNode(_component_fade_out_text, {
                          "max-height": 100,
                          trashhold: 0
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", {
                              class: "vhtml-container",
                              innerHTML: item.answer
                            }, null, 8, _hoisted_7)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}