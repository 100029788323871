import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

import PageFooter from 'src/apps/core/components/PageFooter.vue';
import HeaderToolbar from 'components/HeaderToolbar.vue';
import FooterBar from 'components/FooterBar.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppMainLayout',
  props: {
  noHeader: {type: Boolean, default: false},
  noContainer: {type: Boolean, default: false},
  customClass: {required: false, type: Object},
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_q_header = _resolveComponent("q-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "hhh lpR fff" }, {
    default: _withCtx(() => [
      (!_ctx.$route.meta.noHeader)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.$q.screen.lt.sm)
              ? (_openBlock(), _createBlock(_component_q_header, {
                  key: 0,
                  class: "bg-white text-dark",
                  "height-hint": "50"
                }, {
                  default: _withCtx(() => [
                    _createVNode(HeaderToolbar, {
                      class: _normalizeClass(["bg-white", {container: !__props.noContainer, ...__props.customClass}])
                    }, null, 8, ["class"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_q_page_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      (!_ctx.$route.meta.noFooter && !_ctx.$q.platform.is.nativeMobile)
        ? (_openBlock(), _createBlock(FooterBar, {
            key: 1,
            class: _normalizeClass({'q-pb-xl': _ctx.$q.screen.lt.sm})
          }, null, 8, ["class"]))
        : _createCommentVNode("", true),
      _createVNode(_component_q_no_ssr, null, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(PageFooter, null, null, 512), [
            [_vShow, !_ctx.$route.meta.noFooterBar && _ctx.$q.screen.lt.sm && _ctx.$q.screen.width > 0]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})