import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    class: _normalizeClass(["c-btn--special", {'q-py-md': _ctx.big}]),
    unelevated: "",
    loading: _ctx.loading,
    "no-caps": "",
    onClick: _ctx.click
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_ctx.hasIcon)
          ? (_openBlock(), _createBlock(_component_q_icon, {
              key: 0,
              name: "fas fa-envelope",
              size: "xs"
            }))
          : _createCommentVNode("", true),
        (_ctx.hasIcon && _ctx.hasLabel)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode("   ")
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.hasLabel)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["class", "loading", "onClick"]))
}