import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "sticky bg-white" }
const _hoisted_2 = { class: "flex column" }
const _hoisted_3 = { class: "c-search-header bg-white" }
const _hoisted_4 = { class: "flex no-wrap items-stretch c-shadow" }
const _hoisted_5 = {
  key: 0,
  style: {"display":"inline-flex"},
  class: "relative-position"
}
const _hoisted_6 = {
  key: 0,
  style: {"font-size":"1rem"}
}
const _hoisted_7 = {
  style: {"flex-grow":"1"},
  class: "overflow-hidden"
}

import {computed, inject, ref} from 'vue';
import {useQuasar} from 'quasar';
import SearchFilterDialog from 'src/apps/search/components/dialogs/FilterSelectionDialog.vue';
import CategoryScrollWrapper from 'src/apps/search/components/CategoryScrollWrapper.vue';
import {SearchInject, searchSymbol} from 'src/apps/core/injectionKeys';
import {useSearchStore} from 'src/apps/search/store';
import HeaderToolbar from 'components/HeaderToolbar.vue';
import SearchTypeSwitch from 'src/apps/search/components/SearchTypeSwitch.vue';
import {searchFilterComponentMapping} from 'src/apps/search/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchWrapper',
  props: {
  showCategories: {
    type: Boolean,
    default: true
  },
},
  setup(__props) {

const $q = useQuasar()




const searchStore = useSearchStore()


const {
  searchType,
  updateSearchType,
} = inject(searchSymbol) as SearchInject

const filterActiveCount = computed(() => {
  const excludedKeys = ['vehicle__car_type', 'page', 'r', 'ordering']
  let activeFiltersCount = Object.keys(searchStore.filterData).filter(key => {
    if (excludedKeys.includes(key)) return false;
    const value = searchStore.filterData[key];
    if (value === undefined || value === null) return false;
    if (Array.isArray(value) && value.length === 0) return false;
    if (typeof value === 'object' && Object.keys(value).length === 0) return false;
    return true;
  }).length

  if (searchStore.filterData?.services?.length) {
    activeFiltersCount += searchStore.filterData.services.length - 1
  }
  return activeFiltersCount
})


const content = ref<HTMLElement | null>(null);

function openFilterDialog() {
  $q.dialog({
    component: SearchFilterDialog,

    // props forwarded to your custom component
    componentProps: {
      modelValue: searchStore.filterData,
      searchResultCount: searchStore.searchResultCount,
    }
  }).onOk((payload) => {
    if (payload?.action === 'reset') {
      searchStore.resetFilterData()
    }
  })
}



return (_ctx: any,_cache: any) => {
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_no_ssr = _resolveComponent("q-no-ssr")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (!_unref($q).screen.lt.sm)
        ? (_openBlock(), _createBlock(HeaderToolbar, {
            key: 0,
            tag: "div",
            class: "container",
            style: {"position":"unset"}
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          (!_unref($q).screen.lt.sm)
            ? (_openBlock(), _createBlock(_component_q_separator, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "",
            style: _normalizeStyle([{"margin":"auto"}, {maxWidth: !_unref($q).screen.lt.sm ? '600px' : undefined}])
          }, [
            _createVNode(SearchTypeSwitch, {
              style: {"width":"100%"},
              "model-value": _unref(searchType),
              "onUpdate:modelValue": _unref(updateSearchType)
            }, null, 8, ["model-value", "onUpdate:modelValue"])
          ], 4)
        ])
      ])
    ]),
    _createVNode(_component_q_separator),
    _createElementVNode("div", _hoisted_4, [
      (_unref(searchType) && _unref(searchFilterComponentMapping)[_unref(searchType)])
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_q_btn, {
              color: "dark",
              "no-wrap": "",
              flat: "",
              stretch: "",
              padding: "10px 10px",
              size: "lg",
              "no-caps": "",
              onClick: openFilterDialog
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_icon, { name: "tune" }),
                (!_unref($q).screen.lt.sm)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "  Filter"))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_q_no_ssr, null, {
              default: _withCtx(() => [
                (filterActiveCount.value)
                  ? (_openBlock(), _createBlock(_component_q_badge, {
                      key: 0,
                      rounded: "",
                      color: "dark",
                      "text-color": "white",
                      floating: "",
                      style: {"z-index":"9999"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(filterActiveCount.value), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_q_separator, { vertical: "" })
          ]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", _hoisted_7, [
        _withDirectives(_createElementVNode("div", {
          ref_key: "content",
          ref: content,
          class: "full-height content relative-position"
        }, [
          _createVNode(CategoryScrollWrapper)
        ], 512), [
          [_vShow, __props.showCategories]
        ])
      ], 512), [
        [_vShow, __props.showCategories]
      ])
    ])
  ]))
}
}

})