import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row q-col-gutter-md" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = {
  key: 0,
  class: "col-12"
}
const _hoisted_4 = { class: "text-bold" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "q-my-sm flex column q-gutter-y-lg" }
const _hoisted_7 = { class: "row q-col-gutter-md items-start" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "row q-col-gutter-sm" }
const _hoisted_10 = { class: "col-12 col-md-6" }
const _hoisted_11 = { class: "col-12 col-md-6" }
const _hoisted_12 = {
  key: 0,
  class: "col-6"
}
const _hoisted_13 = {
  key: 1,
  class: "col-6"
}
const _hoisted_14 = {
  key: 2,
  class: "col-12"
}
const _hoisted_15 = {
  key: 3,
  class: "col-12"
}
const _hoisted_16 = {
  key: 4,
  class: "col-12"
}
const _hoisted_17 = {
  key: 5,
  class: "col-12"
}
const _hoisted_18 = {
  key: 6,
  class: "col-12"
}
const _hoisted_19 = { class: "col-2 col-md-1" }

import {computed, ref, watch} from 'vue';
import {debounce} from 'quasar';
import {findExtrasInText} from 'src/etc/utils';
import {useMainStore} from 'stores/main';
import {Extra} from 'src/models/vehicle';
import VehicleExtraChip from 'src/components/etc/VehicleExtraChip.vue';
import DynamicInput from 'components/qutils/form/DynamicInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ExtrasStep',
  props: {
  modelValue: {
    required: true,
  },
  errors: {
    type: Object,
    required: false,
  },
  fields: {
    default: {},
  },
},
  emits: ['update:modelValue', 'extrasFoundInText'],
  setup(__props, { emit: __emit }) {

const mainStore = useMainStore();
const emit = __emit;
const props = __props;

const extraOptions = ref<Array<object>>([]);
const extrasFoundInText = ref<Array<Extra> | undefined>();
const extras = ref<Array<object>>([
  {
    slug: undefined,
    description: undefined,
  },
]);

const getFieldOptions = () => {
  let extra = mainStore.vehicleExtras;
  extraOptions.value = extra.flatMap((obj) => {
    return {
      label: `${obj['icon']} ${obj['name']}`,
      value: obj['value'],
    };
  });
};

watch(
  () => props.modelValue,
  () => {
    getFieldOptions();
    if ((props.modelValue as any)?.vehicle?.extras?.length) {
      extras.value = (props.modelValue as any)?.vehicle?.extras;
    }
  },
  {deep: true, immediate: true}
);

watch(
  () => extras.value,
  (val) => {
    formData.value.vehicle.extras = val;
  },
  {deep: true}
);

const formData = computed({
  // getter
  get() {
    return props.modelValue as any;
  },
  // setter
  set(newValue: object) {
    emit('update:modelValue', newValue);
  },
}) as any;

const removeExtra = function (i) {
  extras.value.splice(i, 1);
};

const addToExtras = function ({slug, description}) {
  if (!extras.value) {
    extras.value = [];
  }

  if (slug && extras.value.filter((obj) => obj['slug'] === slug).length) return;

  extras.value.push({
    slug,
    description,
  });
};

const addExtra = function () {
  addToExtras({slug: undefined, description: undefined});
};

const onTextChange = debounce((val) => {
  findExtrasInText(val, mainStore.vehicleExtras).then((val) => {
    extrasFoundInText.value = val.filter((obj) => {
      return !extras.value
        .flatMap((extra) => extra['slug'])
        .includes(obj['value']);
    });
    emit('extrasFoundInText', extrasFoundInText.value);
  });
}, 300);

watch(
  () => extras.value,
  () => {
    onTextChange(formData.value.description);
  },
  {deep: true}
);

const getFilteredExtraOptions = computed(() => {
  let usedSlugs = extras.value.flatMap((obj) => {
    return obj['slug'] ? obj['slug'] : undefined;
  });

  return extraOptions.value.map((obj) => {
    obj['disable'] = usedSlugs.includes(obj['value']);
    return obj;
  });
});

return (_ctx: any,_cache: any) => {
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(DynamicInput, {
          modelValue: formData.value.description,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((formData.value.description) = $event)),
            _unref(onTextChange)
          ],
          errors: __props.errors?.description,
          field: __props.fields.description,
          counter: "",
          outlined: "",
          rows: "10",
          type: "textarea"
        }, null, 8, ["modelValue", "errors", "field", "onUpdate:modelValue"])
      ]),
      (extrasFoundInText.value?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_q_banner, {
              class: "bg-warning",
              rounded: ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _cache[9] || (_cache[9] = _createTextVNode(" Du hast in deiner Beschreibung ")),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(extrasFoundInText.value.map((obj) => obj.name).join(', ')), 1),
                  _cache[10] || (_cache[10] = _createTextVNode(" verwendet. "))
                ]),
                _cache[11] || (_cache[11] = _createElementVNode("div", null, " Damit deine Anzeige über die Suche gefunden werden kann solltest du diese Info als \"Ausstattung\" angeben. ", -1)),
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(extrasFoundInText.value, (extra) => {
                    return (_openBlock(), _createBlock(VehicleExtraChip, {
                      key: extra.value,
                      "model-value": extra as any,
                      "icon-right": "fas fa-plus",
                      onClick: ($event: any) => (addToExtras({ slug: extra.value, description: '' }))
                    }, null, 8, ["model-value", "onClick"]))
                  }), 128))
                ])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "q-mt-lg" }, [
          _createElementVNode("p")
        ], -1)),
        _createVNode(DynamicInput, {
          modelValue: formData.value.youtube_link,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.youtube_link) = $event)),
          modelModifiers: { lazy: true },
          errors: __props.errors.youtube_link,
          field: __props.fields.youtube_link,
          hint: "Biete deinen Interessenten einen virtuellen Rundgang an indem du ein Video von deinem Fahrzeug machst.",
          label: "Youtube Link",
          outlined: ""
        }, null, 8, ["modelValue", "errors", "field"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(extras.value, (extra, i) => {
        return (_openBlock(), _createElementBlock("div", { key: i }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_q_select, {
                    modelValue: extras.value[i].slug,
                    "onUpdate:modelValue": ($event: any) => ((extras.value[i].slug) = $event),
                    modelModifiers: { lazy: true },
                    error: __props.errors?.vehicle?.extras?.length ? !!__props.errors?.vehicle?.extras[i]?.slug : undefined,
                    "error-message": __props.errors?.vehicle?.extras?.length ? __props.errors?.vehicle?.extras[i]?.slug.join(' ') : undefined,
                    options: getFilteredExtraOptions.value,
                    "emit-value": "",
                    label: "Ausstattung",
                    "map-options": "",
                    outlined: ""
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "error", "error-message", "options"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_q_input, {
                    modelValue: extras.value[i].description,
                    "onUpdate:modelValue": ($event: any) => ((extras.value[i].description) = $event),
                    modelModifiers: { lazy: true },
                    label: "Kurze Beschreibung",
                    maxlength: "255",
                    outlined: "",
                    type: "text"
                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                ]),
                (extra.slug === 'water_tank')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.vehicle.figures.clean_water_tank_in_l,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.value.vehicle.figures.clean_water_tank_in_l) = $event)),
                        modelModifiers: { lazy: true },
                        errors: __props.errors.vehicle?.figures?.clean_water_tank_in_l,
                        field: 
                  __props.fields.vehicle.children.figures.children.clean_water_tank_in_l
                ,
                        outlined: "",
                        suffix: "l",
                        type: "number"
                      }, null, 8, ["modelValue", "errors", "field"])
                    ]))
                  : _createCommentVNode("", true),
                (extra.slug === 'water_tank')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.vehicle.figures.greywater_tank_in_l,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData.value.vehicle.figures.greywater_tank_in_l) = $event)),
                        modelModifiers: { lazy: true },
                        errors: __props.errors.vehicle?.figures?.greywater_tank_in_l,
                        field: 
                  __props.fields.vehicle.children.figures.children.greywater_tank_in_l
                ,
                        outlined: "",
                        suffix: "l",
                        type: "number"
                      }, null, 8, ["modelValue", "errors", "field"])
                    ]))
                  : _createCommentVNode("", true),
                (extra.slug === 'solar')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.vehicle.figures.solar_in_w,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formData.value.vehicle.figures.solar_in_w) = $event)),
                        modelModifiers: { lazy: true },
                        errors: __props.errors.vehicle?.figures?.solar_in_w,
                        field: __props.fields.vehicle.children.figures.children.solar_in_w,
                        outlined: "",
                        suffix: "W",
                        type: "number"
                      }, null, 8, ["modelValue", "errors", "field"])
                    ]))
                  : _createCommentVNode("", true),
                (extra.slug === 'gas_tank')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.vehicle.figures.gas_vol_in_l,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((formData.value.vehicle.figures.gas_vol_in_l) = $event)),
                        modelModifiers: { lazy: true },
                        errors: __props.errors.vehicle?.figures?.gas_vol_in_l,
                        field: __props.fields.vehicle.children.figures.children.gas_vol_in_l,
                        outlined: "",
                        suffix: "l",
                        type: "number"
                      }, null, 8, ["modelValue", "errors", "field"])
                    ]))
                  : _createCommentVNode("", true),
                (extra.slug === 'inverter')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.vehicle.figures.inverter_in_w,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((formData.value.vehicle.figures.inverter_in_w) = $event)),
                        modelModifiers: { lazy: true },
                        errors: __props.errors.vehicle?.figures?.inverter_in_w,
                        field: __props.fields.vehicle.children.figures.children.inverter_in_w,
                        outlined: "",
                        suffix: "W",
                        type: "number"
                      }, null, 8, ["modelValue", "errors", "field"])
                    ]))
                  : _createCommentVNode("", true),
                (extra.slug === 'battery')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.vehicle.figures.battery_capacity_in_ah,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((formData.value.vehicle.figures.battery_capacity_in_ah) = $event)),
                        modelModifiers: { lazy: true },
                        errors: __props.errors.vehicle?.figures?.battery_capacity_in_ah,
                        field: 
                  __props.fields.vehicle.children.figures.children
                    .battery_capacity_in_ah
                ,
                        outlined: "",
                        suffix: "Ah",
                        type: "number"
                      }, null, 8, ["modelValue", "errors", "field"])
                    ]))
                  : _createCommentVNode("", true),
                (extra.slug === 'hot_water')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createVNode(DynamicInput, {
                        modelValue: formData.value.vehicle.figures.boiler_vol_in_l,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((formData.value.vehicle.figures.boiler_vol_in_l) = $event)),
                        modelModifiers: { lazy: true },
                        errors: __props.errors.vehicle?.figures?.boiler_vol_in_l,
                        field: 
                  __props.fields.vehicle.children.figures.children.boiler_vol_in_l
                ,
                        outlined: "",
                        suffix: "l",
                        type: "number"
                      }, null, 8, ["modelValue", "errors", "field"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_q_btn, {
                class: "q-mt-sm",
                color: "negative",
                flat: "",
                round: "",
                onClick: ($event: any) => (removeExtra(i))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_icon, {
                    color: "negative",
                    size: "lg",
                    name: "remove"
                  })
                ]),
                _: 2
              }, 1032, ["onClick"])
            ])
          ])
        ]))
      }), 128)),
      _createElementVNode("div", null, [
        _createVNode(_component_q_btn, {
          "no-caps": "",
          outline: "",
          onClick: addExtra
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: "fas fa-plus",
              size: ".5rem"
            }),
            _cache[13] || (_cache[13] = _createTextVNode("  Weitere hinzufügen "))
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}
}

})