import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "c-container" }
const _hoisted_2 = { class: "c-active-filter" }

import {useMainStore} from 'stores/main';
import {computed, toRef} from 'vue';
import {getFilterItems} from 'src/apps/search/helpers';

/**
 * Shows QChips for all active filters
 * @displayName Active Filter Badges
 */


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'ActiveFilterBadges',
  props: {
  /**
   * Pass filter object
   */
  modelValue: {
    type: Object,
    required: true
  },
  /**
   * Show remove button on chips and remove from filter on click
   */
  removable: {
    type: Boolean,
    default: false,
  },
  /**
   * Show chips inline
   */
  inline: {
    type: Boolean,
    default: false,
  },
  includeSearch: {
    type: Boolean,
    default: true,
  },
  containerClass: {
    type: String,
    default: '',
  }
},
  emits: ['update:modelValue', 'filterItemsChanged'],
  setup(__props, { emit: __emit }) {



const props = __props

const emit = __emit

const filterData = toRef(props, 'modelValue')
const mainStore = useMainStore()

const filterItems = computed<Array<any>>(() => {
  return getFilterItems(filterData.value, mainStore, props.includeSearch)
})


const removeFromFilter = function (item: object) {
  let _filterData = {...props.modelValue}
  if (Array.isArray(_filterData[item['key']])) {
    _filterData[item['key']] = _filterData[item['key']].filter(obj => obj !== item['value'])
  } else {
    _filterData[item['key']] = undefined
  }

  emit('update:modelValue', _filterData)
}


return (_ctx: any,_cache: any) => {
  const _component_q_chip = _resolveComponent("q-chip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["", {'is-open': filterItems.value.length > 0, 'c-wrapper-inline': __props.inline}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _createElementVNode("div", _mergeProps({
          class: ["flex q-py-sm", {'no-wrap scroll-x': __props.inline}]
        }, _ctx.$attrs), [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterItems.value, (item, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createVNode(_component_q_chip, {
                clickable: false,
                ripple: false,
                removable: __props.removable,
                onRemove: ($event: any) => (removeFromFilter(item))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.label), 1)
                ]),
                _: 2
              }, 1032, ["removable", "onRemove"])
            ]))
          }), 128))
        ], 16)
      ]),
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}
}

})