import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, unref as _unref } from "vue"

const _hoisted_1 = { class: "full-width full-height" }
const _hoisted_2 = { class: "flex column no-wrap full-height" }
const _hoisted_3 = {
  class: "q-pb-md relative-position",
  style: {"flex-grow":"1","user-select":"none"}
}
const _hoisted_4 = { class: "text-center full-height flex flex-center" }
const _hoisted_5 = {
  key: 0,
  class: "q-px-sm gallery-image-description bg-black text-white absolute-bottom-left"
}
const _hoisted_6 = {
  class: "flex row no-wrap relative-position",
  style: {"height":"100%"}
}
const _hoisted_7 = { class: "fixed-top-left z-max header__fix__force" }
const _hoisted_8 = { class: "q-pa-md" }
const _hoisted_9 = {
  class: "fixed-right q-mr-xs",
  style: {"top":"calc(50% - 100px)"}
}

import {useDialogPluginComponent} from 'quasar'
import {computed, onMounted, onUnmounted, ref, watch} from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'GalleryModal',
  props: {
  images: {
    required: true,
    type: Array<any>,
    default: () => []
  },
  position: {
    default: 0
  },
},
  emits: [
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits
],
  setup(__props) {

const props = __props



const {onDialogHide, dialogRef} = useDialogPluginComponent()

// export interface Image {
//   file: string,
//   description?: string
//   default_alt_text?: string
//   thumb?: object
// }

// let lastDistance: any = undefined

const scale = ref(1.0)
const activeImagePosition = ref(1.0)
const translateX = ref(0)
const translateY = ref(0)
// const scaling = ref(false)
// const touches = ref()
const thumbContainer = ref(null)
const galleryThumbRefs = ref<Array<any>>([])

function getImgComponent(image, thumbnail = false) {
  return {
    src: thumbnail ? image.thumb : image.file
  }
}

// function touchEnd(/** e **/) {
//   lastDistance = undefined
//   scaling.value = false
// }
//
// function touchMove(e) {
//   touches.value = []
//   if (e.touches && e.touches.length) {
//     if (e.touches.length === 2) {
//       scaling.value = true
//       let obj1 = e.touches.item(0)
//       let obj2 = e.touches.item(1)
//
//       let p1 = {x: obj1.screenX, y: obj1.screenY}
//       let p2 = {x: obj2.screenX, y: obj2.screenY}
//
//       let distance = Math.abs(Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2)))
//
//       if (!lastDistance) {
//         lastDistance = distance
//       }
//
//       let deltaDistance = Math.abs(lastDistance - distance)
//       console.debug(deltaDistance)
//       if (deltaDistance < 2) {
//         return
//       }
//
//       if (deltaDistance > 100) {
//         console.debug('distance', distance, 'lastDistance', lastDistance)
//       }
//
//       // deltaDistance between 0-10 usually
//       if (deltaDistance > 10) {
//         deltaDistance = 10
//       }
//
//       // 10 = 100% = 0.25
//       // 5 = 50% = 0.125
//       // 0 = 0% = 0
//       let normalizeScale = deltaDistance * 10 / (100 / 0.25)
//
//       if (distance > lastDistance) {
//         console.debug('zoom in', deltaDistance)
//         scale.value = scale.value + normalizeScale
//       } else {
//         console.debug('zoom out', deltaDistance)
//         scale.value = scale.value - normalizeScale
//       }
//       lastDistance = distance
//     }
//   }
// }

// function touchPanHandler(e) {
//   if (scale.value > 1 && !scaling.value) {
//     translateX.value = translateX.value + e.delta.x
//     translateY.value = translateY.value + e.delta.y
//   }
// }

// function wheelHandler(e) {
//   if (e.deltaY > 0) {
//     if (scale.value > 1) {
//       scale.value = scale.value - 0.25
//     }
//
//   } else if (scale.value < 5) {
//     scale.value = scale.value + 0.25
//   }
// }

function keydownHandler(e) {
  if (e.key === 'ArrowLeft') {
    activeImagePosition.value = getPrevPosition(activeImagePosition.value)
  }
  if (e.key === 'ArrowRight') {
    activeImagePosition.value = getNextPosition(activeImagePosition.value)
  }
}

function scrollToThumb(pos) {
  if (!galleryThumbRefs.value.length || galleryThumbRefs.value.length < pos) return
  let ref = galleryThumbRefs.value[pos]

  let eleWidth = ref.$el.clientWidth
  let left = ref.$el.offsetLeft

  if (!thumbContainer.value) return
  thumbContainer.value.scrollLeft = left - (eleWidth / 2)
}

function handleSwipe({ /**evt,**/ ...newInfo}) {
  if (newInfo.direction === 'right') {
    activeImagePosition.value = getPrevPosition(activeImagePosition.value)
  }
  if (newInfo.direction === 'left') {
    activeImagePosition.value = getNextPosition(activeImagePosition.value)
  }
}

function resetImageTransformations() {
  scale.value = 1
  translateX.value = 0
  translateY.value = 0
}

function getNextPosition(pos) {
  let newPos = pos + 1
  if (newPos > props.images.length - 1) {
    return 0
  }
  return newPos
}

function getPrevPosition(pos) {
  let newPos = pos - 1
  if (newPos < 0) {
    return props.images.length - 1
  }
  return newPos
}

const transformStyle = computed(() => {
  if (scale.value <= 1) {
    resetImageTransformations()
  }
  return {
    'transform': `scale(${scale.value}) translateX(${translateX.value / scale.value}px)  translateY(${translateY.value / scale.value}px)`,
  }
})

const activeImage: any = computed(() => {
  if (!props.images.length) {
    return undefined
  }
  return props.images[activeImagePosition.value || 0]
})


onMounted(() => {
  activeImagePosition.value = props.position
  document.addEventListener('keydown', keydownHandler);
  // document.addEventListener('wheel', wheelHandler);
  // document.addEventListener('touchmove', touchMove)
  // document.addEventListener('touchend', touchEnd)
})

onUnmounted(() => {
  document.removeEventListener('keydown', keydownHandler);
  // document.removeEventListener('wheel', wheelHandler);
  // document.removeEventListener('touchmove', touchMove);
  // document.removeEventListener('touchend', touchEnd)
})

watch(
  () => activeImagePosition.value,
  (newVal) => {
    scrollToThumb(newVal)
    resetImageTransformations()
  }
)


return (_ctx: any,_cache: any) => {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_touch_swipe = _resolveDirective("touch-swipe")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref_key: "dialogRef",
    ref: dialogRef,
    maximized: "",
    onHide: _unref(onDialogHide)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(), _createElementBlock("div", {
                key: activeImage.value.file,
                class: "relative-position c-active-image"
              }, [
                (_openBlock(), _createBlock(_component_q_img, _mergeProps(getImgComponent(activeImage.value), {
                  key: activeImagePosition.value,
                  ref: "activeImg",
                  draggable: false,
                  class: "vertical-middle",
                  width: "100vw",
                  fit: 'contain',
                  style: [{"max-height":"calc(100vh - 200px)"}, transformStyle.value]
                }), null, 16, ["style"])),
                (activeImage.value.description)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(activeImage.value.description), 1))
                  : _createCommentVNode("", true)
              ]))
            ])
          ])), [
            [
              _directive_touch_swipe,
              scale.value === 1 ? handleSwipe : undefined,
              void 0,
              { mouse: true }
            ]
          ]),
          _createElementVNode("div", {
            ref_key: "thumbContainer",
            ref: thumbContainer,
            style: {"max-width":"100%","overflow":"auto","scroll-behavior":"smooth"}
          }, [
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.images, (obj, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: obj.file,
                  class: _normalizeClass(["q-ma-xs thumbnails", {'active': index === activeImagePosition.value}])
                }, [
                  _createVNode(_component_q_img, _mergeProps({ ref_for: true }, getImgComponent(obj, true), {
                    ref_for: true,
                    ref: el => galleryThumbRefs.value[index] = el,
                    ratio: 3/2,
                    class: "cursor-pointer full-height",
                    style: {"width":"200px"},
                    onClick: ($event: any) => {activeImagePosition.value = index; scale.value = 1}
                  }), null, 16, ["onClick"])
                ], 2))
              }), 128))
            ])
          ], 512)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createVNode(_component_q_btn, {
              color: "white",
              dense: "",
              round: "",
              size: "lg",
              "text-color": "dark",
              icon: "close"
            }, null, 512), [
              [_directive_close_popup]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_q_btn, {
            class: "text-white bg-black",
            round: "",
            flat: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (activeImagePosition.value = getNextPosition(activeImagePosition.value)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "navigate_next" })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", {
          class: "fixed-left q-ml-xs",
          style: {"top":"calc(50% - 100px)"},
          onClick: _cache[1] || (_cache[1] = ($event: any) => (activeImagePosition.value = getPrevPosition(activeImagePosition.value)))
        }, [
          _createVNode(_component_q_btn, {
            class: "text-white bg-black",
            round: "",
            flat: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "navigate_before" })
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["onHide"]))
}
}

})