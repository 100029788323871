import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import LocationSelectDialog from 'src/apps/search/components/dialogs/LocationSelectDialog.vue'
import {useQuasar} from 'quasar'
import {computed, PropType} from 'vue'
import {getCityFromNominatim} from 'src/etc/utils';
import {SearchLocation} from 'src/apps/search/models';


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchLocationInput',
  props: {
  modelValue: {
    type: Object as PropType<SearchLocation>,
    required: false,
  }
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props
const $emit = __emit

const locationStr = computed(() => {
  if (!props.modelValue?.radius || !props.modelValue?.address) return
  return `${getCityFromNominatim(props.modelValue?.address)} • ${props.modelValue.radius} km`
})

const $q = useQuasar()

const openLocationSelect = () => {
  $q.dialog({
    component: LocationSelectDialog,
    componentProps: {
      radius: props.modelValue?.radius,
      latLng: props.modelValue?.latLng,
    }
  }).onOk((data) => {
    $emit('update:modelValue', data)
  })
}


return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    "model-value": locationStr.value,
    class: "full-width c-btn--outstanding",
    outlined: "",
    autofocus: "",
    type: "search",
    placeholder: "Ort und Umkreis wählen",
    clearable: "",
    readonly: "",
    color: "dark",
    rounded: "",
    onClick: _withModifiers(openLocationSelect, ["prevent"])
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_q_icon, {
        size: "xs",
        name: "fas fa-map"
      })
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})