import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "map" }
const _hoisted_2 = {
  class: "absolute",
  style: {"left":"10px","top":"10px"}
}
const _hoisted_3 = { class: "header__fix" }
const _hoisted_4 = {
  class: "absolute text-center",
  style: {"left":"50%","bottom":"10px","z-index":"400","transform":"translateX(-50%)"}
}
const _hoisted_5 = { class: "footer__fix" }
const _hoisted_6 = {
  key: 0,
  class: "absolute-center",
  style: {"z-index":"401"}
}
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = {
  class: "absolute footer__fix",
  style: {"bottom":"30px","right":"10px"}
}

import 'leaflet/dist/leaflet.css';
import {LMap, LTileLayer} from '@vue-leaflet/vue-leaflet';
import {computed, Ref, ref, watch} from 'vue';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import {LatLngTuple, Marker, MarkerOptions} from 'leaflet';
import {useRoute, useRouter} from 'vue-router';
import {useSearchStore} from 'src/apps/search/store';
import axios from 'axios';
import {locate} from 'src/apps/core/utils';
import {useMeta, useQuasar} from 'quasar';
import {useMainStore} from 'stores/main';
import SearchFilterDialog from 'src/apps/search/components/dialogs/FilterSelectionDialog.vue';

export interface Location {
  lat_lng: LatLngTuple,
  price: number,
  seats: number,
  beds: number,
  title: string
  uuid: string,
  image?: string,
}

// Extend MarkerOptions to include the 'price' property
interface CustomMarkerOptions extends MarkerOptions {
  data?: Location
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MapPage',
  setup(__props) {

const zoom = ref(2)
const center = ref([51.165691, 10.451526])
const locations = ref<Array<Location>>([])
const markers = ref<Array<Marker>>([])
const markerCluster = ref<any>()
const loadingLocation = ref(false)
const mapRef: Ref<any> = ref(null); // Using any for simplicity; replace with a more specific type if available
const router = useRouter()
const route = useRoute()
const searchStore = useSearchStore()
const $q = useQuasar()

const mapOptions = {
  zoomControl: false,
  maxZoom: 14,
}

const formatPrice = function (price: number, locale = 'en-US', currency = 'EUR'): string {
  // Format the number with the specified locale and currency
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    useGrouping: false,
    maximumFractionDigits: 0, // Adjust as needed for rounding
    minimumFractionDigits: 0,  // Adjust as needed for rounding
  });

  // Convert the price into thousands
  if (price < 1000) {
    return `${formatter.format(price)}`
  }

  const units = ['K', 'M', 'B', 'T'];
  let unitIndex = -1;
  let scaledNum = price;

  while (scaledNum >= 1000 && unitIndex < units.length - 1) {
    scaledNum /= 1000;
    unitIndex++;
  }

  // Format and add the 'k' suffix for thousands
  return `${formatter.format(scaledNum)}${units[unitIndex]}`;
}

const locateUser = function () {
  loadingLocation.value = true
  const map = mapRef.value.leafletObject;

  locate($q).then(pos => {
    const latlng = [pos.latitude, pos.longitude];
    map.setView(latlng, 12);
  }).catch(err => {
    $q.notify({message: err.message});
    console.error(err)
  }).finally(() => {
    loadingLocation.value = false
  })
}

const createIcon = function (data: Location, L) {
  const _price = formatPrice(data?.price ?? 0);
  const size = [100, 106]
  // HTML content for the icon
  const iconHTML = `
    <div class="marker-content">
        <div class="marker-img">
            <img src="${data?.image}" style="max-width: 100% !important; display: ${data?.image ? 'unset' : 'none'}">
            <div class="q-pt-lg text-xsmall text-muted" style="display: ${!data?.image ? 'inline-block' : 'none'}">Kein Bild</div>
        </div>
        <div class="marker-label">
          <div class="flex q-gutter-x-xs items-center justify-around">
              <div><span class="marker-price">${_price ?? '-'}</span></div>
              <div class="c-icons">
                <div>
                    <span class="material-icons q-mr-none">airline_seat_recline_normal</span>${data?.seats ?? '-'}
                </div>
                <div>
                    <i class="fa-solid fa-bed"></i> ${data?.beds ?? '-'}
                </div>
            </div>
          </div>
      </div>
    </div>`;

  return L.divIcon({
    className: 'custom-marker',
    html: iconHTML,
    iconSize: size,
    iconAnchor: [size[0] / 2, size[1] / 2],
  });
}

const createClusterIcon = function (L, cluster) {
  var childCount = cluster.getChildCount();
  const children = cluster.getAllChildMarkers()

  const minPrice = Math.min(...children.map((child) => child.options.data.price))
  const maxPrice = Math.max(...children.map((child) => child.options.data.price))
  const priceSpan = `${formatPrice(minPrice)} - ${formatPrice(maxPrice)}`

  var c = ' c-marker-cluster-';
  if (childCount < 10) {
    c += 'small';
  } else if (childCount < 100) {
    c += 'medium';
  } else {
    c += 'large';
  }

  return new L.DivIcon({
      html: `
    <div class="custom-marker--wrapper">
      <div class="children-count">
        <div><i class="fas fa-van-shuttle"></i></div>
        <div>${childCount}</div>
      </div>
      <div class="price">${priceSpan}</div>
    </div>
`,
      className: 'c-marker-cluster' + c,
      iconSize: new L.Point(120, 0)
    }
  );
}

async function getLatLng(address) {
  try {
    const response = await axios.get('https://nominatim.openstreetmap.org/search', {
      params: {
        q: address,
        format: 'json',
        limit: 1
      }
    });

    if (response.data.length > 0) {
      const location = response.data[0];
      return {
        lat: location.lat,
        lng: location.lon
      };
    } else {
      console.log('No results found for this address.');
      return null;
    }
  } catch (error) {
    console.error('Error occurred: ', error);
    return null;
  }
}


const onMarkerClick = (e) => {
  const data = e.target.options.data
  router.push({name: 'listingDetail', params: {uuid: data.uuid}})
}

async function drawMarker(leafletMap, init = true) {
  const L = await import('leaflet');
  const leafletMarkerCluster = await import('leaflet.markercluster');
  if (!init && markers.value.length) {
    console.debug('drawing markers')
    markers.value.forEach((marker) => {
      leafletMap.removeLayer(marker)
    })
    markers.value = []
    leafletMap.removeLayer(markerCluster.value)
    markerCluster.value = undefined
  }
  locations.value = []

  markerCluster.value = new leafletMarkerCluster.MarkerClusterGroup({
    spiderfyOnMaxZoom: true,
    showCoverageOnHover: true,
    removeOutsideVisibleBounds: true,
    spiderfyDistanceMultiplier: 4,
    maxClusterRadius: 120,
    iconCreateFunction: function (cluster) {
      return createClusterIcon(L, cluster);
    }
  });


  const force = !init
  locations.value = await searchStore.getMapSearchResults(force)

  locations.value.forEach((location) => {
    // check if location.lat_lng, that should be a tuple of floats is not null or undefined
    if (!location.lat_lng || !location.lat_lng.every(element => !!element)) return;
    const marker: Marker = L.marker(location.lat_lng, {
      icon: createIcon(location, L),
      data: location,
      title: location.title,
      alt: location.title,
    } as CustomMarkerOptions).on('click', onMarkerClick)
    markers.value.push(marker)
    markerCluster.value.addLayer(marker);
  });

  leafletMap.addLayer(markerCluster.value);

}

const initializeMap = async function (e) {
  const L = await import('leaflet');

  const leafletMap = e
  if (!leafletMap) return;

  L.control.zoom({
    position: 'bottomleft',
  }).addTo(leafletMap);


  await drawMarker(leafletMap)

  // const group = L.featureGroup(markers)
  // leafletMap.fitBounds(group.getBounds())

  if (searchStore.mapState?.coordinates?.length && searchStore.mapState?.zoom) {
    leafletMap.setView(searchStore.mapState.coordinates, searchStore.mapState.zoom)
  } else if (searchStore.filterData.l) {
    const latLng = await getLatLng(searchStore.filterData.l)
    if (latLng) {
      leafletMap.setView([latLng.lat, latLng.lng], 11)
    }
  } else {
    leafletMap.fitBounds(markerCluster.value.getBounds())
  }

  leafletMap.on('moveend', () => {
    const newCenter = leafletMap.getCenter();
    const newZoom = leafletMap.getZoom();

    // Update zoom and center reactive properties
    searchStore.updateMapState(
      [newCenter.lat, newCenter.lng],
      newZoom,
    )
  });
}

const hasHistory = computed(() => {
  if (typeof window !== 'undefined') {
    return window.history?.length > 0 && window.history.state?.back !== null;
  }
  return false;
})

const filterActiveCount = computed(() => {
  const excludedKeys = ['l', 's']
  let activeFiltersCount = Object.keys(searchStore.filterData).filter(key => {
    if (excludedKeys.includes(key)) return false;
    const value = searchStore.filterData[key];
    if (value === undefined || value === null) return false;
    if (Array.isArray(value) && value.length === 0) return false;
    if (value && typeof value === 'object' && Object.keys(value).length === 0) return false;
    return true;
  }).length

  if (searchStore.filterData?.services?.length) {
    activeFiltersCount += searchStore.filterData.services.length - 1
  }
  return activeFiltersCount
})

const resultsCount = computed(() => {
  return searchStore.mapSearchResults?.length ?? 0
})

function openFilterDialog() {
  $q.dialog({
    component: SearchFilterDialog,

    // props forwarded to your custom component
    componentProps: {
      modelValue: searchStore.filterData,
      searchResultCount: resultsCount
    }
  }).onOk((payload) => {
    if (payload?.action === 'reset') {
      searchStore.resetFilterData()
    }
  })
}

watch(
  () => searchStore.filterData,
  () => {
    drawMarker(mapRef.value.leafletObject, false)
  },
  {deep: true}
)

useMeta(() => {
  const mainStore = useMainStore()

  return {
    // sets document title
    title: 'Karte | Camper Trader',

    // meta tags
    meta: {
      description: {
        name: 'description',
        content: 'Alle Inserate direkt auf unserer Karte finden. Der schnellste Weg zum eigenem Camper, Wohnmobil oder Kastenwagen.'
      },
      keywords: {
        name: 'keywords',
        content: 'Camper Trader, Wohnmobile kaufen, Wohnwagen kaufen, Camper Vans kaufen, Gebrauchte Wohnmobile, Gebrauchte Wohnwagen, Gebrauchte Campervans, Privat verkaufen, Privat kaufen, Gebrauchte Camper kaufen, Wohnmobilmarkt, Wohnwagenmarkt, Wohnmobile von privat, Wohnwagen von privat, Camper von privat, Campingfahrzeuge, Camper inserieren, Wohnmobile inserieren, Wohnwagen inserieren, Camper Trader Deutschland'
      },
      // equiv: { 'http-equiv': 'Content-Type', content: 'text/html; charset=UTF-8' },
      // note: for Open Graph type metadata you will need to use SSR, to ensure page is rendered by the server
      ogTitle: {
        property: 'og:title',
        // optional; similar to titleTemplate, but allows templating with other meta properties
        template() {
          return 'Karte | Camper Trader'
        }
      },
      ogType: {
        property: 'og:type',
        template() {
          return 'website'
        }
      },
      fbAppId: {
        property: 'fb:app_id',
        template() {
          return '3404774996425496'
        }
      },
      ogUrl: {
        property: 'og:url',
        template() {
          return `${process.env.BASE_URL}${route.fullPath}`
        }
      },
      ogImage: {
        property: 'og:image',
        template() {
          if (mainStore.siteConfig.hasOwnProperty('site_og_default_image')) {
            return mainStore.siteConfig['site_og_default_image']?.path
          }
          return ''
        }
      },
      ogImageAlt: {
        property: 'og:image:alt',
        template() {
          return 'Camper Trader Logo'
        }
      },
      ogDescription: {
        property: 'og:description',
        template() {
          return 'Alle Inserate direkt auf unserer Karte finden. Der schnellste Weg zum eigenem Camper, Wohnmobil oder Kastenwagen.'
        }
      },
      ogLocale: {
        property: 'og:locale',
        template() {
          return 'de_DE'
        },
      },
      ogSiteName: {
        property: 'og:site_name',
        template() {
          return 'Camper Trader'
        },
      },
    },

    script: {
      website: {
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          'name': 'Karte | Camper Trader',
          'alternateName': 'Alle Inserate direkt auf unserer Karte finden. ' +
            'Der schnellste Weg zum eigenem Camper, Wohnmobil oder Kastenwagen.',
          'url': 'https://campertrader.de',
        }),
      },

      organization: {
        type: 'application/ld+json',
        innerHTML: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          name: 'Camper Trader',
          legalName: 'Lysiak & Tröndle GbR',
          url: 'https://campertrader.de',
          sameAs: [
            'https://www.instagram.com/campertrader.de/',
          ]
        })
      }
    },

    noscript: {
      default: 'Please enable Javascript to use this website.'
    }
  }
})


return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_spinner_puff = _resolveComponent("q-spinner-puff")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(LMap), {
          ref_key: "mapRef",
          ref: mapRef,
          zoom: zoom.value,
          "onUpdate:zoom": _cache[0] || (_cache[0] = ($event: any) => ((zoom).value = $event)),
          options: mapOptions,
          center: center.value,
          onReady: initializeMap
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(LTileLayer), {
              url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
              "layer-type": "base",
              name: "OpenStreetMap"
            })
          ]),
          _: 1
        }, 8, ["zoom", "center"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_btn, {
              style: {"z-index":"400"},
              icon: "fas fa-arrow-left",
              round: "",
              color: "white",
              "text-color": "dark",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (hasHistory.value ? _ctx.$router.go(-1) : _ctx.$router.push({name: 'search'})))
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_q_btn, {
              rounded: "",
              style: {"padding":"10px 30px"},
              color: "dark",
              onClick: openFilterDialog
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_icon, { name: "tune" }),
                _cache[2] || (_cache[2] = _createElementVNode("span", null, "  Filter", -1)),
                (filterActiveCount.value)
                  ? (_openBlock(), _createBlock(_component_q_badge, {
                      key: 0,
                      rounded: "",
                      color: "white",
                      "text-color": "dark",
                      floating: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(filterActiveCount.value), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ]),
        (_unref(searchStore).searchLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", null, [
                  _createVNode(_component_q_spinner_puff, { size: "7rem" })
                ]),
                _cache[3] || (_cache[3] = _createElementVNode("div", null, "Lade Ergebnisse", -1))
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_q_btn, {
            round: "",
            color: "white",
            "text-color": "black",
            style: {"z-index":"400"},
            loading: loadingLocation.value,
            onClick: locateUser
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "my_location" })
            ]),
            _: 1
          }, 8, ["loading"])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})