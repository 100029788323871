import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, renderList as _renderList, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-muted text-small"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item = _resolveComponent("q-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.field)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component.component), _mergeProps({
          key: 0,
          error: _ctx.errors?.length ? true : undefined,
          "error-message": _ctx.errors ? _ctx.errors.join(' ') : undefined,
          "model-value": _ctx.component.attrs.modelValue,
          options: _ctx.component.component === _ctx.QSelect ? _ctx.options : undefined
        }, _ctx.component.attrs, _toHandlers(_ctx.component.handlers), {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = 
        (val) => {
          _ctx.$emit('update:modelValue', val);
          _ctx.component.attrs.modelValue = val;
        }
      ),
          onFilter: _ctx.component.attrs?.filterFn
        }), _createSlots({ _: 2 }, [
          _renderList(_ctx.$slots, (_, name) => {
            return {
              name: name,
              fn: _withCtx((data) => [
                _renderSlot(_ctx.$slots, name, { data: data })
              ])
            }
          }),
          (_ctx.component.component?.name === 'QSelect')
            ? {
                name: "option",
                fn: _withCtx(({ itemProps, opt, selected }) => [
                  _createVNode(_component_q_item, _mergeProps(itemProps, { "active-class": "bg-grey-3" }), {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_item_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(opt.label), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_q_item_section, { side: "" }, {
                        default: _withCtx(() => [
                          selected
                            ? (_openBlock(), _createBlock(_component_q_icon, {
                                key: 0,
                                name: "done"
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1040)
                ]),
                key: "0"
              }
            : undefined
        ]), 1040, ["error", "error-message", "model-value", "options", "onFilter"]))
      : _createCommentVNode("", true),
    (_ctx.component.component?.name === 'QCheckbox' && _ctx.component.attrs.hint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.component.attrs.hint), 1))
      : _createCommentVNode("", true)
  ]))
}