import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {Dialog} from 'quasar';
import SearchTextInputDialog from 'src/apps/search/components/SearchTextInputDialog.vue';
import {SearchAdapter} from 'src/apps/search/helpers';
import {PropType} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchTextInput',
  props: {
  modelValue: {
    type: String,
    required: false,
  },
  searchAdapter: {
    type: Object as PropType<SearchAdapter>,
    required: true,
  },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props
const $emit = __emit


function onClickSearch() {
  Dialog.create({
    component: SearchTextInputDialog,
    componentProps: {
      searchAdapter: props.searchAdapter,
      searchTextProp: props.modelValue ?? '',
    },
    transitionShow: 'slide-up',
    transitionHide: 'slide-down',
  }).onOk((val: string) => {
    $emit('update:modelValue', val)
  })
}

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_q_input, {
        outlined: "",
        "model-value": __props.modelValue,
        placeholder: "Suchbegriff",
        class: "c-btn--outstanding",
        readonly: "",
        rounded: "",
        onClick: onClickSearch
      }, _createSlots({
        prepend: _withCtx(() => [
          _createVNode(_component_q_icon, { name: "search" })
        ]),
        _: 2
      }, [
        (__props.modelValue?.length)
          ? {
              name: "append",
              fn: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  dense: "",
                  round: "",
                  flat: "",
                  onClick: _cache[0] || (_cache[0] = () => $emit('update:modelValue', ''))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_icon, { name: "fas fa-times" })
                  ]),
                  _: 1
                })
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["model-value"])
    ])
  ]))
}
}

})