<template>
  <div class="shimmer">
    <slot>{{ text }}</slot>
  </div>
</template>

<script>
export default {
  name: 'ShimmerText',
  props: {
    text: {
      required: false,
      type: String
    },
    color: {
      default: '#797979',
    }
  }
}
</script>

<style lang="scss" scoped>

$shimmer-text-color: v-bind('color');

.shimmer {
  display: inline-block;
  text-align: center;
  color: rgba(255, 255, 255, 0.1);
  background: rgb(255, 255, 255);
  background: linear-gradient(130deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 60%);
  -webkit-background-size: 125px 100%;
  -moz-background-size: 125px 100%;
  background-size: 150px 100%;
  -webkit-background-clip: text;
  //-moz-background-clip: text;
  //background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 3.2s;
  -moz-animation-duration: 3.2s;
  animation-duration: 3.2s;
  animation-delay: 1s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: -100% 0;
  background-color: $shimmer-text-color;
}

@-moz-keyframes shimmer {
  0% {
    background-position: top left -150px;
  }
  100% {
    background-position: top right -150px;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: top left -150px;
  }
  100% {
    background-position: top right -150px;
  }
}

@-o-keyframes shimmer {
  0% {
    background-position: top left -150px;
  }
  100% {
    background-position: top right -150px;
  }
}

@keyframes shimmer {
  0% {
    background-position: top left -150px;
  }
  100% {
    background-position: top right -150px;
  }
}
</style>
