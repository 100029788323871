import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "q-mb-lg flex column"
}
const _hoisted_2 = { class: "font-fjalla-one" }
const _hoisted_3 = { class: "full-width" }
const _hoisted_4 = {
  key: 1,
  class: "full-width"
}
const _hoisted_5 = { class: "full-width" }
const _hoisted_6 = { class: "text-center q-mt-lg" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = {
  key: 0,
  class: "col-12"
}
const _hoisted_9 = { class: "items-center column q-my-xl" }
const _hoisted_10 = {
  key: 1,
  class: "col-12"
}
const _hoisted_11 = { class: "row q-col-gutter-md" }
const _hoisted_12 = {
  key: 0,
  class: "flex justify-center q-my-lg"
}
const _hoisted_13 = {
  key: 2,
  class: "no-results q-my-xl q-py-xl text-center full-width"
}
const _hoisted_14 = { class: "q-my-md" }
const _hoisted_15 = {
  href: "https://campertrader.de/",
  target: "_blank"
}

import {ref, toRef, watch} from 'vue';
import BusinessApi from 'src/api/business';
import FilterListItem from 'src/components/adverts/FilterListItem.vue';
import {extraMapping} from 'src/etc/helper';
import {orderingOptions} from 'src/pages/FilterPage.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BusinessAdvertsList',
  props: {
  slug: {type: String, default: undefined},
  target: {type: String, required: false, default: undefined},
    showExtras: {type: Boolean, default: false},
    hideOwner: {type: Boolean, default: false},
    asCards: {type: Boolean, default: false},
    showFilter: {type: Boolean, default: false},
  collapseFilter: {type: Boolean, default: true},
  limitResultsPerPage: {type: Number, default: undefined},
  preDefinedFilter: {
    type: Object, default: () => {
      return {}
    }
  }
  },
  setup(__props) {

const props = __props
const filterData = ref<any>({
    vehicle__extras__slug: [],
    vehicle__brand: [],
  vehicle__car_type: [],
  address__city: [],
  }
)
const adverts = ref()
const loadingAdverts = ref()
const availableFilter = ref()
const collapseFilter = toRef(props, 'collapseFilter')
const isCollapseFilter = ref(collapseFilter.value)
const currentPage = ref(1)
const preDefinedFilter = toRef(props, 'preDefinedFilter')
const slug = toRef(props, 'slug')

const fetchAdverts = () => {
  loadingAdverts.value = true
  BusinessApi.fetchBusinessPublicAdvertList(
    slug.value,
    {limit: props.limitResultsPerPage, page: currentPage.value, ...filterData.value, ...preDefinedFilter.value}
  ).then(resp => {
    adverts.value = resp.data
    availableFilter.value = resp.data.available_filter
  }).catch(err => {
    console.error(err)
  }).finally(() => {
    loadingAdverts.value = false
  })
}

watch(
  () => filterData.value,
  () => {
    fetchAdverts()
  },
  {deep: true, immediate: true}
)

const reset = function () {
  filterData.value = {
    vehicle__extras__slug: [],
    vehicle__brand: [],
    vehicle__car_type: [],
    address__city: [],
  }
}
const toggleList = function (value: string, list: Array<string>) {
  if (list.includes(value)) {
    const i = list.indexOf(value)
    list.splice(i, 1)
  } else {
    list.push(value)
  }
}


return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_spinner_dots = _resolveComponent("q-spinner-dots")!
  const _component_q_pagination = _resolveComponent("q-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (props.showFilter && availableFilter.value && adverts.value?.results?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass([{'q-mx-sm': _ctx.$q.screen.lt.sm}, "q-mb-md flex justify-between"])
          }, [
            _createElementVNode("div", null, [
              _createVNode(_component_q_btn, {
                ref: "refBtnExtras",
                disable: !!filterData.value.vehicle__extras__slug?.length,
                color: "primary",
                "no-caps": "",
                rounded: "",
                "text-color": "white",
                unelevated: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (!filterData.value.vehicle__extras__slug?.length ? isCollapseFilter.value = !isCollapseFilter.value : undefined))
              }, {
                default: _withCtx(() => [
                  _cache[5] || (_cache[5] = _createTextVNode(" Ausbau Filter ")),
                  _createVNode(_component_q_icon, {
                    name: `fas fa-caret-${isCollapseFilter.value ? 'up' : 'down'}`
                  }, null, 8, ["name"])
                ]),
                _: 1
              }, 8, ["disable"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_q_select, {
                modelValue: filterData.value.ordering,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filterData.value.ordering) = $event)),
                options: _unref(orderingOptions),
                dense: "",
                "emit-value": "",
                label: "Sortierung",
                "map-options": "",
                outlined: "",
                style: {"width":"190px","max-width":"100%"}
              }, null, 8, ["modelValue", "options"])
            ])
          ], 2),
          (filterData.value.vehicle__extras__slug?.length || !isCollapseFilter.value)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(extraMapping)(availableFilter.value.extras), (extrasCategory) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: extrasCategory.name
                }, [
                  (extrasCategory.extras?.length)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass([{'q-pl-sm': _ctx.$q.screen.lt.sm}, "flex items-center no-wrap col-shrink q-gutter-x-sm"])
                      }, [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(extrasCategory.name), 1),
                        _createElementVNode("div", {
                          class: _normalizeClass([{'c-horizontal-scroll': !_ctx.$q.platform.is.desktop}, "flex q-py-sm"])
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(extrasCategory.extras, (extra) => {
                            return (_openBlock(), _createBlock(_component_q_chip, {
                              key: extra.slug,
                              clickable: !loadingAdverts.value,
                              color: filterData.value.vehicle__extras__slug?.includes(extra.slug) ? 'positive' : undefined,
                              disable: loadingAdverts.value,
                              "text-color": filterData.value.vehicle__extras__slug?.includes(extra.slug) ? 'white' : undefined,
                              onClick: ($event: any) => (toggleList(extra.slug, filterData.value.vehicle__extras__slug))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(extra.icon) + " " + _toDisplayString(extra.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["clickable", "color", "disable", "text-color", "onClick"]))
                          }), 128))
                        ], 2)
                      ], 2))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass([{'q-pl-sm': _ctx.$q.screen.lt.sm}, "flex items-center no-wrap col-shrink q-gutter-x-sm"])
            }, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "font-fjalla-one" }, "Marke", -1)),
              _createElementVNode("div", {
                class: _normalizeClass([{'c-horizontal-scroll': !_ctx.$q.platform.is.desktop}, "flex q-py-xs"])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availableFilter.value?.car_brands, (brand) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: brand.value
                  }, [
                    _createVNode(_component_q_chip, {
                      clickable: !loadingAdverts.value,
                      color: filterData.value['vehicle__brand'].includes(brand.value) ? 'positive' : undefined,
                      disable: loadingAdverts.value,
                      "text-color": filterData.value['vehicle__brand'].includes(brand.value) ? 'white' : undefined,
                      onClick: ($event: any) => (toggleList(brand.value, filterData.value['vehicle__brand']))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(brand.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["clickable", "color", "disable", "text-color", "onClick"])
                  ]))
                }), 128))
              ], 2)
            ], 2)
          ]),
          (availableFilter.value?.cities?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: _normalizeClass([{'q-pl-sm': _ctx.$q.screen.lt.sm}, "flex items-center no-wrap col-shrink q-gutter-x-sm"])
                }, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "font-fjalla-one" }, "Ort", -1)),
                  _createElementVNode("div", {
                    class: _normalizeClass([{'c-horizontal-scroll': !_ctx.$q.platform.is.desktop}, "flex q-py-xs"])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availableFilter.value?.cities, (city) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: city.value
                      }, [
                        _createVNode(_component_q_chip, {
                          clickable: !loadingAdverts.value,
                          color: filterData.value['address__city'].includes(city.value) ? 'positive' : undefined,
                          disable: loadingAdverts.value,
                          "text-color": filterData.value['address__city'].includes(city.value) ? 'white' : undefined,
                          onClick: ($event: any) => (toggleList(city.value, filterData.value['address__city']))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(city.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["clickable", "color", "disable", "text-color", "onClick"])
                      ]))
                    }), 128))
                  ], 2)
                ], 2)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: _normalizeClass([{'q-pl-sm': _ctx.$q.screen.lt.sm}, "flex items-center no-wrap col-shrink q-gutter-x-sm"])
            }, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "font-fjalla-one" }, "Typ", -1)),
              _createElementVNode("div", {
                class: _normalizeClass([{'c-horizontal-scroll': !_ctx.$q.platform.is.desktop}, "flex q-py-xs"])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availableFilter.value?.car_types, (carType) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: carType.value
                  }, [
                    _createVNode(_component_q_chip, {
                      clickable: !loadingAdverts.value,
                      color: filterData.value['vehicle__car_type'].includes(carType.value) ? 'positive' : undefined,
                      disable: loadingAdverts.value,
                      "text-color": filterData.value['vehicle__car_type'].includes(carType.value) ? 'white' : undefined,
                      onClick: ($event: any) => (toggleList(carType.value, filterData.value['vehicle__car_type']))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(carType.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["clickable", "color", "disable", "text-color", "onClick"])
                  ]))
                }), 128))
              ], 2)
            ], 2)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_q_btn, {
              color: "grey-3",
              "no-caps": "",
              outline: "",
              rounded: "",
              "text-color": "text-black",
              unelevated: "",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (reset()))
            }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("Filter zurücksetzen ")
              ])),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      (loadingAdverts.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_q_spinner_dots, {
                class: "changeTextColor",
                size: "4rem"
              }),
              _cache[10] || (_cache[10] = _createElementVNode("div", {
                class: "text-muted",
                style: {"margin-top":"-1rem"}
              }, "Suche ...", -1))
            ])
          ]))
        : _createCommentVNode("", true),
      (!loadingAdverts.value && adverts.value?.results)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(adverts.value.results, (advert) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: advert.uuid,
                  class: _normalizeClass([props.asCards ? 'col-sm-4 col-md-3' : undefined, "col-12"])
                }, [
                  _createVNode(FilterListItem, {
                    advert: advert,
                    animated: false,
                    "as-card": __props.asCards,
                    "show-extras": __props.showExtras,
                    "show-owner": !__props.hideOwner,
                    target: __props.target
                  }, null, 8, ["advert", "as-card", "show-extras", "show-owner", "target"])
                ], 2))
              }), 128))
            ]),
            (!loadingAdverts.value && adverts.value?.pages > 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_q_pagination, {
                    modelValue: currentPage.value,
                    "onUpdate:modelValue": [
                      _cache[3] || (_cache[3] = ($event: any) => ((currentPage).value = $event)),
                      _cache[4] || (_cache[4] = () => { fetchAdverts(); loadingAdverts.value = true })
                    ],
                    input: _ctx.$q.screen.width < 382,
                    max: adverts.value.pages,
                    "max-pages": 1,
                    "direction-links": "",
                    flat: "",
                    padding: "",
                    unelevated: ""
                  }, null, 8, ["modelValue", "input", "max"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (!loadingAdverts.value && !adverts.value?.results?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-h2" }, "🙃", -1)),
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "text-bold" }, "Keine passenden Inserate gefunden.", -1)),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("a", _hoisted_15, [
                _createVNode(_component_q_btn, { color: "primary" }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode(" Mehr auf CamperTrader.de ")
                  ])),
                  _: 1
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})