import script from "./CreateUpdatePage.vue?vue&type=script&setup=true&lang=ts"
export * from "./CreateUpdatePage.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QBanner,QForm,QOptionGroup,QCard,QBtn,QCheckbox});
