import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "fullscreen full-height full-width relative-position" }
const _hoisted_2 = { class: "flex column bg-white full-width full-height" }
const _hoisted_3 = { class: "col-grow c-image-editor-container" }
const _hoisted_4 = { class: "full-width" }
const _hoisted_5 = {
  style: {"max-height":"100px"},
  class: "flex justify-center q-py-sm q-px-md q-gutter-sm"
}
const _hoisted_6 = { class: "absolute-top-right" }

import 'tui-image-editor/dist/tui-image-editor.min.css';
import 'tui-color-picker/dist/tui-color-picker.min.css';
import {ref} from 'vue';
import {Notify, useDialogPluginComponent} from 'quasar';
import Adverts from 'src/api/adverts';


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageEditor',
  props: {
  advertUuid: {
    type: String,
    required: true
  },
  imageUuid: {
    type: String,
    required: true
  }
},
  emits: [
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits
],
  setup(__props) {

const props = __props


const {dialogRef, onDialogHide, onDialogOK} = useDialogPluginComponent()


const ImageEditor = require('tui-image-editor');

const editor = ref(null);
const imageEditor = ref<any>(null)
const activeObjectId = ref(undefined)
const undoCount = ref(0)
const submenu = ref()
const editorKey = Math.floor(Math.random() * 10000).toString();

const onHide = function () {
  if (imageEditor.value) {
    imageEditor.value.clearObjects();
    imageEditor.value.destroy();
  }
  onDialogHide()
}

function activateShapeMode() {
  if (imageEditor.value.getDrawingMode() !== 'SHAPE') {
    imageEditor.value.stopDrawingMode();
    imageEditor.value.startDrawingMode('SHAPE');
  }
}

function resizeEditor() {
  const editor: HTMLElement | null = document.querySelector('.c-image-editor-container');
  if (!editor) {
    console.error('editor not found')
    return
  }
  const container: HTMLElement | null = document.querySelector('#image-editor');
  if (!container) {
    console.error('container not found')
    return
  }

  const height = parseFloat(container.style.maxHeight) - 200;
  editor.style.height = String(height) + 'px';
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: Array<Uint8Array> = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: contentType});
}

const initImageEditor = async function () {
  let respImg, blobUrl
  try {
    respImg = await Adverts.fetchAdvertImageBase64Data(props.advertUuid, props.imageUuid)
    const blob = b64toBlob(respImg.data,)
    blobUrl = URL.createObjectURL(blob);
  } catch (err) {
    console.error(err)
    return
  }
  if (!editor.value) {
    console.error('editor is null')
    return
  }

  const options = {
    usageStatistics: false,
    cssMaxWidth: document.querySelector('.c-image-editor-container')?.clientWidth,
    cssMaxHeight: document.querySelector('.c-image-editor-container')?.clientHeight,
    selectionStyle: {
      rotatingPointOffset: 100,
      cornerSize: 25,
      lineWidth: 2,
      borderColor: '#F39C12',
      cornerStrokeColor: '#F39C12',
      cornerColor: '#F39C12',
      cornerStyle: 'circle'
    },
  }
  imageEditor.value = new ImageEditor(editor.value, options);

  imageEditor.value.loadImageFromURL(
      // resp.data.image.path,
      blobUrl,
      'lena'
  ).then(() => {
    imageEditor.value.clearUndoStack()
  })

  imageEditor.value.on({
    objectAdded: function (objectProps) {
      console.info('objectAdded', objectProps);
      imageEditor.value.stopDrawingMode()
    },
    undoStackChanged: function (length) {
      undoCount.value = length
      resizeEditor();
    },
    redoStackChanged: function (/*length*/) {
      resizeEditor();
    },
    objectActivated: function (obj) {
      activeObjectId.value = obj.id;
    },
  })
}

// function pixelateRate() {
//     let resp
//     if (!editor.value) {
//         resp = 10
//     } else {
//         resp = 5 * Math.pow(2, Math.floor(editor.value['offsetWidth']/1000));
//     }
//     console.log('pixelateRate', resp)
//     return resp
// }

const drawShape = function () {
  activateShapeMode()
  /**
   * Pixelate not working yet, see:
   * Issue: https://github.com/nhn/tui.image-editor/issues/572
   * */
  // imageEditor.value.setDrawingShape('rect', {
  //     fill: {
  //         type: 'filter',
  //         filter: [{pixelate: pixelateRate() }],
  //     },
  //     stroke: 'transparent',
  // });

  imageEditor.value.setDrawingShape('rect', {
    fill: '#000000',
    stroke: 'transparent',
  });
  imageEditor.value.startDrawingMode('SHAPE');
}

const cropping = function () {
  imageEditor.value.startDrawingMode('CROPPER')
  imageEditor.value.setCropzoneRect(0)
  submenu.value = 'cropping'
}

const applyCrop = function () {
  const cropzone = imageEditor.value.getCropzoneRect()
  if (cropzone === null) {
    imageEditor.value.stopDrawingMode();
    submenu.value = undefined
    return
  }
  imageEditor.value.crop(imageEditor.value.getCropzoneRect()).then(function () {
    imageEditor.value.stopDrawingMode();
    submenu.value = undefined
    resizeEditor();
  });
}


const save = function () {
  if (imageEditor.value.isEmptyUndoStack()) {
    onDialogHide()
    return
  }

  const options = {
    format: 'jpeg',
    quality: 1,
    // multiplier,
    // left,
    // top,
    // width,
    // height,
  }
  const imageStr = imageEditor.value.toDataURL(options)
  Adverts.patchAdvertImage(
      props.advertUuid,
      props.imageUuid,
      {image: imageStr}
  ).then(resp => {
    onDialogOK(resp.data)
  }).catch(err => {
    Notify.create({
      color: 'negative',
      message: 'Fehler beim speichern der Datei.',
    })
    console.error(err)
  })
}


return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref_key: "dialogRef",
    ref: dialogRef,
    "full-height": "",
    "full-width": "",
    onHide: onHide,
    onShow: initImageEditor
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createElementBlock("div", {
              id: "image-editor",
              key: _unref(editorKey),
              ref_key: "editor",
              ref: editor,
              class: "c-image-editor full-width full-height"
            }))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex q-pa-sm no-wrap overflow-auto", {'justify-center': _ctx.$q.screen.gt.xs}]),
              style: {"max-height":"100px","column-gap":"10px"}
            }, [
              (!submenu.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_q_btn, {
                      size: "12px",
                      round: "",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (imageEditor.value.rotate(-90)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_icon, { name: "fas fa-rotate-left" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_btn, {
                      size: "12px",
                      round: "",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (imageEditor.value.rotate(90)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_icon, { name: "fas fa-rotate-right" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_btn, {
                      size: "12px",
                      round: "",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (imageEditor.value.flipX()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_icon, { name: "fas fas fa-arrows-left-right" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_btn, {
                      size: "12px",
                      round: "",
                      onClick: cropping
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_icon, { name: "fas fas fa-crop" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_btn, {
                      size: "12px",
                      rounded: "",
                      "no-caps": "",
                      onClick: drawShape
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("Zensieren")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_q_btn, {
                      size: "12px",
                      disable: !undoCount.value,
                      round: "",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (imageEditor.value.undo()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_icon, { name: "fas fa-clock-rotate-left" })
                      ]),
                      _: 1
                    }, 8, ["disable"]),
                    _createVNode(_component_q_btn, {
                      size: "12px",
                      rounded: "",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (imageEditor.value.undo(undoCount.value)))
                    }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Reset")
                      ])),
                      _: 1
                    })
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (submenu.value === 'cropping')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(_component_q_btn, {
                            outline: "",
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (imageEditor.value.setCropzoneRect()))
                          }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode("Frei")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_q_btn, {
                            outline: "",
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (imageEditor.value.setCropzoneRect(1.8)))
                          }, {
                            default: _withCtx(() => _cache[11] || (_cache[11] = [
                              _createTextVNode("Titelbild")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_q_btn, {
                            round: "",
                            flat: "",
                            color: "negative",
                            onClick: _cache[7] || (_cache[7] = ($event: any) => {imageEditor.value.stopDrawingMode(); submenu.value = undefined})
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_icon, { name: "fas fa-close" })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_q_btn, {
                            round: "",
                            flat: "",
                            color: "positive",
                            onClick: applyCrop
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_icon, { name: "fas fa-check" })
                            ]),
                            _: 1
                          })
                        ], 64))
                      : _createCommentVNode("", true)
                  ], 64))
            ], 2),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives((_openBlock(), _createBlock(_component_q_btn, {
                rounded: "",
                outline: ""
              }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode("Abbrechen")
                ])),
                _: 1
              })), [
                [_directive_close_popup]
              ]),
              _createVNode(_component_q_btn, {
                color: "primary",
                unelevated: "",
                rounded: "",
                onClick: save
              }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode("Speichern")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _withDirectives((_openBlock(), _createBlock(_component_q_btn, {
            color: "white",
            "text-color": "black",
            unelevated: "",
            round: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "fas fa-close" })
            ]),
            _: 1
          })), [
            [_directive_close_popup]
          ])
        ])
      ])
    ]),
    _: 1
  }, 512))
}
}

})