import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  cellspacing: "0",
  cellpadding: "0",
  class: "optional-prices"
}
const _hoisted_2 = {
  class: "text-bold no-wrap",
  style: {"white-space":"nowrap"}
}
const _hoisted_3 = { class: "q-pl-md" }

import {PropType} from 'vue';

interface TableItem {
  price: number
  interval: string
  title: string
  description?: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductsTable',
  props: {
  products: {
    type: Object as PropType<Array<TableItem>>,
    required: true
  }
},
  setup(__props) {




return (_ctx: any,_cache: any) => {
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    class: "overflow-hidden",
    bordered: "",
    flat: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("table", _hoisted_1, [
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.products, (item, i) => {
            return (_openBlock(), _createElementBlock("tr", { key: i }, [
              _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.$filters.currency({value: item.price})) + " / " + _toDisplayString(item.interval), 1),
              _createElementVNode("td", _hoisted_3, [
                _createElementVNode("b", null, _toDisplayString(item.title), 1),
                (item.description)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" - " + _toDisplayString(item.description), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ])
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}
}

})