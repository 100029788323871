import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex column q-gutter-y-md"
}
const _hoisted_2 = { class: "col-12 font-fjalla-one q-pl-sm text-uppercase q-mb-sm" }
const _hoisted_3 = { class: "flex q-gutter-sm" }
const _hoisted_4 = {
  key: 0,
  class: "bg-grey-2 q-py-xs q-px-sm"
}
const _hoisted_5 = { class: "row q-col-gutter-x-sm items-center" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "col-3" }
const _hoisted_8 = { class: "col-3" }
const _hoisted_9 = { class: "flex no-wrap items-center justify-between q-gutter-x-sm text-small" }
const _hoisted_10 = { class: "flex no-wrap items-center q-gutter-x-sm" }

import {useDialogPluginComponent} from 'quasar';
import {computed, ref, toRef} from 'vue';
import {useMainStore} from 'stores/main';
import {extraMapping, getChipLabelExtraFigureKeys, randomIntFromInterval} from 'src/etc/helper';
import BaseDialogContent from 'src/apps/core/components/BaseDialogContent.vue';
import {useSearchStore} from 'src/apps/search/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'ExtrasFilterDialog',
  props: {
  modelValue: {required: true, type: Object},
},
  emits: [...useDialogPluginComponent.emits],
  setup(__props) {

// REQUIRED; must be called inside of setup()
const {dialogRef, onDialogHide, onDialogOK} = useDialogPluginComponent()
// dialogRef      - Vue ref to be applied to QDialog
// onDialogHide   - Function to be used as handler for @hide on QDialog
// onDialogOK     - Function to call to settle dialog with "ok" outcome
//                    example: onDialogOK() - no payload
//                    example: onDialogOK({ /*.../* }) - with payload
// onDialogCancel - Function to call to settle dialog with "cancel" outcome

const props = __props


const searchStore = useSearchStore()
const filterData = toRef(props, 'modelValue')
const extras = ref()
const extrasLoading = ref(true)
const mainStore = useMainStore()


extras.value = mainStore.vehicleExtras
extras.value = extras.value.sort(function (a, b) {
  return b.name.toLowerCase() < a.name.toLowerCase()
})
const sections = computed(() => {
  return extraMapping(mainStore.vehicleExtras)
})
extrasLoading.value = false

const toggleFilterValue = function (value) {
  if (!filterData.value?.vehicle__extras__slug) {
    filterData.value.vehicle__extras__slug = []
  }

  if (filterData.value.vehicle__extras__slug.includes(value)) {
    const i = filterData.value.vehicle__extras__slug.indexOf(value)
    filterData.value.vehicle__extras__slug.splice(i, 1)

    if (getChipLabelExtraFigureKeys(value) !== undefined) {
      const keys = getChipLabelExtraFigureKeys(value)
      if (!keys) return
      filterData.value[keys.minKey] = undefined
      filterData.value[keys.maxKey] = undefined
    }

  } else {
    filterData.value.vehicle__extras__slug.push(value)
  }
}


return (_ctx: any,_cache: any) => {
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref_key: "dialogRef",
    ref: dialogRef,
    maximized: _ctx.$q.screen.lt.sm,
    "transition-show": "slide-up",
    "transition-hide": "slide-down",
    onHide: _unref(onDialogHide)
  }, {
    default: _withCtx(() => [
      _createVNode(BaseDialogContent, null, {
        default: _withCtx(() => [
          (!extrasLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sections.value, (sec) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: sec.name
                  }, [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(sec.name), 1),
                    _createElementVNode("div", _hoisted_3, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sec.extras, (extra) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: extra.value
                        }, [
                          _createElementVNode("div", null, [
                            _createVNode(_component_q_chip, {
                              color: filterData.value?.vehicle__extras__slug?.includes(extra.value) ? 'positive' : '',
                              "text-color": filterData.value?.vehicle__extras__slug?.includes(extra.value) ? 'white' : '',
                              clickable: "",
                              onClick: ($event: any) => (toggleFilterValue(extra.value))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(extra.icon) + " " + _toDisplayString(extra.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["color", "text-color", "onClick"])
                          ]),
                          (_unref(getChipLabelExtraFigureKeys)(extra.value) && filterData.value.vehicle__extras__slug?.includes(extra.value))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                _createElementVNode("div", _hoisted_5, [
                                  _createElementVNode("div", _hoisted_6, _toDisplayString(extra.icon) + " " + _toDisplayString(extra.name) + " in " + _toDisplayString(_unref(getChipLabelExtraFigureKeys)(extra.value)?.unit), 1),
                                  _createElementVNode("div", _hoisted_7, [
                                    _createVNode(_component_q_input, {
                                      modelValue: filterData.value[_unref(getChipLabelExtraFigureKeys)(extra.value)?.minKey ?? ''],
                                      "onUpdate:modelValue": ($event: any) => ((filterData.value[_unref(getChipLabelExtraFigureKeys)(extra.value)?.minKey ?? '']) = $event),
                                      dense: "",
                                      label: "von",
                                      max: "99999",
                                      min: "0",
                                      outlined: "",
                                      steps: "1",
                                      type: "number"
                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                  ]),
                                  _createElementVNode("div", _hoisted_8, [
                                    _createVNode(_component_q_input, {
                                      modelValue: filterData.value[_unref(getChipLabelExtraFigureKeys)(extra.value)?.maxKey ?? ''],
                                      "onUpdate:modelValue": ($event: any) => ((filterData.value[_unref(getChipLabelExtraFigureKeys)(extra.value)?.maxKey ?? '']) = $event),
                                      dense: "",
                                      label: "bis",
                                      max: "99999",
                                      min: "1",
                                      outlined: "",
                                      steps: "1",
                                      type: "number"
                                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                  ])
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(20, (i) => {
                return _createVNode(_component_q_skeleton, {
                  key: i,
                  type: 'QChip',
                  width: `${_unref(randomIntFromInterval)(100,200)}px`,
                  dense: ""
                }, null, 8, ["width"])
              }), 64))
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_9, [
            _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1)),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", null, [
                (!_unref(searchStore).searchLoading)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(_unref(searchStore).searchResultCount) + " Ergebnisse", 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_q_btn, {
                unelevated: "",
                "no-caps": "",
                color: "primary",
                onClick: _unref(onDialogOK)
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" Anzeigen ")
                ])),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["maximized", "onHide"]))
}
}

})