import {defineStore} from 'pinia';
import Adverts from 'src/api/adverts';

export const useAdvertStore = defineStore('adverts', {

  state: () => ({
    advertOptions: undefined as any | undefined,
  }),

  actions: {
    fetchAdvertOptions() {
      if (this.advertOptions !== undefined) return new Promise(resolve => resolve(this.advertOptions))

      return Adverts.getAdvertOptions().then(resp => {
        this.advertOptions = resp.data?.actions?.POST || resp.data?.actions?.PUT || resp.data?.actions?.PATCH
      }).catch(err => {
        console.error(err)
      })
    }
  }
})
