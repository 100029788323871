import {InjectionKey, Ref} from 'vue';
import {SearchType} from 'src/apps/search/types';

export const searchSymbol: InjectionKey<SearchInject> = Symbol('search')


export interface SearchInject {
  searchType: Ref<SearchType>,
  updateSearchType: (searchType: any) => void,
}
