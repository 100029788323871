import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "q-my-lg bg-grey-2" }
const _hoisted_2 = { class: "container q-pb-md" }
const _hoisted_3 = { class: "row justify-center q-col-gutter-md" }
const _hoisted_4 = {
  class: "row col-grow justify-center",
  style: {"gap":"16px"}
}
const _hoisted_5 = { class: "col-shrink" }
const _hoisted_6 = { class: "container" }
const _hoisted_7 = { class: "row q-col-gutter-x-md" }
const _hoisted_8 = { class: "col-12 col-md-3" }
const _hoisted_9 = {
  key: 1,
  class: "q-mb-md"
}
const _hoisted_10 = {
  key: 2,
  class: "q-mt-md"
}
const _hoisted_11 = { class: "col relative-position" }
const _hoisted_12 = { class: "row q-col-gutter-y-sm" }
const _hoisted_13 = {
  key: 0,
  class: "col-12"
}
const _hoisted_14 = { class: "text-h3" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "col-12" }
const _hoisted_17 = {
  key: 0,
  class: "text-h4"
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "flex justify-between items-center" }
const _hoisted_20 = { style: {"max-width":"50%"} }
const _hoisted_21 = { style: {"max-width":"50%"} }
const _hoisted_22 = {
  key: 1,
  class: "col-12"
}
const _hoisted_23 = { class: "items-center column q-my-xl" }
const _hoisted_24 = {
  key: 0,
  class: "q-my-xl q-py-xl text-center full-width"
}
const _hoisted_25 = { class: "q-my-md" }
const _hoisted_26 = {
  key: 0,
  class: "flex justify-center q-my-lg"
}
const _hoisted_27 = {
  key: 1,
  class: "q-mt-xl text-center"
}

import {computed, onMounted, provide, readonly, ref, watch} from 'vue';
import Adverts from 'src/api/adverts';
import type {CustomPagination} from 'src/models/qutils/etc';
import FilterMenu from 'src/components/FilterMenu.vue';
import FilterListItem from 'src/components/adverts/FilterListItem.vue';
import {useRoute, useRouter} from 'vue-router';
import {debounce, LocalStorage, useMeta} from 'quasar';
import LocationField from 'src/components/fields/LocationField.vue';
import {radiusOptions} from 'src/pages/IndexPage.vue';
import {useMainStore} from 'stores/main';
import SearchAgentButton from 'src/components/SearchAgentButton.vue';
import axios from 'axios';
import {getPageUrl} from 'src/etc/helper';
import deepmerge from 'deepmerge';
import ActiveFilterBadges from 'components/adverts/ActiveFilterBadges.vue';



export const orderingOptions = [
  {value: '-created_at', label: 'Neueste zuerst'},
  {value: 'created_at', label: 'Ältesten zuerst'},
  {value: '-price', label: 'Preis - Absteigend'},
  {value: 'price', label: 'Preis - Aufsteigend'},
  {value: '-vehicle__year', label: 'Baujahr - Absteigend'},
  {value: 'vehicle__year', label: 'Baujahr - Aufsteigend'},
  {value: '-vehicle__mileage', label: 'Kilometerstand - Absteigend'},
  {value: 'vehicle__mileage', label: 'Kilometerstand - Aufsteigend'},
];


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterPage',
  setup(__props) {

const isInitChange = ref(true);
const route = useRoute();
const router = useRouter();
const mainStore = useMainStore();
const resultsCount = ref();
provide('resultsCount', readonly(resultsCount));

const currentPage = ref(1);

const searchLocation = ref(route.query.l?.toString());
const showFilter = ref(false);
const searchRadius = ref(route.query.r?.toString() || '100');
const filter = ref(
  deepmerge(mainStore.filter, {
    ordering: route.query?.ordering,
  })
);
const loadingAdverts = ref(true);
const advertsResponse = ref<CustomPagination | undefined>();
const CancelToken = axios.CancelToken;
let cancel;
let loadingTimeout;

const fetchFilteredAdverts = debounce(() => {
  loadingTimeout = setTimeout(() => {
    loadingAdverts.value = true;
  }, 400);
  if (cancel) {
    cancel();
  }

  Adverts.getAdverts(
    {...filter.value, page: currentPage.value},
    new CancelToken(function executor(c) {
      // An executor function receives a cancel function as a parameter
      cancel = c;
    })
  )
    .then((resp) => {
      advertsResponse.value = resp.data;
      resultsCount.value = advertsResponse.value?.count;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        console.debug('canceled');
      } else {
        console.error(err);
      }
    })
    .finally(() => {
      clearTimeout(loadingTimeout);
      loadingAdverts.value = false;
    });
}, 400);

onMounted(() => {
  filter.value['l'] = searchLocation.value;
  filter.value['r'] = searchRadius.value;
  if (LocalStorage.getItem('searchLimit')) {
    filter.value['limit'] = LocalStorage.getItem('searchLimit');
  }
});

watch(
  () => filter.value,
  () => {
    fetchFilteredAdverts();
    if (isInitChange.value === false) {
      currentPage.value = 1;
    }
    isInitChange.value = false;

    if (filter.value['ordering']) {
      router.push({
        query: {
          ...route.query,
          ...{ordering: filter.value['ordering']},
        },
      });
    }

    mainStore.setFilter(filter.value);
  },
  {deep: true, immediate: true}
);

watch(
  () => currentPage.value,
  () => {
    window.scrollTo(0, 0);
  });

const setFilterFromAgentData = async function () {
  if (!route.query.f_id) return;
  Adverts.getAdvertSearchAgent(route.query.f_id)
    .then((resp) => {
      filter.value = resp.data.filter_data;
    })
    .catch((err) => {
      console.error(err);
    });
};

if (route.query.f_id) {
  setFilterFromAgentData();
} else {
  filter.value = mainStore.filter;
}

const changePageSize = function (size) {
  LocalStorage.set('searchLimit', size);
};

const newLocationOnSubmit = () => {
  if (!searchLocation.value) return;
  router.push({
    query: {l: searchLocation.value, r: searchRadius.value},
  });
  filter.value['l'] = searchLocation.value;
  filter.value['r'] = searchRadius.value;
};

const reset = function () {
  searchLocation.value = undefined;
  searchRadius.value = '100';
  newLocationOnSubmit();
  filter.value = {} as any;
};

function sanitizeParam(param) {
  const sanitizedParam = param.replace(/[^\w.-äöüÄÖÜß]/g, '');
  return sanitizedParam.replace(/(<([^>]+)>)/gi, '');
}

const paramCarType = computed(() => {
  if (!route.params?.carType) return;
  const _carType = sanitizeParam(route.params.carType.toString());
  return _carType.charAt(0).toUpperCase() + _carType.slice(1);
});

const paramCity = computed(() => {
  if (!route.params?.city) return;
  let _city = sanitizeParam(route.params.city?.toString());
  return _city.charAt(0).toUpperCase() + _city.slice(1);
});

const checkLandingPage = function () {
  if (paramCity.value) {
    searchLocation.value = paramCity.value;
    searchRadius.value = '50';
  }

  if (paramCarType.value) {
    filter.value['vehicle__car_type'] = [route.params.carType];
  }
};
checkLandingPage();

useMeta(() => {
  let description =
    'Finde den perfekten Campervan. ' +
    'Ob Solaranlage, Dusche, Ofen, Gastank oder Markise, ' +
    'mit unseren Such-Filtern findest du genau das was du suchst.';

  let _title = 'Campervan finden';
  let city = '';

  if (paramCity.value) {
    city = paramCity.value
    city = ' in ' + city

    _title = `Gebrauchte Camper${city}`

    description =
      `Finde gebrauchte Camper${city} mit unserem ` +
      'Filter-Tool. Ob Solar, WC, Dusche und Toilette, finde den perfekten Match!'
  }

  if (paramCarType.value) {
    _title = `${paramCarType.value} gebraucht`;

    if (paramCity.value) {
      _title = `${_title}${city}`;
      description = `Suchst du nach einem ${paramCarType.value}? ${description}`
    }

    description =
      `Suchst du nach einem ${paramCarType.value}? Finde gebrauchte Camper${city} mit unserem ` +
      'Filter-Tool. Ob Solar, WC, Dusche und Toilette, finde den perfekten Match!';
  }

  let canonicalLink = getPageUrl(route.name, router) || '';

  return {
    // sets document title
    titleTemplate(title) {
      return `${_title} | ${title}`;
    },

    // meta tags
    meta: {
      description: {
        name: 'description',
        content: description,
      },

      ogTitle: {
        property: 'og:title',
        // optional; similar to titleTemplate, but allows templating with other meta properties
        template() {
          return `${_title} auf Camper Trader`;
        },
      },
    },

    link: {
      canonical: {
        rel: 'canonical',
        href: canonicalLink,
      },
    },

    noscript: {
      default: 'Please enable Javascript to use this website.',
    },
  };
});


return (_ctx: any,_cache: any) => {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_q_spinner_dots = _resolveComponent("q-spinner-dots")!
  const _component_q_pagination = _resolveComponent("q-pagination")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, { class: "q-mb-lg" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_form, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(LocationField, {
                    modelValue: filter.value.l,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filter.value.l) = $event)),
                    class: "col-grow col-sm",
                    dense: "",
                    outlined: "",
                    "onUpdate:location": _cache[1] || (_cache[1] = val => filter.value['l'] = val)
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_q_select, {
                      modelValue: filter.value.r,
                      "onUpdate:modelValue": [
                        _cache[2] || (_cache[2] = ($event: any) => ((filter.value.r) = $event)),
                        _cache[3] || (_cache[3] = val => filter.value['r'] = val)
                      ],
                      options: _unref(radiusOptions),
                      borderless: "",
                      dense: "",
                      label: "Radius",
                      suffix: "km",
                      disable: !searchLocation.value
                    }, null, 8, ["modelValue", "options", "disable"])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("main", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_ctx.$q.screen.gt.sm || showFilter.value)
              ? (_openBlock(), _createBlock(_component_q_card, {
                  key: 0,
                  bordered: "",
                  flat: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_card_section, null, {
                      default: _withCtx(() => [
                        _createVNode(FilterMenu, {
                          modelValue: filter.value,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((filter).value = $event)),
                          onResetFilter: reset
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!_ctx.$q.screen.gt.sm)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_q_btn, {
                    class: "full-width",
                    outline: "",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (showFilter.value = !showFilter.value))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Filter " + _toDisplayString(showFilter.value ? 'Ausblenden' : 'Anzeigen'), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.$q.screen.gt.sm)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(SearchAgentButton, {
                    class: "full-width",
                    outline: false,
                    filter: filter.value
                  }, null, 8, ["filter"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              (paramCarType.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("h1", _hoisted_14, [
                      _createTextVNode(" Ergebnisse für \"" + _toDisplayString(paramCarType.value) + "\"", 1),
                      (paramCity.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, " im Umkreis von " + _toDisplayString(paramCity.value), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("p", null, " Du bist auf der Suche nach einem " + _toDisplayString(paramCarType.value) + " oder willst vielleicht selber ausbauen? Dann bist du hier genau richtig! Wir bieten eine große Auswahl an hochwertigen Fahrzeugen von privaten und gewerblichen Anbietern, die perfekt für dein individuelles Abenteuer geeignet sind. Egal, ob du einen geräumigen Familien-Campervan, einen kompakten Van für kurze Trips, ein Fahrzeug zum selber ausbauen oder ein Wohnmobil suchst, wir haben das passende Fahrzeug. ", 1),
                    _cache[12] || (_cache[12] = _createElementVNode("h4", null, " Nutze unsere Filter um dir direkt dein Traumfahrzeug anzeigen zu lassen. ", -1))
                  ]))
                : _createCommentVNode("", true),
              _createVNode(ActiveFilterBadges, {
                modelValue: filter.value,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((filter).value = $event)),
                class: "col-12",
                removable: ""
              }, null, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_16, [
                (advertsResponse.value)
                  ? (_openBlock(), _createElementBlock("h1", _hoisted_17, _toDisplayString(advertsResponse.value.count) + " Angebote entsprechen Deinen Suchkriterien ", 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      _createVNode(_component_q_skeleton, {
                        type: "text",
                        width: "120px"
                      })
                    ])),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_component_q_select, {
                      modelValue: filter.value.ordering,
                      "onUpdate:modelValue": [
                        _cache[7] || (_cache[7] = ($event: any) => ((filter.value.ordering) = $event)),
                        _unref(fetchFilteredAdverts)
                      ],
                      options: orderingOptions,
                      dense: "",
                      "emit-value": "",
                      label: "Sortierung",
                      "map-options": "",
                      outlined: "",
                      style: {"width":"200px","max-width":"100%"}
                    }, null, 8, ["modelValue", "onUpdate:modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_q_select, {
                      modelValue: filter.value.limit,
                      "onUpdate:modelValue": [
                        _cache[8] || (_cache[8] = ($event: any) => ((filter.value.limit) = $event)),
                        _cache[9] || (_cache[9] = 
                        (val) => {
                          _unref(fetchFilteredAdverts);
                          changePageSize(val);
                        }
                      )
                      ],
                      options: [10, 20, 50, 100],
                      dense: "",
                      label: "Pro Seite",
                      outlined: "",
                      style: {"width":"130px","max-width":"100%"}
                    }, null, 8, ["modelValue"])
                  ])
                ])
              ]),
              (loadingAdverts.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      _createVNode(_component_q_spinner_dots, {
                        class: "changeTextColor",
                        size: "4rem"
                      }),
                      _cache[13] || (_cache[13] = _createElementVNode("div", {
                        class: "text-muted",
                        style: {"margin-top":"-1rem"}
                      }, " Suche ... ", -1))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!loadingAdverts.value && advertsResponse.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(advertsResponse.value.results, (advert) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: advert.uuid,
                        class: "col-12"
                      }, [
                        (_openBlock(), _createBlock(FilterListItem, {
                          key: advert.uuid,
                          advert: advert,
                          loading: loadingAdverts.value
                        }, null, 8, ["advert", "loading"]))
                      ]))
                    }), 128)),
                    (!loadingAdverts.value && !advertsResponse.value?.results?.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "text-h2" }, "🙃", -1)),
                          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "text-bold" }, "Keine passenden Inserate gefunden.", -1)),
                          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "q-mt-xl" }, [
                            _createElementVNode("i", null, [
                              _createTextVNode("Tipp: Erstelle einen Suchauftrag um sofort benachrichtigt zu werden, "),
                              _createElementVNode("br"),
                              _createTextVNode("sobald ein passendes Inserat hinzugefügt wird.")
                            ])
                          ], -1)),
                          _createElementVNode("div", _hoisted_25, [
                            _createVNode(SearchAgentButton, { filter: filter.value }, null, 8, ["filter"])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            (!loadingAdverts.value && advertsResponse.value?.pages > 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                  _createVNode(_component_q_pagination, {
                    modelValue: currentPage.value,
                    "onUpdate:modelValue": [
                      _cache[10] || (_cache[10] = ($event: any) => ((currentPage).value = $event)),
                      _cache[11] || (_cache[11] = 
                () => {
                  _unref(fetchFilteredAdverts)();
                  loadingAdverts.value = true;
                }
              )
                    ],
                    input: _ctx.$q.screen.width < 382,
                    max: advertsResponse.value.pages,
                    "max-pages": 1,
                    "direction-links": "",
                    flat: "",
                    padding: "",
                    unelevated: ""
                  }, null, 8, ["modelValue", "input", "max"])
                ]))
              : _createCommentVNode("", true),
            (!_ctx.$q.screen.gt.sm && advertsResponse.value?.results?.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                  _cache[17] || (_cache[17] = _createElementVNode("div", null, "Gerade nichts passendes dabei? Sofort benachrichtigt werden mit Suchaufträgen", -1)),
                  _createVNode(SearchAgentButton, {
                    label: "Suchauftrag erstellen",
                    filter: filter.value
                  }, null, 8, ["filter"])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})