import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

import {computed} from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'WhatsAppChatBtn',
  props: {
  number: {
    required: true,
    type: String
  },
  predefinedText: {
    required: false,
    type: String,
    default: undefined
  }
},
  setup(__props) {

const props = __props

const url = computed(() => {
  if (!props.number) return ''
  let _text = ''
  if (props.predefinedText) {
    _text = `?text=${encodeURI(props.predefinedText)}`
  }
  return `https://wa.me/${props.number}${_text}`
})

return (_ctx: any,_cache: any) => {
  const _component_q_img = _resolveComponent("q-img")!

  return (_openBlock(), _createElementBlock("a", {
    href: url.value,
    rel: "external nofollow noreferrer noopener",
    style: {"width":"100%","display":"inline-block"},
    target: "_blank"
  }, [
    _createElementVNode("div", null, [
      _createVNode(_component_q_img, {
        width: "100%",
        height: "100%",
        src: require('src/assets/etc/WhatsAppButtonGreenMedium.svg')
      }, null, 8, ["src"])
    ])
  ], 8, _hoisted_1))
}
}

})