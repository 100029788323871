import script from "./DealerSearchFilter.vue?vue&type=script&setup=true&lang=ts"
export * from "./DealerSearchFilter.vue?vue&type=script&setup=true&lang=ts"

import "./DealerSearchFilter.vue?vue&type=style&index=0&id=40c7ae16&lang=scss"

const __exports__ = script;

export default __exports__
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QSpinner,QChip,QBtn,QSelect});
