import SearchAgentDialog from 'src/apps/search/components/dialogs/SearchAgentDialog.vue'
import {showLoginDialog} from 'src/etc/helper'
import Adverts from 'src/api/adverts'
import VehicleSearchFilter from 'src/apps/search/components/searchFilter/VehicleSearchFilter.vue'
import DealerSearchFilter from 'src/apps/search/components/searchFilter/DealerSearchFilter.vue'
// import MarketSearchFilter from 'src/apps/search/components/searchFilter/MarketSearchFilter.vue'

export const searchTypeMapping = {
  // explore: {
  //   label: 'Erkunden',
  //   icon: 'fas fa-search',
  // },
  vehicle: {
    label: 'Fahrzeuge',
    icon: 'fas fa-car',
  },
  dealer: {
    label: 'Dienstleister',
    icon: 'fas fa-store',
  },
  market: {
    label: 'Flohmarkt',
    icon: 'fas fa-tags',
  },
}


export function openSearchAgentDialog($q, searchAgentUuid) {
  $q.dialog({
    component: SearchAgentDialog,
    componentProps: {
      searchAgentUuid,
    }
  })
}


export function getFilterItems(filterData: object, mainStore, includeSearch = false) {
  const filterMapping = mainStore.filterMapping || {}
  const badges: Array<object> = []

  // get keys of filter object while iterating through them
  for (const [key, value] of Object.entries(filterData)) {
    // check if value is empty even if its a string or array or object
    if (value === undefined || value === null || (Array.isArray(value) && value.length === 0) || (typeof value === 'object' && Object.keys(value).length === 0)) {
      continue
    }

    if (key === 'r') continue
    if (key === 'l' && filterData['r']) {
      badges.push({
        key: key,
        label: `${value} (Umkreis ${filterData['r']} km)`,
        value: value,
      })
      continue
    }

    if (key === 'is_negotiable' && value === false) {
      badges.push({
        key: key,
        label: 'Festpreis',
      })
      continue
    }


    if (key === 'is_business' && value === false) {
      badges.push({
        key: key,
        label: 'Privatverkauf',
      })
      continue
    }

    // if is extras
    if (key === 'vehicle__extras__slug') {
      value.forEach(slug => {
        const extra = mainStore.vehicleExtras.find(obj => obj['value'] === slug)
        if (!extra) return
        badges.push({
          key: key,
          label: `${extra['icon']} ${extra['name']}`,
          value: slug,
        })
      })
      continue
    }

    if (includeSearch && key === 's' && value !== '') {
      badges.push({
        key: key,
        label: `Suche: ${value}`,
        value: value,
      })
      continue
    }

    if (Array.isArray(value)) {
      value.forEach(obj => {
        let displayName = obj
        if (filterMapping[key].choices) {
          displayName = filterMapping[key].choices[obj]
        }
        const label = `${filterMapping[key].label}: ${displayName}`
        badges.push({
          key: key,
          label: label,
          value: obj,
        })
      })
    } else {
      let label
      if (!filterMapping[key]?.label) {
        label = `${key}`
      } else {
        // check value is boolean
        if (value === '') {
          continue
        } else if (typeof value === 'boolean') {
          // for booleans
          label = `${filterMapping[key].label}`
        } else {
          // all other
          let _val = value
          if (Number.isInteger(value)) {
            let opts = {}
            if (key.includes('price')) {
              opts = {style: 'currency', currency: 'EUR', maximumFractionDigits: 0}
            }
            _val = new Intl.NumberFormat('de-DE', opts).format(value)
          }
          label = `${filterMapping[key].label}: ${_val}`
        }
      }

      if (!Object.keys(filterMapping).includes(key)) continue

      badges.push({
        key: key,
        label: label,
        value: value,
      })
    }
  }

  // move all badges with key 'vehicle__extras__slug' to the end of the array badges
  const badgesWithExtras = badges.filter(obj => obj['key'] === 'vehicle__extras__slug')
  const badgesWithoutExtras = badges.filter(obj => obj['key'] !== 'vehicle__extras__slug')
  return [...badgesWithoutExtras, ...badgesWithExtras]
}


export function createSearchAgent($q, mainStore, subStore, filterData, $router) {
  if (!mainStore.isLoggedIn) {
    $q.dialog({
      title: 'Anmeldung erforderlich',
      message: 'Suchaufträge sind dein persönlicher Alarm, der dich sofort benachrichtigt, wenn ein Camper, der genau deinen ' +
        'Suchkriterien entspricht, zum Kauf angeboten wird.',
      cancel: true,
      ok: {
        label: 'Anmelden',
        color: 'primary',
      },
    }).onOk(() => {
      showLoginDialog()
    })
    return
  }

  if (subStore.accountQuota.current_usage.search_agent_limit >= subStore.accountQuota.limits.search_agent_limit) {
    $q.notify({
      message: 'Du hast bereits die maximale Anzahl an Suchaufträgen erstellt.',
      actions: [{label: 'Ansehen', color: 'white', handler: () => $router.push({name: 'search-agents'})}]
    })
    return
  }

  $q.dialog({
    title: 'Suchauftrag erstellen',
    message: 'Suchaufträge sind dein persönlicher Alarm, der dich sofort benachrichtigt, wenn ein Camper, der genau deinen ' +
      'Suchkriterien entspricht, zum Kauf angeboten wird.',
    prompt: {
      model: '',
      type: 'text',
      label: 'Name für deinen Suchauftrag',
      placeholder: 'z.B. "In München mit Solaranlage"',
    },
    cancel: true,
    ok: {
      label: 'Erstellen',
      color: 'primary',
    },
  }).onOk(data => {
    Adverts.setAdvertSearchAgent(filterData, data).then(() => {
      $q.notify({
        message: 'Suchauftrag erfolgreich erstellt',
        type: 'positive',
        actions: [{label: 'Ansehen', color: 'white', handler: () => $router.push({name: 'search-agents'})}]
      })
    }).catch(() => {
      $q.notify({
        message: 'Suchaufträge anzeigen',
        actions: [{label: 'Ansehen', color: 'white', handler: () => $router.push({name: 'search-agents'})}]
      })
    })
  })
}


export const searchFilterComponentMapping = {
  'explore': undefined,
  'vehicle': VehicleSearchFilter,
  'dealer': DealerSearchFilter,
  'market': undefined, //MarketSearchFilter,
}


export interface AutocompleteResponse {
  text: string
  input?: string
  source?: string
}

export abstract class SearchAdapter {
  abstract fetchAutocomplete(data: any): Promise<Array<AutocompleteResponse>>

  abstract fetchHistory(): Promise<Array<any>>
}
