import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "column q-gutter-y-lg" }
const _hoisted_3 = { class: "hero-section" }
const _hoisted_4 = { class: "row q-col-gutter-xl items-center" }
const _hoisted_5 = { class: "col-12 col-md-5" }
const _hoisted_6 = { class: "lt-md q-mt-sm" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "q-mt-xl text-center" }
const _hoisted_9 = { class: "q-mt-md text-center" }
const _hoisted_10 = {
  class: "gt-sm col-sm-7",
  style: {"padding-bottom":"3rem","padding-top":"8rem"}
}
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 0,
  style: {"margin":"5rem 0"}
}
const _hoisted_13 = { class: "row q-col-gutter-xl" }
const _hoisted_14 = { class: "flex no-wrap q-gutter-md" }
const _hoisted_15 = { class: "text-h3 font-fjalla-one" }
const _hoisted_16 = { class: "text-subtitle1" }
const _hoisted_17 = { class: "" }
const _hoisted_18 = { class: "q-mt-xl q-pb-xl" }
const _hoisted_19 = { class: "row q-col-gutter-lg" }
const _hoisted_20 = { class: "flex items-center no-wrap q-gutter-md" }
const _hoisted_21 = { class: "text-uppercase" }
const _hoisted_22 = { class: "flex column q-gutter-y-lg items-center" }
const _hoisted_23 = { class: "text-subtitle1" }
const _hoisted_24 = { class: "text-subtitle1" }
const _hoisted_25 = { style: {"width":"170px"} }
const _hoisted_26 = { class: "absolute-top-right" }

import {ref} from 'vue';
import WhatsAppChatBtn from 'components/etc/WhatsAppChatBtn.vue';
import {useMeta} from 'quasar';
import {useRouter} from 'vue-router';
import SecureEmail from 'components/qutils/SecureEmail.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BusinessLandingPage',
  setup(__props) {


const features = [
  {
    label: 'Moderne Widget-LÖsung für Ihre Webseite',
    icon: 'fas fa-code',
  },
  {
    label: 'Statistiken & Auswertungen',
    icon: 'fas fa-chart-line',
  },
  {
    label: 'ZielgerichteteS Verkaufen',
    icon: 'fas fa-hand-holding-dollar',
  },
  {
    label: 'Wir sind für sie da! Blitzschneller Support',
    icon: 'fas fa-headset',
  },
  {
    label: 'ZUGANG zuR wachsenden Community',
    icon: 'fas fa-people-roof',
  },
  {
    label: 'Unkomplizerte & Effiziente Bedienung',
    icon: 'fas fa-face-smile',
  },
  {
    label: 'Periodische Updates & Verbesserungen',
    icon: 'fas fa-code',
  },
  {
    label: 'Einfaches Importieren & Exportieren',
    icon: 'fas fa-file-export',
  },
  {
    label: 'Individuelle Lösungen für Dein Problem',
    icon: 'fas fa-box',
  },
]

const userStories = [
  {
    name: 'Hannes Camper',
    caption: 'Tim Aster',
    image: require('src/assets/logos/hannes_camper_logo.jpg'),
    text: 'Sandra und Dominik von Camper Trader sind nicht nur inspirierende Gründer, sondern auch super feine Menschen. Daher macht uns die Kooperation und das gemeinsame Wachstum einfach Spaß. Schnelle, agile Wünsche wie Widgets und übersichtliche Filterlisten wurden super schnell umgesetzt. Wir sind super happy!',
  },
  {
    name: 'Köllefornia Camper',
    caption: 'Klaus Nachtsheim',
    image: require('src/assets/logos/koellefornia_logo.jpg'),
    text: 'Camper Trader ist genau die Plattform, die Käufer oder Verkäufer von Campern brauchen - übersichtlich, nutzerfreundlich und es können Camper zielgerichtet präsentiert und gesucht werden. Uns gefällt besonders der persönliche Kontakt zu den Gründern Sandra und Dominik, gelebte Camper Community. Wir freuen uns auf die weitere Zusammenarbeit!'
  },
  {
    name: 'HappyCampers2019',
    caption: 'Christian Klug',
    image: require('src/assets/logos/happycampers2019_logo.jpg'),
    text: 'Es ist super, dass wir nun zusätzlich eine Plattform haben, die es uns ermöglicht, unsere Camper bestmöglich unserer Zielgruppe zu präsentieren. Vor allem aber überzeugt die einfache und moderne Widget-Lösung.'
  },
  {
    name: 'VANTOPIA',
    caption: 'Bastian Gembler',
    image: require('src/assets/logos/vantopia-campervermietung-logo-rund.webp'),
    text: 'Man spürt, dass bei Camper Trader echte Experten mit echter Leidenschaft am Werk sind! Die Plattform ist modern, nutzerfreundlich für „beide Seiten“ und mit viel Liebe zum Detail umgesetzt. Dass wir über Camper Trader bereits nach 1-2 Monaten total viele sehr gute Kontakte mit sympathischen InteressentInnen machen konnten, wundert uns nicht!'
  },
  {
    name: 'Sandra Tröndle',
    caption: 'Camper Trader',
    image: require('src/assets/sandra_square.jpeg'),
    text: 'Mit CamperTrader.de bringen wir Käufer und Verkäufer auf einer Plattform zusammen. Wir sind selbst begeisterte Camper und wissen, wie schwierig es ist, den richtigen Camper zu finden. Wir wollen die Suche nach dem passenden Camper so einfach wie möglich machen.',
  },
]

const contactModal = ref(false)


const demoClick = function () {
  contactModal.value = true
}

const openLiveChat = function () {
  window.open(
    `https://chatwoot.campertrader.org/widget?website_token=${process.env.CHATWOOT_WEBSITE_TOKEN_BUSINESS}&locale=de/`,
    '_blank'
  )
}

const router = useRouter()

useMeta(() => {
  const link = router.resolve({
    name: 'dealer-landing',
  }).fullPath
  return {
    titleTemplate(title) {
      return `Händler werden | ${title}`;
    },

    link: {
      canonical: {
        rel: 'canonical',
        href: link,
      },
    },

    meta: {
      equiv: {
        'http-equiv': 'Content-Type',
        content: 'text/html; charset=UTF-8',
      },

      description: {
        name: 'description',
        template() {
          return 'Das smarte Tool für deinen Camper-Verkauf. Werde Händler und sichere dir Zugang zur wachsenden Campervan Community';
        },
      },
      ogType: {
        property: 'og:type',
        template() {
          return 'og:profile';
        },
      },

      // note: for Open Graph type metadata you will need to use SSR, to ensure page is rendered by the server
      ogTitle: {
        property: 'og:title',

        // optional; similar to titleTemplate, but allows templating with other meta properties
        template() {
          return 'Händler werden auf Camper Trader';
        },
      },
      ogDescription: {
        property: 'og:description',
        template() {
          return 'Das smarte Tool für deinen Camper-Verkauf. Werde Händler und sichere dir Zugang zur wachsenden Campervan Community';
        },
      },

      ogUrl: {
        property: 'og:url',
        template() {
          return `${link}`;
        },
      },

      twitterTitle: {
        property: 'twitter:title',
        template() {
          return 'Händler werden auf Camper Trader';
        },
      },
      twitterDescription: {
        property: 'twitter:description',
        template() {
          return 'Das smarte Tool für deinen Camper-Verkauf. Werde Händler und sichere dir Zugang zur wachsenden Campervan Community';
        },
      },
    },
  } as object;
})


return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_page = _resolveComponent("q-page")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[3] || (_cache[3] = _createElementVNode("div", null, [
                  _createElementVNode("div", { class: "font-fjalla-one text-uppercase text-h3" }, " Dein Partner für den "),
                  _createElementVNode("div", { class: "font-fjalla-one text-uppercase text-h1" }, " Erfolgreichen Verkauf ")
                ], -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("img", {
                    alt: "Camper Trader Demo Screens",
                    src: require('src/assets/pages/business/demo_screen.png'),
                    style: {"width":"100%"}
                  }, null, 8, _hoisted_7)
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "q-mt-xl" }, [
                  _createElementVNode("div", { class: "text-h3 font-fjalla-one text-uppercase" }, "Effizient, Unkompliziert und ZIELGERICHTET Verkaufen. ")
                ], -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_q_btn, {
                    class: "font-fjalla-one",
                    color: "primary",
                    size: "lg",
                    unelevated: "",
                    onClick: demoClick
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("Demo-Termin buchen ")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_q_btn, {
                    class: "font-fjalla-one",
                    color: "primary",
                    size: "lg",
                    outline: "",
                    to: {name: 'pricing-commercial-use-tab'}
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Preisliste ")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("img", {
                  alt: "Camper Trader Demo Screens",
                  src: require('src/assets/pages/business/demo_screen.png'),
                  style: {"width":"100%"}
                }, null, 8, _hoisted_11)
              ])
            ])
          ]),
          (userStories?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(userStories, (story, index) => {
                    return _createElementVNode("div", {
                      key: index,
                      class: "col-12 col-md-6"
                    }, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", null, [
                          _createVNode(_component_q_avatar, { style: {"width":"120px","height":"120px"} }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_img, {
                                src: story.image
                              }, null, 8, ["src"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_15, _toDisplayString(story.name), 1),
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("i", null, _toDisplayString(story.caption), 1)
                          ]),
                          _createElementVNode("div", _hoisted_17, _toDisplayString(story.text), 1)
                        ])
                      ])
                    ])
                  }), 64))
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(features, (feature, index) => {
                return _createElementVNode("div", {
                  key: index,
                  class: "col-12 col-sm-6 col-md-4"
                }, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_q_icon, {
                        name: feature.icon,
                        size: "2rem"
                      }, null, 8, ["name"])
                    ]),
                    _createElementVNode("div", _hoisted_21, _toDisplayString(feature.label), 1)
                  ])
                ])
              }), 64))
            ])
          ])
        ])
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("div", {
        id: "livechat--wrapper",
        style: {"visibility":"hidden"}
      }, null, -1)),
      _createVNode(_component_q_dialog, {
        modelValue: contactModal.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((contactModal).value = $event)),
        persistent: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card, { class: "full-width" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { class: "q-py-lg" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_q_btn, {
                        style: {"width":"170px"},
                        unelevated: "",
                        color: "dark",
                        onClick: openLiveChat
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("Zum Live Chat")
                        ])),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _createVNode(_component_q_icon, { name: "fas fa-phone" }),
                      _cache[6] || (_cache[6] = _createElementVNode("a", { href: "tel:+4915679366374" }, "+49 1567 9366374", -1))
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _createVNode(SecureEmail, {
                        class: "text-bold",
                        subject: "Camper Trader Demo-Termin buchen",
                        email: "info@campertrader.de"
                      })
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createVNode(WhatsAppChatBtn, {
                        number: "4915679366374",
                        "predefined-text": "Camper Trader Demo-Termin buchen"
                      })
                    ])
                  ])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_26, [
                _withDirectives(_createVNode(_component_q_btn, {
                  icon: "close",
                  flat: "",
                  round: ""
                }, null, 512), [
                  [_directive_close_popup]
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
}

})