import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container q-py-lg" }
const _hoisted_2 = { class: "flex column items-center q-mb-xl q-gutter-md" }
const _hoisted_3 = { class: "flex justify-center" }
const _hoisted_4 = { class: "row q-col-gutter-lg justify-center" }
const _hoisted_5 = {
  key: 0,
  class: "col-12"
}
const _hoisted_6 = {
  key: 1,
  class: "col-12"
}

import PricingBox from 'src/apps/core/components/PricingBox.vue';
import {computed, ref} from 'vue';
import {useMainStore} from 'stores/main';
import ProductsTable from 'src/apps/subscription/components/ProductsTable.vue';
import {useSubscriptionStore} from 'src/apps/subscription/store';
import {useQuasar} from 'quasar';
import {useRoute, useRouter} from 'vue-router';
import Payment from 'src/api/payment';
import {CapacitorBrowser} from 'boot/inject-capacitor';
import {openPortal} from 'src/apps/subscription/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'CommercialPlans',
  props: {
  preventClickAction: {
    default: false,
    type: Boolean
  },
  hideButtons: {
    default: false,
    type: Boolean
  },
  hideTitle: {
    default: false,
    type: Boolean
  }
},
  emits: ['btn-click'],
  setup(__props, { emit: __emit }) {

const mainStore = useMainStore()
const subStore = useSubscriptionStore()
const interval = ref<'month' | 'year'>('year')
const $q = useQuasar()
const router = useRouter()
const $route = useRoute()
const btnLoading = ref(false)

const emits = __emit

const props = __props

const getProductPlanPrice = function (product) {
  if (!Array.isArray(product.plans)) return
  let r = {}
  product.plans.forEach(obj => {
    r[obj.interval] = obj.amount
  })
  return r
}
const getProductPlan = function (product) {
  return product.plans.find(obj => obj.interval === interval.value)
}

const subscriptionMetaPlans = computed(() => {
  return mainStore.subscriptionMetaCommercialPlans.filter(obj => {
    if (obj?.product?.plans && obj.product.plans.filter(obj => obj.interval === interval.value).length) return obj
    return false
  })
})

const products = computed(() => {
  return mainStore.commercialProducts?.flatMap(productMeta => {
    return {
      price: productMeta.product.default_price.unit_amount_display,
      interval: productMeta.product.default_price.interval_display,
      title: productMeta.product.name,
      description: productMeta.product.description,
      id: productMeta.id
    }
  })
})

const activePlanId = computed(() => {
  if (!subStore.accountSubscription?.status || !['trialing', 'active'].includes(subStore.accountSubscription?.status))
    return

  return subStore.accountSubscription?.plan?.id
})

const bulletPointsCmp = (subscriptionMeta) => {
  if (!subscriptionMeta) return []
  let r: Array<string> = []
  if (subscriptionMeta.advert_limit > 0) {
    r.push(`Bis ${subscriptionMeta.advert_limit} Inserate`)
  }
  if (subscriptionMeta.active_days_limit) {
    r.push(`Bis zu ${subscriptionMeta.active_days_limit} Tagen aktiv ohne manuell erneuern zu müssen`)
  }
  if (subscriptionMeta?.features?.length) {
    r = [
      ...r,
      ...subscriptionMeta.features?.flatMap(obj => obj.name),
    ]
  }
  return r
}

const hadTrial = computed(() => {
  return mainStore.accountDetail?.active_account?.had_trial
})

function clickPlan(plan) {
  if (props.preventClickAction) {
    emits('btn-click', plan)
    return
  }
  if (!mainStore.isLoggedIn) {
    router.push({
      name: 'login',
    })
    return
  }
  if (!mainStore.accountDetail?.active_account.is_business) {
    router.push({name: 'business-register'})
    return
  }

  if (
    subStore.accountSubscription &&
    ['trialing', 'active'].includes(subStore.accountSubscription.status)
  ) {
    openPortal($q, $route)
    return
  }

  btnLoading.value = true
  Payment.createCheckoutSession(plan.id, $q.platform.is.nativeMobile).then(({data}) => {
    if ($q.platform.is.nativeMobile) {
      CapacitorBrowser.open({url: data})
    } else {
      window.open(data, '_blank')
    }
  }).finally(() => {
    btnLoading.value = false
  })

  emits('btn-click', plan)
}

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_btn_group = _resolveComponent("q-btn-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!__props.hideTitle)
        ? _renderSlot(_ctx.$slots, "title", { key: 0 }, () => [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-h3 font-inter-bold" }, [
              _createTextVNode(" Jährlich abschließen und "),
              _createElementVNode("span", { class: "text-primary" }, "2 Monate kostenlos"),
              _createTextVNode(" sichern! ")
            ], -1))
          ])
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_btn_group, {
          push: "",
          outline: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_btn, {
              class: "tab-btn",
              color: interval.value === 'month' ? 'primary' : 'grey-3',
              "text-color": interval.value === 'month' ? 'white' : 'grey-14',
              push: "",
              "no-caps": "",
              label: "Monatlich",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (interval.value = 'month'))
            }, null, 8, ["color", "text-color"]),
            _createVNode(_component_q_btn, {
              class: "tab-btn",
              color: interval.value === 'year' ? 'primary' : 'grey-3',
              "text-color": interval.value === 'year' ? 'white' : 'grey-14',
              push: "",
              "no-caps": "",
              label: "Jährlich",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (interval.value = 'year'))
            }, null, 8, ["color", "text-color"])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subscriptionMetaPlans.value, (subscriptionMeta, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "col-12 col-sm-6 col-md-4 col-lg-3"
        }, [
          _createVNode(PricingBox, {
            title: subscriptionMeta.name,
            "is-active": activePlanId.value === getProductPlan(subscriptionMeta.product)?.id,
            "chip-label": subscriptionMeta.sub_name,
            description: subscriptionMeta.description,
            prices: getProductPlanPrice(subscriptionMeta.product),
            "bullet-list": bulletPointsCmp(subscriptionMeta),
            "is-bestseller": subscriptionMeta.is_bestseller,
            "btn-loading": btnLoading.value,
            interval: interval.value,
            "hide-buttons": __props.hideButtons,
            "trial-days-month": interval.value === 'month' && !hadTrial.value ? subscriptionMeta.trial_days_month : undefined,
            onBtnClick: ($event: any) => (clickPlan(getProductPlan(subscriptionMeta.product)))
          }, null, 8, ["title", "is-active", "chip-label", "description", "prices", "bullet-list", "is-bestseller", "btn-loading", "interval", "hide-buttons", "trial-days-month", "onBtnClick"])
        ]))
      }), 128)),
      (!subscriptionMetaPlans.value?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[3] || (_cache[3] = [
            _createElementVNode("div", { class: "text-center full-width" }, " Keine Pakete bisher ", -1)
          ])))
        : _createCommentVNode("", true),
      (products.value?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(ProductsTable, { products: products.value }, null, 8, ["products"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})