import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_toolbar = _resolveComponent("header-toolbar")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_drawer_menu = _resolveComponent("drawer-menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_footer_bar = _resolveComponent("footer-bar")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "lhh Lpr lff" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_header, {
        "height-hint": "50",
        class: "bg-white text-dark"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_header_toolbar)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_drawer_menu, {
        modelValue: _ctx.drawer,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawer) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_page_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      _createVNode(_component_footer_bar)
    ]),
    _: 1
  }))
}