import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "full-width full-height relative-position" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "absolute-bottom flex text-white",
  style: {"background-color":"rgba(0,0,0,.35)"}
}
const _hoisted_4 = { class: "full-width flex justify-end" }
const _hoisted_5 = {
  key: 0,
  class: "absolute-full c-image-btns",
  style: {"background":"rgba(0,0,0,0.45)"}
}
const _hoisted_6 = { class: "flex items-center justify-center full-height" }
const _hoisted_7 = { style: {"font-size":"5rem","color":"rgba(255,255,255, 0.8)"} }

import Draggable from 'vuedraggable';
import {ref} from 'vue';
import {Image} from 'components/imageUpload/ImageUploader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DragImagesOrder',
  props: {
  modelValue: {required: true, type: Array as () => Image[]},
  aspectRatio: {type: Number, default: 1},
  colClasses: {default: 'col-6 col-sm-4 col-md-3 col-lg-2'}
},
  emits: ['update:modelValue', 'change'],
  setup(__props, { emit: __emit }) {

const $emit = __emit

const props = __props

const drag = ref(false)
const disableDragging = ref(false)

const deleteImage = function (uuid) {
  $emit('update:modelValue', props.modelValue.filter(image => image.uuid !== uuid))
}


return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_unref(Draggable), {
    "model-value": __props.modelValue,
    "item-key": "uuid",
    class: _normalizeClass(["c-draggable disable-long-press row q-col-gutter-md", {'c-disabled': disableDragging.value}]),
    "swap-threshold": 1,
    animation: 150,
    easing: "cubic-bezier(0.83, 0, 0.17, 1)",
    disabled: disableDragging.value,
    "force-fallback": true,
    delay: 100,
    "delay-on-touch-only": true,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = val => $emit('update:modelValue', val)),
    onContextmenu: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
    onStart: _cache[2] || (_cache[2] = ($event: any) => (drag.value=true)),
    onEnd: _cache[3] || (_cache[3] = ($event: any) => (drag.value=false)),
    onChange: _cache[4] || (_cache[4] = val => $emit('change', val))
  }, {
    header: _withCtx(() => [
      _renderSlot(_ctx.$slots, "prepend")
    ]),
    footer: _withCtx(() => [
      _renderSlot(_ctx.$slots, "append")
    ]),
    item: _withCtx(({element, index}) => [
      _createElementVNode("div", {
        class: _normalizeClass(["relative-position", __props.colClasses]),
        style: _normalizeStyle({aspectRatio: __props.aspectRatio})
      }, [
        _createVNode(_component_q_card, {
          class: "overflow-hidden full-height full-width",
          flat: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("img", {
                class: "c-img-thumbnail",
                src: element.path,
                width: "100%",
                height: "100%",
                style: {"object-fit":"cover"}
              }, null, 8, _hoisted_2),
              (!drag.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_q_btn, {
                        flat: "",
                        round: "",
                        onClick: _withModifiers(($event: any) => (deleteImage(element.uuid)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_icon, {
                            name: "fas fa-trash",
                            size: "xs"
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            (drag.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(index + 1), 1)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024)
      ], 6)
    ]),
    default: _withCtx(() => [
      _cache[5] || (_cache[5] = _createTextVNode(" > "))
    ]),
    _: 3
  }, 8, ["model-value", "class", "disabled"]))
}
}

})